import { ActiveStateFilter, AssignmentStatus } from 'century-core/entities/Assignment/Assignment';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';
import * as helpers from '../utils/helpers';
export interface AssignmentTableData {
  _id: string;
  coursePlanId: string;
  durationActual: number | null;
  isCompleted: boolean;
  finishedWithinPlanTime: boolean;
  lastActivityTime: Date | null;
  name: string;
  numberOfNuggets: number;
  percentScore: number | null;
  planEndTime: Date | null;
  planStartTime: Date | null;
  status?: AssignmentStatus;
  subject: {
    _id: string;
    name: string;
    colour: string;
  };
  teacher: {
    title: string;
    value: string;
  }
  type: string;
}

export function getAssignmentTableDataAndFilterBarStatus(
  assignments: Ctek.Assignment[],
  courseplans: Ctek.CoursePlan[],
  activeStateFilter: ActiveStateFilter,
  subjectId: string,
  teachers: SelectOption[],
  teacherId: string,
) {
  if (!assignments || !courseplans) {
    return null;
  }
  let tableData = compileTableData(courseplans, assignments, teachers);
  const shouldDisplayFilterBar = tableData.length > 0;
  tableData = filterBySubjectId(tableData, subjectId);
  tableData = filterByTeacherId(tableData, teacherId)
  tableData = filterByActiveState(tableData, activeStateFilter);
  return {
    assignments: tableData,
    shouldDisplayFilterBar,
  };
}

function filterByActiveState(tableData: AssignmentTableData[], activeStateFilter: ActiveStateFilter) {
  switch (activeStateFilter) {
    case 'active':
      return tableData.filter((tableRow: AssignmentTableData) => isAssignmentActive(tableRow));
    case 'past':
      return tableData.filter((tableRow: AssignmentTableData) => !isAssignmentActive(tableRow));
    case 'ALL':
    default:
      return tableData;
  }
}

function filterBySubjectId(tableData: AssignmentTableData[], subjectId: string) {
  const subjectExists = tableData.some(tableRow => tableRow.subject._id === subjectId);
  return !subjectId || subjectId === 'ALL' || !subjectExists ? tableData : tableData.filter(tableRow => tableRow.subject._id === subjectId);
}
function filterByTeacherId(tableData: AssignmentTableData[], teacherId: string) {
  const teacherExists = tableData.some(tableRow => tableRow.teacher?.value === teacherId);
  return !teacherId || teacherId === 'ALL' || !teacherExists ? tableData : tableData.filter(tableRow => tableRow.teacher?.value === teacherId);
}

function isAssignmentActive(tableRow: { planEndTime: Date | null }) {
  // We shouldn't have assignments without endTime but if this bug happens
  // we'll just exclude them from the filter
  if (!tableRow.planEndTime) {
    return false;
  }

  return new Date() < tableRow.planEndTime;
}

function countNuggetsInStrands(strands: Ctek.Strand[] | undefined) {
  let count = 0;

  if (strands) {
    strands.forEach(strand => {
      count += strand.nuggets.length;
    });
  }

  return count;
}

function getDate(value: string | null): Date | null {
  return value ? new Date(value) : null;
}

function getAssignmentById(assignmentId: string, assignments: Ctek.Assignment[]): Ctek.Assignment | undefined {
  return assignments.find(assignment => assignment._id === assignmentId);
}

function compileTableData(coursePlans: Ctek.CoursePlan[], assignments: Ctek.Assignment[], teachers: SelectOption[]): AssignmentTableData[] {
  return coursePlans.map(coursePlan => {
    const assignment = getAssignmentById(coursePlan.courseId!, assignments);
    if (!assignment) {
      // eslint-disable-next-line array-callback-return
      return;
    }
    return {
      _id: assignment._id,
      coursePlanId: coursePlan.coursePlanId,
      durationActual: coursePlan.isCompleted && coursePlan.durationActual ? coursePlan.durationActual : null,
      finishedWithinPlanTime: coursePlan.finishedWithinPlanTime,
      history: assignment.history,
      isCompleted: coursePlan.isCompleted,
      lastActivityTime: coursePlan.isCompleted ? getDate(coursePlan.lastActivityTime!) : null,
      name: assignment.name || '',
      numberOfNuggets: countNuggetsInStrands(assignment.strands),
      percentScore: coursePlan.isCompleted && coursePlan.percentScore ? helpers.getPercentValue(coursePlan.percentScore) : null,
      planEndTime: getDate(coursePlan.planEndTime!),
      planStartTime: getDate(coursePlan.planStartTime!),
      status: getAssignmentStatus(coursePlan.planStartTime!, coursePlan.planEndTime!),
      subject: assignment.subject,
      teacher: teachers.find(t => t.value === getFirstCreatorFromAssignment(assignment)),
      type: assignment.type,
    };
  }) as AssignmentTableData[];
}

function getAssignmentStatus(startTime: string, endTime: string): AssignmentStatus {
  const now = new Date();
  if (now > new Date(startTime)) {
    if (now < new Date(endTime)) {
      return AssignmentStatus.ACTIVE;
    } else {
      return AssignmentStatus.PAST;
    }
  } else {
    return AssignmentStatus.PLANNED;
  }
}

export function getFirstCreatorFromAssignment(assignment: Ctek.Assignment) {
  return typeof (assignment.history?.firstVersion.createdBy) === 'string'
    ? assignment.history.firstVersion.createdBy
    : assignment.history?.firstVersion.createdBy._id
      ? assignment.history?.firstVersion.createdBy._id
      : ''
}
