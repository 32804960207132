import { Errors } from '../ErrorMessage/Errors';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export interface RefreshTokenV1Context {
  canImpersonate: boolean;
  createdBy: string;
  selfRefreshing: boolean;
  version: number;
}

export interface RefreshTokenV1 {
  context: RefreshTokenV1Context;
  exp: number; // expire date
  iat: number; // issue date
  scopes: string[];
  sub: string; // the logged in user
}

export enum ViewState {
  LoginForm,
  LoggingIn,
  LoggedIn,
  Error,
}

export interface Auth {
  accessToken?: string;
  accessTokenData?: Ctek.JWTData;
  error?: ErrorMessage<Errors>;
  orgSetting?: Ctek.Accounts.DomainResponse;
  refreshToken?: string;
  refreshTokenData?: RefreshTokenV1;
  sso?: object;
  viewState?: ViewState;
}

export default Auth;
