import { createSelector } from 'reselect';
import * as stateSelectors from './stateSelectors';

export const getAvatars = createSelector(
  [stateSelectors.userProfileSelectors.getAvatars],
  (avatars) => avatars);

export const getUserProfileLoaded = createSelector(
  [stateSelectors.userProfileSelectors.getLoaded],
  (loaded) => loaded);

export const getUserProfileError = createSelector(
  [stateSelectors.userProfileSelectors.getError],
  (error) => error);

export const getUserProfileShowAddEmail = createSelector(
  [stateSelectors.userProfileSelectors.getShowAddEmail],
  (showAddEmail) => showAddEmail);

export const getUserProfileShowChangePassword = createSelector(
  [stateSelectors.userProfileSelectors.getShowChangePassword],
  (showChangePassword) => showChangePassword);

export const getUserProfileShowChangeUsername = createSelector(
  [stateSelectors.userProfileSelectors.getShowChangeUsername],
  (showChangeUsername) => showChangeUsername);
