import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/auth.utils';
import { minorErrors } from 'century-core/entities/ErrorMessage/Errors';
import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import { normalize } from 'normalizr';
import * as R from 'ramda';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import App from './ContainerApp';
import Core from './Core';
import api from 'api/api';
import { getAppEnv, getReleaseTagWithVersion } from 'century-core/core-utils/utils/config/config';
import * as polyfills from 'lib/utils/polyfills';
import { PolymerLoadedProvider } from './century-core/polymer/PolymerLoadedContext';
import rootReducer from 'state/reducers/reducers';
import sanitizeTransactionName from 'century-core/core-utils/lib/sentry/sanitizeTransactionName';
import browserUpdate from 'browser-update';
import '@ctek/design-system/assets/index.css';
import PolymerAvailableReactComponents from 'PolymerToReactComponents';
import { appendScript } from 'century-core/core-utils/utils/dynamicImport/scriptImport';

type CENTURY_REGION = 'global' | 'cn';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    Century: any;
    DefaultText: any;
    ENV: string;
    METADATA_GIT_BRANCH: string;
    METADATA_GIT_HASH: string;
    mixpanel: any;
    Polymer: (...args: any) => void;
    React: any;
    ReactDOM: any;
    Reactify: (...args: any) => void;
    RELEASE_VERSION: string;
    REGION: CENTURY_REGION;
    Text: any;
  }
}

interface SentryConfig {
  dsn: string;
  ignoredApiErrors: {
    pathRegex: RegExp;
    statuses: number[];
  }[];
  ignoredErrors: string[];
  tracingOrigins: string[];
  tracesSampleRate: number;
}

interface APILocationData {
  useAlternateCDN: boolean;
  CDN: {
    override: string;
    fallback: string;
  };
}

interface LSLocationData extends APILocationData {
  exp: string;
}

// Error messages that should be ignored
const ignoredExceptions = [
  'Origin is not allowed by Access-Control-Allow-Origin',
  'No study groups found for practice bank',
  "Can't find variable: YT",
  'YT is not defined',
  "'YT' is not defined",
];

/**
 *
 */
window.React = React;
window.ReactDOM = ReactDOM;

// Used to fix bug where ic_player sets window.Text to something else. This then breaks quill editors which try to use
// window.Text later on. This can be removed when ic_player is removed.
window.DefaultText = window.Text;

const buildPathToAPIURLs = (env: string = 'int', windowRegion: CENTURY_REGION = 'global'): string => {
  const region = windowRegion === 'global' ? '' : `-${windowRegion}`;
  return `/assets/api-urls${region}.${env}.json?v=${Date.now()}`;
};

async function loadVersionData(): Promise<any> {
  window.METADATA_GIT_BRANCH = 'N/A';
  window.METADATA_GIT_HASH = 'N/A';
  window.RELEASE_VERSION = 'local';

  try {
    const versionData = await fetch(`./version.json?v=${Date.now()}`).then((res: Response) => res.json());
    const {
      version = 'local',
      metadata: { gitBranch = 'N/A', gitHash = 'N/A' },
    } = versionData;

    window.METADATA_GIT_BRANCH = gitBranch;
    window.METADATA_GIT_HASH = gitHash;
    window.RELEASE_VERSION = version;

    return versionData;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.warn(error);

    return {};
  }
}

async function loadEnvironmentData(): Promise<any> {
  window.ENV = 'int';
  window.REGION = 'global';

  try {
    const envData = await fetch('./env.json').then((res: Response) => res.json());
    const { env = 'int' } = envData;

    window.ENV = env;

    if (window.location.hostname.includes('centurytech.cn')) {
      window.REGION = 'cn';
    }

    return envData;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.warn(error);

    return {};
  }
}

async function loadAPIUrls(): Promise<any> {
  try {
    const apiUrlsData = await fetch(buildPathToAPIURLs(window.ENV, window.REGION)).then((res: Response) => res.json());
    window.Century = {
      ...window.Century,
      apiUrls: apiUrlsData,
    };

    return apiUrlsData;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.warn(error);

    window.Century = {
      ...window.Century,
      apiUrls: {},
    };

    return {};
  }
}

async function loadCDNInfo(): Promise<any> {
  try {
    const lsLocationData = localStorage.getItem('ctek.cdn');
    if (lsLocationData) {
      const parsedLocationData: LSLocationData = JSON.parse(lsLocationData);
      if (DateTime.utc() < DateTime.fromISO(parsedLocationData.exp).toUTC()) {
        return;
      }
    }

    const cdnData: APILocationData = await fetch(`${window.Century.apiUrls.learn}/region/cdn`).then((res: Response) => res.json());
    localStorage.setItem(
      'ctek.cdn',
      JSON.stringify({
        ...cdnData,
        exp: DateTime.utc().toISO(),
      })
    );

    return cdnData;
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.warn(error);

    return {};
  }
}

async function initialiseCentury(callback?: () => void) {
  browserUpdate({
    required: {
      c: -5,
      e: -5,
      f: -5,
      o: -5,
      s: -3,
      ios: -3,
      samsung: -3,
      ie: 12,
    },
    insecure: true,
  });

  await WCM.bootstrap();
  // PolymerCSS
  WCM.loadable(
    {
      href: '/node_modules/@ctek/ct-global-styles/css/styles.css',
      rel: 'stylesheet',
    },
    'link'
  );

  if (localStorage.getItem('Meteor.sessionId')) {
    localStorage.clear();
  }

  function shouldInitSentry(): boolean {
    return (
      (window.METADATA_GIT_BRANCH.includes('master') && window.ENV.includes('prod')) ||
      (window.METADATA_GIT_BRANCH.includes('release/next') && window.ENV.includes('sta'))
    );
  }

  if (shouldInitSentry()) {
    const sentryConfig = (await fetch(`./sentry-config.json?v=${Date.now()}`).then((res: Response) => res.json())) as SentryConfig;
    Sentry.init({
      beforeSend: (event, hint) => {
        for (const prop in minorErrors) {
          if (minorErrors[prop] === event.message) {
            return null;
          }
        }

        /**
         * If the exception message matches any of the hardcoded messages defined above, we'll return early.
         */
        if (R.includes(R.path(['originalException', 'message'], hint), ignoredExceptions)) {
          return null;
        }

        /**
         * Taken from the Cerberus application, this block checks that the error is not an already known API error.
         */
        const { status, responseURL } = R.pathOr({}, ['originalException', 'response', 'xhr'], hint);

        if (status && responseURL) {
          const shouldSkip = sentryConfig.ignoredApiErrors.some(({ pathRegex, statuses }) => {
            return statuses.some(_ => _ === status) && RegExp(pathRegex).test(responseURL);
          });

          if (shouldSkip) {
            return null;
          }
        }

        return event;
      },
      ignoreErrors: [
        ...sentryConfig.ignoredErrors,
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      dsn: sentryConfig.dsn,
      environment: getAppEnv() || 'test',
      release: getReleaseTagWithVersion(),
      integrations: [
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
        }),
        new Sentry.BrowserTracing({
          beforeNavigate: context => {
            return {
              ...context,
              name: sanitizeTransactionName(),
            };
          },
          tracingOrigins: sentryConfig.tracingOrigins,
        }),
      ],
      tracesSampleRate: sentryConfig.tracesSampleRate,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
    });
  }

  polyfills.initialise();

  if (window.ENV.includes('prod')) {
    appendScript('https://p6tkn1wbhnlt.statuspage.io/embed/script.js', 'statusPageScript');
    appendScript('https://uptime.betterstack.com/widgets/announcement.js', 'betterStackAnnouncementScript', { 'data-id': '174722' });
  }

  if (callback) {
    callback();
  }
}

function renderApp() {
  const token = window.ENV === 'prod' ? '2ad81efc7b9ccf1a5bf930fbe666b9fd' : '7bc0c77e694b142ef635a5d778817487';
  window.mixpanel.init(token, {
    api_host: `${window.Century.apiUrls.insights}/api/v1/proxy/event`,
    ip: 0,
    api_method: 'GET',
    xhr_headers: {
      get Authorization() {
        return `Bearer ${parseAuthFromLocalstorage() || ''}`;
      },
    },
    persistence: 'localStorage',
  });
  const composeEnhancer: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer as any, composeEnhancer(applyMiddleware(ReduxThunk.withExtraArgument({ api, normalize }))));

  const rootElm = document.getElementById('root') as HTMLElement;

  ReactDOM.render(
    <React.StrictMode>
      <PolymerLoadedProvider polymerAvailableReactComponents={PolymerAvailableReactComponents}>
        <Core store={store}>
          <Router>
            <App />
          </Router>
        </Core>
      </PolymerLoadedProvider>
    </React.StrictMode>,
    rootElm
  );

  rootElm.setAttribute('data-release', getReleaseTagWithVersion());
}

Promise.all([loadVersionData(), loadEnvironmentData()])
  .then(async () => {
    await loadAPIUrls();
  })
  .then(async () => {
    await loadCDNInfo();
    initialiseCentury(renderApp);
  });
