import { Button, FlexGrid, FlexGridNoGap, Form, FormError, Input, InputError, Label, Select, Text } from '@ctek/design-system';
import { Formik } from 'formik';
import { DateTime, Interval } from 'luxon';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { DATE_FORMAT, MIN_DOB, MIN_PASSWORD_LENGTH } from 'century-core/core-utils/utils/utils';
import { NewUserFormValues } from '../ClassCodePage';
import { useMemo, useState } from 'react';
import { ClassCodeValidSignupValues } from '../validation';

interface Props {
  formErrors: { [key: string]: string };
  checkEmailAlreadyExists: (email: string) => Promise<boolean>;
  processNewUserForm: (newUserFormValues: NewUserFormValues) => void;
  renderClassCodeValidSignin: () => void;
  setEmailAddress: (email: string) => void;
}

const classCodeValidSignupValues = {
  confirmEmail: '',
  confirmPassword: '',
  dob: '',
  email: '',
  firstName: '',
  gender: '',
  lastName: '',
  password: '',
  consentGiven: false,
};

export const ClassCodeValidSignupForm = (props: Props) => {
  const formErrors = props.formErrors;
  const validateClassCodeValidSignupForm = (values: ClassCodeValidSignupValues) => {
    const errors: Record<string, keyof typeof formErrors> = {};

    if (!values.consentGiven) {
      errors.consentGiven = formErrors['class-code-signup-error-consent-required'];
    }

    // First name and last name check
    if (!values.firstName) {
      errors.firstName = formErrors['class-code-signup-error-first-name'];
    }

    if (!values.lastName) {
      errors.lastName = formErrors['class-code-signup-error-last-name'];
    }

    // Gender check
    if (!values.gender) {
      errors.gender = formErrors['class-code-signup-error-gender'];
    }

    // Emails check
    if (!values.email) {
      errors.email = formErrors['class-code-signup-error-email'];
    }

    if (!values.confirmEmail) {
      errors.confirmEmail = formErrors['class-code-signup-error-confirm-email'];
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = formErrors['class-code-signup-error-email-invalid'];
    }

    if (values.email !== values.confirmEmail) {
      errors.email = formErrors['class-code-signup-error-email-dont-match'];
    }

    // Passwords check
    if (!values.password) {
      errors.password = formErrors['class-code-signup-error-password'];
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = formErrors['class-code-signup-error-confirm-password'];
    }

    if (values.password !== values.confirmPassword) {
      errors.password = formErrors['class-code-signup-error-password-dont-match'];
    }

    if (values.password && values.password.length < MIN_PASSWORD_LENGTH) {
      errors.password = formErrors['error-password-too-short'];
    }

    // DOB check
    const inputDate = DateTime.fromISO(values.dob);
    if (values.dob === '') {
      errors.dob = formErrors['class-code-signup-error-dob'];
    } else if (Interval.fromDateTimes(new Date(MIN_DOB), DateTime.local()).contains(inputDate) === false || inputDate.isValid === false) {
      errors.dob = formErrors['class-code-signup-error-dob-invalid'];
    }

    return errors;
  };

  const intl: IntlShape = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const genderOptions = useMemo(
    () => [
      { value: '', title: intl.formatMessage({ id: 'select-placeholder', defaultMessage: 'Select' }), disabled: true, selected: true },
      { value: 'male', title: intl.formatMessage({ id: 'sign-up-label-gender-male', defaultMessage: 'Male' }) },
      { value: 'female', title: intl.formatMessage({ id: 'sign-up-label-gender-female', defaultMessage: 'Female' }) },
      { value: 'unknown', title: intl.formatMessage({ id: 'sign-up-label-gender-not-specified', defaultMessage: 'Not Specified' }) },
    ],
    [intl]
  );

  return (
    <Formik
      initialValues={classCodeValidSignupValues}
      validate={validateClassCodeValidSignupForm}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        const emailExists: boolean = await props.checkEmailAlreadyExists(values.email);
        if (emailExists) {
          setFieldError('email', formErrors['class-code-signup-error-email-repeated']);
          props.setEmailAddress(values.email);
          props.renderClassCodeValidSignin();
        } else {
          props.processNewUserForm({
            birthDate: values.dob,
            email: values.email,
            firstName: values.firstName,
            gender: values.gender,
            lastName: values.lastName,
            password: values.password,
          });
        }
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} aria-labelledby="sign-up" data-testid="class-code-signup-form">
          <FlexGrid>
            <FlexGrid.Col xs={12} sm={6}>
              <Label>
                <Label.Text>
                  <FormattedMessage id="class-code-signup-label-first-name" defaultMessage="Name" />{' '}
                </Label.Text>
                <Input
                  data-private="lipsum"
                  autoComplete="given-name"
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  qa="class-code-signup-form-first-name"
                />

                {errors.firstName && touched.firstName ? <FormError qa="firstname-error">{errors.firstName}</FormError> : null}
              </Label>
            </FlexGrid.Col>
            <FlexGrid.Col xs={12} sm={6}>
              <Label>
                <Label.Text>
                  <FormattedMessage id="class-code-signup-label-last-name" defaultMessage="Last Name" />
                </Label.Text>
                <Input
                  data-private="lipsum"
                  autoComplete="family-name"
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  qa="class-code-signup-form-last-name"
                />
                {errors.lastName && touched.lastName ? <FormError qa="lastname-error">{errors.lastName}</FormError> : null}
              </Label>
            </FlexGrid.Col>
          </FlexGrid>
          <Label>
            <Label.Text>
              <FormattedMessage id="class-code-signup-label-email" defaultMessage="Email" />
            </Label.Text>
            <Input
              data-private="lipsum"
              autoComplete="email"
              onChange={handleChange}
              value={values.email}
              name="email"
              qa="class-code-signup-form-email"
            />
            {errors.email && touched.email ? <FormError qa="email-error">{errors.email}</FormError> : null}
          </Label>
          <Label>
            <Label.Text>
              <FormattedMessage id="class-code-signup-label-confirm-email" defaultMessage="Confirm email" />
            </Label.Text>
            <Input
              data-private="lipsum"
              autoComplete="email"
              onChange={handleChange}
              value={values.confirmEmail}
              name="confirmEmail"
              qa="class-code-signup-form-confirm-email"
            />
            {errors.confirmEmail && touched.confirmEmail ? <FormError qa="confirm-email-error">{errors.confirmEmail}</FormError> : null}
          </Label>
          <Label>
            <Label.Text>
              <FormattedMessage id="class-code-signup-label-password" defaultMessage="Password" />
            </Label.Text>
            <Input
              data-private="lipsum"
              autoComplete="new-password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={values.password}
              name="password"
              qa="class-code-signup-form-password"
            />
            {errors.password && touched.password ? <FormError qa="password-error">{errors.password}</FormError> : null}
          </Label>
          <Label>
            <Input type="checkbox" onClick={() => setShowPassword(!showPassword)} qa="class-code-signup-form-show-password-checkbox">
              <Input.Text>
                <FormattedMessage id="show-password" defaultMessage="Show password" />
              </Input.Text>
            </Input>
          </Label>
          <Label>
            <Label.Text>
              <FormattedMessage id="class-code-signup-label-confirm-password" defaultMessage="Confirm password" />
            </Label.Text>
            <Input
              data-private="lipsum"
              autoComplete="new-password"
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={values.confirmPassword}
              name="confirmPassword"
              qa="class-code-signup-form-confirm-password"
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <FormError qa="confirm-password-error">{errors.confirmPassword}</FormError>
            ) : null}
          </Label>
          <Label>
            <Input
              type="checkbox"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              qa="class-code-signup-form--show-confirm-password-checkbox"
            >
              <Input.Text>
                <FormattedMessage id="show-password" defaultMessage="Show password" />
              </Input.Text>
            </Input>
          </Label>

          <FlexGrid noGap={FlexGridNoGap.VERTICAL}>
            <FlexGrid.Col xs={12} sm={6}>
              <Label>
                <Label.Text>
                  <FormattedMessage id="class-code-signup-label-dob" defaultMessage="Date of birth" />
                </Label.Text>

                <Input
                  type="date"
                  data-private="lipsum"
                  autoComplete="bday"
                  onChange={handleChange}
                  value={values.dob}
                  name="dob"
                  min={MIN_DOB}
                  max={DateTime.local().toFormat(DATE_FORMAT)}
                  qa="class-code-signup-form-dob"
                  placeholder={intl.formatMessage({ id: 'dateformat-day-month-year', defaultMessage: 'dd/mm/yyyy' })}
                />
                {errors.dob && touched.dob ? <FormError qa="dob-error">{errors.dob}</FormError> : null}
              </Label>
            </FlexGrid.Col>
            <FlexGrid.Col xs={12} sm={6}>
              <Label>
                <Label.Text>
                  <FormattedMessage id="class-code-signup-label-sex" defaultMessage="Sex" />
                </Label.Text>
                <Select
                  data-private="lipsum"
                  name="gender"
                  value={values.gender}
                  qa="class-code-signup-form-sex"
                  onChange={handleChange}
                  aria-labelledby="sign-up-label-sex"
                >
                  {genderOptions.map(({ title, value, disabled }, i) => (
                    <Select.Option key={i} disabled={disabled} value={value}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
                {errors.gender && touched.gender ? <InputError qa="error-gender">{errors.gender}</InputError> : null}
              </Label>
            </FlexGrid.Col>
          </FlexGrid>
          <Label>
            <Input
              name="consentGiven"
              type="checkbox"
              checked={values.consentGiven}
              onChange={handleChange}
              qa="class-code-signup-form-consent-checkbox"
            >
              <Input.Text>
                <FormattedMessage
                  id="class-code-signup-consent-checkbox"
                  defaultMessage="I agree to my data being stored and processed in accordance with CENTURY's Privacy Policy."
                />
              </Input.Text>
            </Input>
            <Text.Disclaimer qa="signup-disclaimer">
              <FormattedMessage
                id="class-code-signup-form-consent-privacy-policy"
                defaultMessage="Before signing up to use the CENTURY platform, please read our {privacyPolicy}. By signing up you are agreeing to the Privacy Policy, including the provisions for data collection to improve your learning experience and for communication about the service."
                values={{
                  privacyPolicy: (
                    <a
                      className="link"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.century.tech/privacy-policy/"
                      onClick={e => e.stopPropagation()}
                    >
                      <FormattedMessage id="class-code-signup-form-consent-privacy-policy" defaultMessage="Privacy Policy" />
                    </a>
                  ),
                }}
              />
            </Text.Disclaimer>
            {errors.consentGiven && touched.consentGiven ? <FormError qa="error-consentGiven">{errors.consentGiven}</FormError> : null}
          </Label>

          <Button type="submit" disabled={isSubmitting} qa="class-code-signup-form-submit" variant="primary">
            <FormattedMessage id="class-code-signup-submit" defaultMessage="Sign up" />
          </Button>
        </Form>
      )}
    </Formik>
  );
};
