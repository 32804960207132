import { Button, FlexGrid, Heading, IllustrationClassCodeValid, SignInBox } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';

interface Props {
  renderClassCodeValidSignup: () => void;
  renderClassCodeValidSignin: () => void;
}

export const ClassCodeValid = (props: Props) => {
  const { renderClassCodeValidSignin, renderClassCodeValidSignup } = props;

  return (
    <>
          <SignInBox.Header>
            <IllustrationClassCodeValid />
            <h1 data-testid="code-valid">
              <FormattedMessage id="class-code-class-code-valid-title" defaultMessage="Connect your account" />
            </h1>
          </SignInBox.Header>
          <SignInBox.Body>
          <FormattedMessage
            id="class-code-class-code-valid-instructions"
            defaultMessage="You are not currently signed in. You will be added to your teacher’s class as soon as you sign in."
          />
          </SignInBox.Body>
          <SignInBox.Form qa="class-code-valid-form">
            <Heading.Question qa="class-code-question">
              <FormattedMessage id="class-code-class-code-valid-action-title" defaultMessage="Do you have a CENTURY account?" />
            </Heading.Question>
            <FlexGrid>
              <FlexGrid.Col sm={6}>
                <Button variant="primary" type="button" normalCase={true} onClick={renderClassCodeValidSignup} qa="class-code-class-code-valid-signup">
                  <FormattedMessage id="class-code-class-code-valid-signup" defaultMessage="NO, sign me up!" />
                </Button>
              </FlexGrid.Col>
              <FlexGrid.Col sm={6}>
                <Button variant="primary" type="button" normalCase={true} onClick={renderClassCodeValidSignin} qa="class-code-class-code-valid-signin">
                  <FormattedMessage id="class-code-class-code-valid-signin" defaultMessage="YES, sign me in!" />
                </Button>
              </FlexGrid.Col>
            </FlexGrid>
          </SignInBox.Form>
        </>
  );
};
