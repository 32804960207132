import { RouteComponentProps } from 'react-router';
import { StoreState } from '../reducers/reducers';

export const getAuth = (state: StoreState) => state.auth;
export const getDependants = (state: StoreState) => state.dependants.dependants;
export const getEntities = (state: StoreState) => state.entities;

// entities
export const getCourseAchievementEntities = (state: StoreState) => state.entities && state.entities.courseAchievements;
export const getCourseEntities = (state: StoreState) => state.entities && state.entities.courses;
export const getCourseplanEntities = (state: StoreState) => state.entities && state.entities.courseplans;
export const getMediaFileEntities = (state: StoreState) => state.entities && state.entities.mediaFiles;
export const getMediaItemEntities = (state: StoreState) => state.entities && state.entities.mediaItems;
export const getNuggetsEntities = (state: StoreState) => state.entities && state.entities.nuggets;
export const getQuestionGroupsEntities = (state: StoreState) => state.entities && state.entities.questionGroups;
export const getQuestionEntities = (state: StoreState) => state.entities && state.entities.questions;
export const getRecommendationEntities = (state: StoreState) => state.entities && state.entities.recommendations;
export const getStudyGroupEntities = (state: StoreState) => state.entities && state.entities.studygroups;
export const getSubjectEntities = (state: StoreState) => state.entities && state.entities.subjects;
export const getUserEntities = (state: StoreState) => state.entities && state.entities.users;

// router props
export const getLocationPathnameFromRouter = (state: StoreState, props: RouteComponentProps) => props.location.pathname;
export const getMatch = (state: StoreState, props: RouteComponentProps) => props.match;
export const getHistory = (state: StoreState, props: RouteComponentProps) => props.history;
export const getLocation = (state: StoreState, props: RouteComponentProps) => props.location;
export const getMatchUrl = (state: StoreState, props: RouteComponentProps) => props.match.url;

// User Profile

export const userProfileSelectors = {
  getAvatars: (state: StoreState) => state.userProfile.avatars,
  getError: (state: StoreState) => state.userProfile.error,
  getLoaded: (state: StoreState) => state.userProfile.loaded,
  getShowAddEmail: (state: StoreState) => state.userProfile.showAddEmail,
  getShowChangePassword: (state: StoreState) => state.userProfile.showChangePassword,
  getShowChangeUsername: (state: StoreState) => state.userProfile.showChangeUsername,
};
