export const useIsChinaLocation = (): boolean => window.location.hostname.includes('centurytech.cn');

export const ICPLicense = () => {
  const isChinaLocation = useIsChinaLocation();
  if (!isChinaLocation) return null;

  return (
    <li>
      <a href="http://beian.miit.gov.cn" target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
        京ICP备2021009027号-2
      </a>
    </li>
  );
};
