import { GET, PATCH, DELETE, POST, PUT } from 'century-core/core-apis/utils';
import { getAccountsUrl } from '../accountsV2';
import { chunk } from 'lodash';

interface NewClassId {
  id: string;
  _id: string;
}

export interface ClassCodeResponse {
  code: string;
  expiresAt: string;
}

export function getClass(classId: string, accessToken: string) {
  const url = getAccountsUrl(`/classes/${classId}`);

  return GET<Ctek.Class>({ url, token: accessToken }).catch((err) => {
    throw Error(`Unable to get class by id ${classId}. ${err?.errorData?.message || err}`);
  });
}

export async function patchClass(classId: string, newClass: Partial<Ctek.Class>, accessToken: string) {
  const url = getAccountsUrl(`/classes/${classId}`);

  return PATCH<NewClassId>({ url, token: accessToken, body: newClass }).catch((err: Error) => {
    throw Error(`Unable to patch class by id ${classId}, ${err}`);
  });
}

export async function deleteClass(classId: string, accessToken: string) {
  const url = getAccountsUrl(`/classes/${classId}`);

  return DELETE({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to delete class by id ${classId}, ${err}`);
  });
}

export function getAssessmentClasses(orgId: string, accessToken: string, classCategory: string) {
  const url = getAccountsUrl('/classes/');
  url.search += '?context=teach';
  url.search += `&org=${orgId}`;
  url.search += `&classCategory=${classCategory}`;
  url.search += '&classType=smartAssessment&select=classCategory,name,organisation';

  return GET<Ctek.Class[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get assessment classes for org id ${orgId} in class category ${classCategory}, ${err.message}`);
  });
}

export function getOrgCohorts(orgId: string, accessToken: string) {
  const url = getAccountsUrl('/classes');
  url.search += `?org=${orgId}`;
  url.search += '&select=name,type,organisation,classCategory&classType=smartAssessment';

  return GET<Ctek.Class[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get org cohorts for org id ${orgId}, ${err.message}`);
  });
}

export async function getUsersClasses(user: Ctek.User, accessToken: string, hasSelect: boolean = false, classType?: string) {
  const baseUrl = getAccountsUrl();
  const userClassIds =
    user?.profile?.groups?.organisations?.map(org => org.classSettings?.map(classSetting => classSetting.class))?.flat(2) || [];

  const idChunks = chunk(userClassIds, 20); // divide into chunks of 20 using lodash's chunk function

  const promises = idChunks.map((ids: string[]) => {
    const url = new URL(baseUrl.href);
    url.pathname += '/classes/';
    url.search += `?ids=${ids.join(',')}`;
    url.search += hasSelect ? '&select=name,type,organisation,classCategory' : '';
    url.search += classType === 'cohort' ? '&classType=smartAssessment' : '';

    return GET<Ctek.Class[]>({ url, token: accessToken }).catch(
      (err: Error) => new Error(`Unable to get user ${user._id}'s classes; ${err.message}`)
    );
  });

  const results = await Promise.all(promises); // fail if any of the promises gets rejected
  return results.flatMap(result => result);
}

/** Makes a POST req to create a class and returns uuid and DB _id */
export function createClass(newClass: Ctek.Class, orgId: string, accessToken: string): Promise<NewClassId> {
  const url = getAccountsUrl('/classes/');

  return POST<NewClassId>({ url, token: accessToken, body: newClass }).catch((err: Error) => {
    throw Error(`Unable to create a class for org id ${orgId}, ${err}`);
  });
}

/** Calls accounts to get all classes matching passed class name */
export function getClasses(
  orgId: string,
  accessToken: string,
  classCategory?: string,
  className?: string,
  classType?: string
): Promise<Ctek.Class[]> {
  const url = getAccountsUrl('/classes/');
  url.search += `&orgs=${orgId}`;
  url.search += classCategory ? `&classCategory=${classCategory}` : '';
  url.search += className ? `&name-ignore-case=${className}` : '';
  url.search += classType === 'assessment' ? '&classType=smartAssessment' : '';

  return GET<Ctek.Class[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Failed to get all classes for org: ${orgId} and class name: ${className}, ${err}`);
  });
}

/** Get all classes for a given org */
export function getAllClasses(accessToken: string, orgId: string) {
  const url = getAccountsUrl('/classes');
  url.search += `org=${orgId}`;
  url.search += '&select=name,type,organisation,classCategory';

  return GET<Ctek.Class[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get all classes for org id ${orgId}, ${err}`);
  });
}

export async function getClassCode(classId: string, accessToken: string) {
  const url = getAccountsUrl(`/classes/${classId}/onboarding-code`);

  return GET<ClassCodeResponse>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get class code for class ${classId}, ${err}`);
  });
}

export async function generateClassCode(classId: string, accessToken: string) {
  const url = getAccountsUrl(`/classes/${classId}/onboarding-code`);

  return PUT<ClassCodeResponse>({ url, token: accessToken, body: {} }).catch((err: Error) => {
    throw Error(`Unable to generate class code for class ${classId}, ${err}`);
  });
}

export async function disableClassCode(classId: string, accessToken: string) {
  const url = getAccountsUrl(`/classes/${classId}/onboarding-code`);

  return DELETE({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to disable class code for class ${classId}, ${err}`);
  });
}

export async function updateAssessmentStatus(
  classId: string,
  assessmentId: string,
  action: 'activate' | 'deactivate',
  accessToken: string
) {
  const url = getAccountsUrl(`/classes/${classId}/${assessmentId}/${action}`);

  return PATCH({ url, token: accessToken, body: {} }).catch((err: Error) => {
    throw Error(`Unable to update assessment ${assessmentId} in class ${classId}, ${err}`);
  });
}
