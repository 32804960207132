import * as contentV2Assignments from 'century-core/core-apis/contentV2/assignments/assignments';
/**
 * Fetches assignemnts in chunks of 20 to ensure the path isn't too long.
 * AssignmentIds are the same as CourseIds and most data is stored in the courses
 * table.
 */
export function fetchAssignments(courseIds: string[], courses: Ctek.Assignment[], accessToken: string): Promise<Ctek.Assignment[]> {
  const remainingCourseIds = courseIds.slice(20);
  const requestedCourseIds = courseIds.slice(0, 20);
  return contentV2Assignments
    .getAssignments(requestedCourseIds, accessToken, { select: 'name, history' })
    .then(requestedCourses => {
      requestedCourses.map(crs => courses.push(crs));
      if (remainingCourseIds.length === 0) {
        return courses;
      } else {
        return fetchAssignments(remainingCourseIds, courses, accessToken);
      }
    })
    .catch((e: Error) => {
      throw new Error(`Unable to retrieve courses for course ids ${JSON.stringify(requestedCourseIds)},
       ${e}`);
    });
}
