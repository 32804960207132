import { Auth } from 'century-core/entities/Auth/Auth';
import { changePasswordSentinel, createSession } from '../../century-core/core-apis/sentinel/auth/auth';
import { patchMe } from '../../century-core/core-apis/accountsV2/users/users';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils';

export async function changePassword(username: string, oldPassword: string, newPassword: string) {
  const auth = await createFetchRequestWithLocalisedError(
    () => createSession(username, oldPassword),
    'localised-errors-invalid-credentials'
  );

  return createFetchRequestWithLocalisedError(async () => {
    const accessToken = (auth as Auth).accessToken;
    await patchMe(accessToken!, { password: newPassword });
    return await createSession(username, newPassword);
  }, 'localised-errors-user-profile-generic-error');
}

export async function recoverPassword(accessToken: string, newPassword: string) {
  return createFetchRequestWithLocalisedError(
    () => changePasswordSentinel(accessToken, newPassword),
    'localised-errors-user-profile-generic-error'
  );
}
