import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import Spinner from 'century-core/core-components/Spinner/Spinner';
import { ClassCodeButton, SigninFooter, SignInScreen } from '@ctek/design-system';
import { login } from 'state/actions/auth/auth';
import { setEmailAddress } from 'state/actions/recoverPassword/recoverPassword';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import * as Select from 'state/selectors';
import { LoginForm } from './LoginForm';
import { isLtiLoginEnabled } from './LtiLogin';
import { isMicrosoft365SeamlessLoginEnabled } from './Microsoft365SeamlessLogin';
import { ICPLicense } from './ICPLicense';
import LocationBasedTitle from 'components/AppTemplate/LocationBasedTitle'
import { ClassCodePage } from 'components/ClassCodePage/ClassCodePage';
import { RecoverPasswordPage } from 'century-core/core-auth/components/RecoverPasswordPage/RecoverPasswordPage'

export interface InputData {
  error: React.ReactNode | null;
  value: string;
}

export const Login = () => {
  const dispatch = useDispatch();
  const auth = useSelector(Select.getAuth);
  const emailAddressRecovery = useSelector(Select.getEmailAddress)
  const mixpanel = useMixpanel();
  const loginDispatch = (username: string, password: string) => {
    dispatch(login(username, password, mixpanel.track));
  };
  const location = useLocation();
  const enableClassCode = auth.orgSetting?.settings?.global?.authSettings?.isClassCodeEnabled ?? true;
  const setEmailAddressDispatch = (email: string) => {
    dispatch(setEmailAddress(email));
  };

  // This is needed as our SSO options need the url to have a trailing slash after the login.
  // React router considers /login and /login/ to be equal so will let /login down the /login/ path.
  if (location.pathname === '/login') {
    const searchParams = new URLSearchParams(location.search).toString();
    return <Redirect to={`/login/${searchParams ? `?${searchParams}` : ''}`} />;
  }

  let classCodeButton: React.ReactElement<typeof ClassCodeButton> | null = null;
  if (!isLtiLoginEnabled(auth) && !isMicrosoft365SeamlessLoginEnabled(auth) && !!enableClassCode) {
    classCodeButton = (
      <ClassCodeButton>
        <NavLink to="/class-code" data-qa="class-code-button" data-testid="class-code-button">
          <FormattedMessage id="login-class-code-link" defaultMessage="I have a class code" />
        </NavLink>
      </ClassCodeButton>
    );
  }

  return (
    <>
      <LocationBasedTitle auth={auth} />
      <Spinner type="fullpage" showContent={auth.orgSetting !== undefined}>
        <SignInScreen>
          <SignInScreen.Body>
            <Switch>
              <Route path="/class-code">
                <ClassCodePage />
              </Route>
              <Route exact={true} path="/login/recover-password">
                <RecoverPasswordPage emailAddress={emailAddressRecovery} />
              </Route>
              <Route
                exact={true}
                path="/login/"
                render={() => (
                  <>
                    <LoginForm layoutForm={'SigninForm'} login={loginDispatch} setEmailAddress={setEmailAddressDispatch} />
                    {classCodeButton}
                  </>
                )}
              />
              <Route>
                <Redirect to="/login/" />
              </Route>
            </Switch>
          </SignInScreen.Body>
          <SignInScreen.Footer>
            <SigninFooter poweredByMsg={<FormattedMessage id="login-footer-powered-by" defaultMessage="Powered by" />}>
              <ICPLicense />
            </SigninFooter>
          </SignInScreen.Footer>
        </SignInScreen>
      </Spinner>
    </>
  );
};

export default Login;
