import * as SentryLib from 'century-core/core-utils/lib/sentry';
import { History } from 'history';
import queryString from 'query-string';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Auth } from 'century-core/entities/Auth/Auth';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/auth.utils';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { getReleaseTag } from 'century-core/core-utils/utils/config/config';
import localStorage from 'century-core/core-utils/utils/localStorage/localStorage';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { isValidHttpUrl } from 'century-core/core-utils/utils/utils';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { StoreState, ThunkExtraArg } from '../../reducers/reducers';
import { Actions as EntitiesActions, addEntities } from '../entities/entities';
import { createSession as createSentinelSession } from 'century-core/core-apis/sentinel/auth/auth';

import {
  getTokenLoginSessionId,
  getTokenMainOrgId,
  getTokenMainOrgName,
  getTokenSub,
  hasGuardianRole,
  hasLearnerRole,
  isAccessTokenValid,
  sendMessageToServiceWorker,
  timeToExpireAccessToken,
  tokenTtl,
} from 'century-core/core-auth/utils';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { mapAuthForLocalStorage, mapAuthForRedux } from 'century-core/core-auth/utils/auth.mapper';
import { getDefaultMixpanelProps, getMixpanelWithDefaultProps } from 'century-core/core-utils/utils/mixpanel/mixpanel';
import { clearElaMood } from 'century-core/features-learner/ELAMood/ELAMoodContext';

let renewTokenTimer: any = 0;

export enum ActionTypes {
  LoggingIn = 'Auth.LoggingIn',
  LoggedIn = 'Auth.LoggedIn',
  Error = 'Auth.Error',
  ClearError = 'Auth.ClearError',
  ShowLoginForm = 'Auth.ShowLoginForm',
  LoggedOut = 'Auth.loggedOut',
  UpdateAccessToken = 'Auth.UpdateAccessToken',
  AddOrgSettings = 'Auth.AddOrgSettings',
}

export type Actions =
  | ReturnType<typeof loggingIn>
  | ReturnType<typeof loggedIn>
  | ReturnType<typeof loggedOut>
  | ReturnType<typeof errors>
  | ReturnType<typeof clearError>
  | ReturnType<typeof showLoginForm>
  | ReturnType<typeof addOrgSettings>
  | ReturnType<typeof updateAccessToken>;

export interface GoogleAccessToken {
  tokenObj: {
    accessToken: string;
    id_token: string;
  };
}

const STORAGE_KEY = 'auth';

export const loggingIn = () => ({
  type: ActionTypes.LoggingIn as typeof ActionTypes.LoggingIn,
});

export const loggedIn = (auth: Auth) => {
  // FIXME move into some middleware, maybe?
  addUserAndOrgIdToSentry(auth);

  return {
    auth,
    type: ActionTypes.LoggedIn as typeof ActionTypes.LoggedIn,
  };
};

export const loggedOut = () => ({
  type: ActionTypes.LoggedOut as typeof ActionTypes.LoggedOut,
});

export const errors = (err: ErrorMessage<Errors>) => ({
  error: err,
  type: ActionTypes.Error as typeof ActionTypes.Error,
});

export const clearError = () => ({
  type: ActionTypes.ClearError as typeof ActionTypes.ClearError,
});

export const showLoginForm = () => ({
  type: ActionTypes.ShowLoginForm as typeof ActionTypes.ShowLoginForm,
});

const writeTokenToLocalStorage = (auth: Auth) => {
  localStorage.write(STORAGE_KEY, mapAuthForLocalStorage(auth));
};

export const updateAccessToken = (auth: Auth) => {
  /**
   * TECH-15664 - TEMPORARY USE OF POLYMER LOGIN:
   * - writeLocalstorageAuth added for backwards compatibility with polymer app
   * Read AppShellPolymer README.
   */
  writeTokenToLocalStorage(auth);

  return {
    auth,
    type: ActionTypes.UpdateAccessToken as typeof ActionTypes.UpdateAccessToken,
  };
};

export const addOrgSettings = (settings: Ctek.Accounts.DomainResponse) => {
  return {
    settings,
    type: ActionTypes.AddOrgSettings as typeof ActionTypes.AddOrgSettings,
  };
};

function redirectToLogin(historyPush: (path: History.Path) => void) {
  const isLoginPage = window.location.href.indexOf('/login') > -1;
  if (isLoginPage) {
    return Promise.resolve();
  }

  let redirect;
  if (window.location.pathname && window.location.pathname !== '/') {
    redirect = escape(window.location.href);
  }

  const url = redirect ? `/login/?redirect=${redirect}` : '/login/';
  historyPush(url);
  return Promise.resolve();
}

const LOGGED_OUT_PATHS = ['/registration', '/bond'];

export function loadLocalStorage(
  historyPush: (path: History.Path) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    if (isOpenId(window.location.hash)) {
      return Promise.resolve();
    }

    /**
     * If we are trying to access the "recover-password", do not bother to validate any local tokens, and do not
     * redirect the user.
     * The same goes if refresh_token is appended which means we're authorising straight from it (goal: LTI auth)
     */
    if (window.location.pathname.indexOf('recover-password') > -1 || new URL(window.location.href).searchParams.has('refresh_token')) {
      // We force here a localstorage cleaning to avoid the bug where
      // different AL users get redirected to same Century account (TECH-21285)
      localStorage.remove(STORAGE_KEY);
      return Promise.resolve();
    }

    /**
     * We're not forcing a localstorage cleaning for user trying to access "registration" as we want logged-in user to be
     * redirected to user's Home Page, depending on the user's role. The redirection function is in App.tsx.
     * For non-logged user trying to access "registration", they will stay on /registration therefore NOT redirect to Login page
     */
    if (LOGGED_OUT_PATHS.some(path => window.location.pathname.match(new RegExp(`^${path}`)))) {
      return Promise.resolve();
    }

    /**
     * If we are trying to pass auth0 params, do not bother to validate any local tokens,
     * and do not redirect the user.
     */
    if (window.location.search.indexOf('code') > -1 && window.location.search.indexOf('state') > -1) {
      return Promise.resolve();
    }

    /**
     * TECH-15664 - TEMPORARY USE OF POLYMER LOGIN:
     * - we parse the auth object from the different values Polymer sets in localStorage
     * Read AppShellPolymer README.
     */
    // const auth = JSON.parse(localStorage.read<Auth>(STORAGE_KEY));

    const auth = parseAuthFromLocalstorage();
    if (auth === undefined) {
      return redirectToLogin(historyPush);
    }

    const isTokenValid = isAccessTokenValid(auth);
    if (!isTokenValid && auth.refreshToken) {
      dispatch(renewAccessToken(auth));
      return Promise.resolve();
    }

    if (!isTokenValid && !auth.refreshToken) {
      /**
       * TECH-15664 - TEMPORARY USE OF POLYMER LOGIN:
       * - we should redirect to /login route where the polymer login is
       * Read AppShellPolymer README.
       */
      // dispatch(showLoginForm());
      dispatch(logout());
      return redirectToLogin(historyPush);
    }

    return api.auth
      .getUser(getTokenSub(auth), auth.accessToken)
      .then(user => {
        if (user) {
          dispatch(
            addEntities({
              users: {
                [user._id as string]: user,
              },
            })
          );
        }
        dispatch(loggedIn(auth));
        scheduleRenewToken(auth, () => dispatch(renewAccessToken(auth)));
      })
      .catch(() => {
        /**
         * TECH-15664 - TEMPORARY USE OF POLYMER LOGIN:
         * - error renders React login form, we should logout and redirect to /login
         * Read AppShellPolymer README.
         */
        // dispatch(errors(err));
        dispatch(logout());
      });
  };
}

export function getDomainSettings(domain: string): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions> {
  return (dispatch: any, getState: () => StoreState, { api, normalize }: ThunkExtraArg): Promise<void> => {
    return (
      api.auth
        .getDomainSettings(domain)
        // TODO: sort out typings
        .then((settings: Ctek.Accounts.DomainResponse) => {
          dispatch(addOrgSettings(settings));
        })
        .catch((err: ErrorMessage<Errors>) => {
          dispatch(errors(err));
        })
    );
  };
}

function accountEntryProcess(
  apiCall: () => Promise<Auth | void>,
  track: (...props: any) => void,
  accountSwitchSource?: 'Dashboard' | 'Sidebar',
  onSuccess?: () => any
) {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    if (renewTokenTimer) {
      clearTimeout(renewTokenTimer);
    }
    dispatch(loggingIn());
    const previousAuth = getState().auth;
    let hadTimeout = false;
    if (renewTokenTimer) {
      clearTimeout(renewTokenTimer);
      hadTimeout = true;
    }
    return apiCall()
      .then(async (auth: Auth) => {
        if (renewTokenTimer) {
          clearTimeout(renewTokenTimer);
        }
        await createSession(auth, { api, normalize }, dispatch, track);
        return auth;
      })
      .then((auth: Auth) => {
        const parsedAuth = mapAuthForRedux(auth);
        if (onSuccess) {
          onSuccess();
        }
        if (accountSwitchSource) {
          track(MixpanelEventTypes.AccountSwitchCompleted, {
            ...getDefaultMixpanelProps(auth),
            [MixpanelKeys.Role]: hasLearnerRole(previousAuth) ? 'Learner' : hasGuardianRole(previousAuth) ? 'Guardian' : undefined,
            [MixpanelKeys.AccountSwitchedFrom]: previousAuth.accessTokenData?.sub,
            [MixpanelKeys.AccountSwitchedTo]: parsedAuth.accessTokenData?.sub,
            [MixpanelKeys.AccountSwitchLocation]: window.location.pathname,
            [MixpanelKeys.AccountSwitchedSource]: accountSwitchSource,
          });
        }
        gotoRedirectUrlFromQueryString();
      })
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));

        const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};
        if (hadTimeout && previousAuth.accessToken === localStorageAuth.accessToken) {
          scheduleRenewToken(previousAuth, () => dispatch(renewAccessToken(previousAuth)));
        }
      });
  };
}
export function switchUser(
  getNewAuth: () => Promise<Auth | void>,
  track: () => void,
  accountSwitchSource: 'Sidebar' | 'Dashboard',
  onSuccess: () => any
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return accountEntryProcess(getNewAuth, track, accountSwitchSource, onSuccess);
}

export function login(
  username: string,
  password: string,
  track: (...props: any) => void,
  accountSwitchSource?: 'Sidebar' | 'Dashboard'
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return accountEntryProcess(() => createSentinelSession(username, password), track, accountSwitchSource);
}

export function renewAccessToken(auth: Auth): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    const refreshToken: string = auth.refreshToken || '';
    const localStorageAndReduxUserMatch = isReduxAuthUserSynced(auth);

    // skip token renew if localStorage does not match with redux
    if (!localStorageAndReduxUserMatch) {
      return Promise.resolve();
    }
    return api.auth
      .renewToken(refreshToken)
      .then((newAuth: Auth) => {
        writeTokenToLocalStorage(newAuth);

        dispatch(loggedIn(mapAuthForRedux(newAuth)));
        scheduleRenewToken(newAuth, () => dispatch(renewAccessToken(newAuth)));
      })
      .catch((err: ErrorMessage<Errors>) => {
        if (err.code === 'localised-errors-offline') {
          // try again in 5sec
          delayRenewToken(() => dispatch(renewAccessToken(auth)), 5000);
          return;
        }
        dispatch(logout());
        dispatch(errors(err));
      });
  };
}

/**
 * Logs user out.
 *
 * This thunk isn't an async fn but we want to handle all
 * localstorage access here and not in the reducer.
 */
export function logout(redirect?: string, historyPush?: (path: string) => void) {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    clearTimeout(renewTokenTimer);
    // clear the service worker cache
    sendMessageToServiceWorker('clearCenturyCache');
    localStorage.remove(STORAGE_KEY);
    clearElaMood();

    if (window.auth0) {
      window.auth0.logout({});
    }

    const loginPath = `/login/${redirect ? `?redirect=${redirect}` : ''}`;

    window.location.href = loginPath;

    return Promise.resolve();
  };
}

function scheduleRenewToken(auth: Auth, fn: () => void) {
  const timeToExpire = timeToExpireAccessToken(auth) || 0;
  const tokenDuration = tokenTtl(auth) || 0;
  const idealRenewTtl = tokenDuration / 3;
  const delay = timeToExpire > idealRenewTtl ? timeToExpire - idealRenewTtl : 0;

  delayRenewToken(fn, delay);
}

function delayRenewToken(fn: () => void, delay: number) {
  if (renewTokenTimer) {
    clearTimeout(renewTokenTimer);
  }

  renewTokenTimer = setTimeout(fn, delay);
}

function addUserAndOrgIdToSentry(auth: Auth) {
  try {
    const userId = getTokenSub(auth);
    const orgId = getTokenMainOrgId(auth);
    SentryLib.configureScope(userId, orgId);
  } catch (_) {
    SentryLib.captureMessage('Not able to provide user and org id id for the logged in user');
  }
}

function isOpenId(hash: string): boolean {
  return (
    !!hash &&
    hash.includes('id_token') &&
    hash.includes('access_token') &&
    hash.includes('token_type') &&
    hash.includes('expires_in') &&
    hash.includes('scope') &&
    hash.includes('state')
  );
}

export function googleSignIn(
  googleIdToken: string,
  track: (...props: any) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .googleSignIn(googleIdToken)
      .then((auth: Auth) => createSession(auth, { api, normalize }, dispatch, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
      });
  };
}
export function MSTeamsSignIn(
  office365Token: string,
  track: (...props: any) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .office365SignIn(office365Token)
      .then((auth: Auth) => createSession(auth, { api, normalize }, dispatch, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        throw err;
      });
  };
}
export function office365SignIn(
  office365Token: string,
  track: (...props: any) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .office365SignIn(office365Token)
      .then((auth: Auth) => createSession(auth, { api, normalize }, dispatch, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
      });
  };
}

export function auth0SignIn(
  auth0Token: string,
  track: (...props: any) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .auth0SignIn(auth0Token)
      .then((auth: Auth) => createSession(auth, { api, normalize }, dispatch, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
      });
  };
}

export function openIdSignIn(
  openIdToken: string,
  track: (...props: any) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .openIdSignIn(openIdToken)
      .then((auth: Auth) => createSession(auth, { api, normalize }, dispatch, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
      });
  };
}

function createSession(
  auth: Auth,
  { api, normalize }: ThunkExtraArg,
  dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
  track?: (...props: any) => void
): Promise<void> {
  const parsedAuth = auth.accessTokenData ? auth : mapAuthForRedux(auth);

  return api.auth.getUser(getTokenSub(parsedAuth), parsedAuth.accessToken).then(user => {
    if (!user) {
      return; // TODO remove this and fix types
    }

    dispatch(updateAccessToken(parsedAuth));

    dispatch(
      addEntities({
        users: {
          [user._id as string]: user,
        },
      })
    );

    // it should cover starting a tab too so maybe converting loggedin to a thunk would work better?
    scheduleRenewToken(parsedAuth, () => dispatch(renewAccessToken(parsedAuth)));
    track?.(MixpanelEventTypes.LoginSessionStarted, {
      [MixpanelKeys.AppVersion]: getReleaseTag(),
      [MixpanelKeys.AppType]: 'PWA',
      [MixpanelKeys.LoginSessionId]: parsedAuth.accessTokenData ? getTokenLoginSessionId(parsedAuth) : 'N/A',
      [MixpanelKeys.OrganisationId]: parsedAuth.accessTokenData ? getTokenMainOrgId(parsedAuth) : 'N/A',
      [MixpanelKeys.OrganisationName]: parsedAuth.accessTokenData ? getTokenMainOrgName(parsedAuth) : 'N/A',
      [MixpanelKeys.UserId]: parsedAuth.accessTokenData?.sub,
      distinct_id: parsedAuth.accessTokenData?.sub,
    });
    dispatch(loggedIn(parsedAuth));
  });
}
function isReduxAuthTokenSynced(reduxAuth: Auth) {
  const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};
  return reduxAuth.accessToken && localStorageAuth.accessToken && reduxAuth.accessToken === localStorageAuth.accessToken;
}
function isReduxAuthUserSynced(reduxAuth: Auth) {
  const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};
  return reduxAuth.accessTokenData?.sub === localStorageAuth?.accessTokenData?.sub;
}

export function checkAccessTokenOnRouteChange(): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    const reduxAuth = getState().auth;
    const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};

    // If no local storage auth found, we log tab out
    if (!localStorageAuth?.refreshToken && reduxAuth?.refreshToken) {
      dispatch(logout(window.location.pathname));
      return Promise.resolve();
    }
    // no action as no local storage auth and no redux auth
    if (!localStorageAuth?.refreshToken && !reduxAuth?.refreshToken) {
      return Promise.resolve();
    }

    // if localstorage auth is valid and redux does not match, update Redux
    if (localStorageAuth?.refreshToken && isAccessTokenValid(localStorageAuth)) {
      if (isReduxAuthTokenSynced(reduxAuth)) {
        // no action as local storage matches redux
        return Promise.resolve();
      } else {
        if (renewTokenTimer) {
          clearTimeout(renewTokenTimer);
        }
        // update redux to use local storage auth
        return createSession(
          localStorageAuth,
          { api, normalize },
          dispatch,
          reduxAuth?.accessTokenData?.sub !== localStorageAuth?.accessTokenData?.sub
            ? getMixpanelWithDefaultProps(localStorageAuth)?.track // exceptional scenario in which the MixpanelProvider may not be in sync with correct data
            : undefined
        );
      }
    }

    if (localStorageAuth?.accessToken && !reduxAuth.accessToken) {
      return Promise.resolve();
    }

    // if all else fails renew accessToken
    dispatch(renewAccessToken(localStorageAuth || {}));
    return Promise.resolve();
  };
}

/**
 * TODO https://centurytech.atlassian.net/browse/TL-76 && https://centurytech.atlassian.net/browse/TL-689
 * Note for future selves:
 * Working on TL-689, I'm pretty sure this function can be removed and just let 'getRedirectRouteByRole'
 * in src/century-core/core-auth/utils/roles.ts do its job instead, logging in doesn't actually redirects you,
 * it just routes you into `Page.tsx` from `App.tsx`, instead of going to `Login.tsx`. The redirect in `Page.tsx`
 * sends you to `getRedirectRouteByRole` and it does its magic.
 * But since the pentesting is releatively urgent and I'm worried about losing some functionality with SSO logins
 * I'll leave it for now and recheck this as part of the work for TL-76.
 */
const gotoRedirectUrlFromQueryString = () => {
  const redirect = queryString.parse(window.location.search).redirect as string;
  if (redirect && !redirect.includes('javascript') && (!isValidHttpUrl(redirect) || new URL(redirect).origin === window.location.origin)) {
    window.location.href = redirect;
  }
};
