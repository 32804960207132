import api from 'api/api';
import { AvailableOrgTypes, defaultLocale, defaultOrgType } from 'century-core/entities/Intl/defaultIntlValues';
import Auth from 'century-core/entities/Auth/Auth';
import { TranslationDocument } from 'entities/TranslationDocument/TranslationDocument';
import { getTokenSettingsLocale, getTokenSettingsOrgType } from 'century-core/core-auth/utils';
import i18next from 'i18next';
import { Settings as LuxonSettings } from 'luxon';
import { isRTLLocaleFromTokenLocale } from 'century-core/core-utils/utils/intl/intl';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';

export const runPolyfills = async (locale: string) => {
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill-force');
  }

  if (shouldPolyfillGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill-force');
  }

  if (shouldPolyfillDateTimeFormat(locale)) {
    await import('@formatjs/intl-datetimeformat/polyfill-force'); // Add locale data for de
    await import(`@formatjs/intl-datetimeformat/locale-data/${locale}`); // Add locale data for de
  }

  if (shouldPolyfillPluralRules(locale)) {
    await import('@formatjs/intl-pluralrules/polyfill-force');
    await import(`@formatjs/intl-pluralrules/locale-data/${locale}`); // Add locale data for de
  }

  if (shouldPolyfillRelativeTimeFormat(locale)) {
    await import('@formatjs/intl-relativetimeformat/polyfill-force');
    await import(`@formatjs/intl-relativetimeformat/locale-data/${locale}`);
  }

  if (shouldPolyfillNumberFormat(locale)) {
    await import('@formatjs/intl-numberformat/polyfill-force');
    await import(`@formatjs/intl-numberformat/locale-data/${locale}`);
  }
};

export const updateLocaleSettings = async (auth: Auth, setMessages: React.Dispatch<React.SetStateAction<TranslationDocument | null>>) => {
  const tokenLocale = getTokenSettingsLocale(auth);
  const tokenOrgType = getTokenSettingsOrgType(auth);

  // TODO TECH-21654
  LuxonSettings.defaultLocale = tokenLocale;

  await runPolyfills(tokenLocale.substring(0, 2));
  await populateMessages(tokenLocale, tokenOrgType, setMessages, auth);
};

export const populateMessages = async (
  locale: string,
  orgType: AvailableOrgTypes,
  setMessages: (messages: TranslationDocument) => void,
  auth: Auth
) => {
  let messages = {};
  let defaultOrgTranslations = {};
  let defaultLocaleTranslations = {};
  let defaultTranslations = {};

  try {
    messages = await api.intl.fetchLocalTranslationMessages(locale, orgType);
  } catch (e) {
    // Fail silently.
  }

  // Loading default orgtype resources for missing keys. PhraseApp/Bitbucket interactions don’t provide
  // a fallback project in case the key is nonexistent so we have to provide a fallback logic by ourselves
  if (orgType !== defaultOrgType) {
    try {
      defaultOrgTranslations = await api.intl.fetchLocalTranslationMessages(locale, defaultOrgType);
    } catch (e) {
      // Fail silently.
    }
  }

  if (locale !== defaultLocale) {
    try {
      defaultLocaleTranslations = await api.intl.fetchLocalTranslationMessages(defaultLocale, orgType);
    } catch (e) {
      // Fail silently.
    }
  }

  if (orgType !== defaultOrgType && locale !== defaultLocale) {
    try {
      defaultTranslations = await api.intl.fetchLocalTranslationMessages(defaultLocale, defaultOrgType);
    } catch (e) {
      // Fail silently.
    }
  }

  messages = { ...defaultTranslations, ...defaultLocaleTranslations, ...defaultOrgTranslations, ...messages };

  if (Object.keys(messages).length === 0) {
    return Promise.reject('No translations found');
  }

  initialisePolymerTranslations(locale, messages, auth);
  setMessages(messages);
  return Promise.resolve();
};

export const showContent = (auth: Auth, messages: TranslationDocument): boolean => {
  return Object.keys(messages).length > 0;
};

enum TextDirection {
  RTL = 'rtl',
  LTR = 'ltr',
  AUTO = 'auto',
}

const initialisePolymerTranslations = (locale: string, resources: TranslationDocument, auth: Auth) => {
  const options = {
    fallbackLng: [defaultLocale],
    lng: locale,
    resources: {
      [locale]: {
        translation: resources,
      },
    },
    interpolation: {
      prefix: '{',
      suffix: '}',
      skipOnVariables: false,
    },
    nsSeparator: '__NAMESPACES__ARE__DISABLED__', // See TECH-17053
  };

  i18next.init(options);

  // set html lang attribute
  document.getElementsByTagName('html')[0].setAttribute('lang', locale);

  // set dir attribute in the body
  document.body.setAttribute('dir', isRTLLocaleFromTokenLocale(auth) ? TextDirection.RTL : TextDirection.LTR);

  (window as any).i18next = i18next;
};
