import { EndpointPath, legacyGetUrl, PublicApiUrls } from 'century-core/core-apis/ApiProvider';
import { GET, PUT } from '../utils';

export function getScoutUrl(endpoint?: EndpointPath) {
  return legacyGetUrl(PublicApiUrls.Scout, 2, endpoint);
}

const getUserAchievements = (
  userId: string,
  accessToken: string,
  params?: Ctek.Scout.AchievementParams
): Promise<Ctek.Scout.AchievementResponse> => {
  const url = getScoutUrl(`/achievements/-/users/${userId}`);
  if (params) {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => {
      if (v !== null && v !== undefined) {
        searchParams.set(k, `${v}`);
      }
    });
    url.search = searchParams.toString();
  }

  return GET<Ctek.Scout.AchievementResponse>({ url, token: accessToken });
};

export const getAllUserBadges = (userId: string, accessToken: string) => {
  return getUserAchievements(userId, accessToken, { achievementType: 'badge', orderBy: 'issuedDate desc' });
};

// TODO is this to be limited to badges?
export const getUnreadUserAchievements = (userId: string, accessToken: string, hasLXJourneyEnabled = false) => {
  return getUserAchievements(userId, accessToken, {
    isAck: hasLXJourneyEnabled ? undefined : false,
    isIssued: true,
    achievementType: 'badge',
    orderBy: 'issuedDate desc',
    pageSize: 1,
  });
};

export const acknowledgeAchievement = (userId: string, accessToken: string, achievementId: string | null) => {
  const url = getScoutUrl(achievementId ? `/achievements/${achievementId}/users/${userId}/ack` : `/achievements/users/${userId}/ack`);

  return PUT<null>({ url, token: accessToken, body: {} });
};

export const acknowledgeAllAchievements = async (userId: string, accessToken: string) =>
  await acknowledgeAchievement(userId, accessToken, null);

export const getUserStreaks = (userId: string, accessToken: string) =>
  getUserAchievements(userId, accessToken, { achievementType: 'streak' });
