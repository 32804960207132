// --- Entities ---
import { Auth } from '../../entities/Auth/Auth';

// --- Services ---
import { mapAuthForRedux } from './auth.mapper';
import { read } from 'century-core/core-utils/utils/localStorage/localStorage';

export const STORAGE_AUTH_KEY = 'auth';

export function parseAuthFromLocalstorage(): Auth | undefined {
  const auth = read(STORAGE_AUTH_KEY);

  if (auth === undefined) {
    return undefined;
  }

  const parsedAuth = JSON.parse(auth);

  if (!parsedAuth || !parsedAuth.accessToken) {
    return undefined;
  }

  return mapAuthForRedux(parsedAuth);
}
