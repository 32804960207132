import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import StudentDashboardCourses from '../features-dashboard/StudentDashboard/StudentDashboardCourses/StudentDashboardCourses';
import StudentDashboardOverview from '../features-dashboard/StudentDashboard/StudentDashboardOverview/StudentDashboardOverview';
import StudentDashboardMenu from '../features-dashboard/StudentDashboard/StudentDashboardMenu/StudentDashboardMenu';
import StudentDashboardAssignmentsV1 from 'century-core/features-dashboard/StudentDashboard/StudentDashboardAssignmentsV1/StudentDashboardAssignmentsV1';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';
import { shouldOverrideFeature } from 'century-core/core-auth/utils';

function StudentDashboardForLeadership(props: { user: any; isLeadership?: boolean }) {
  const auth = useAuth();
  const match = useRouteMatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const searchParamsString = searchParams.toString();
  const hasAssignmentsEnabled = !shouldOverrideFeature('assignments', auth);

  return (
    <>
      <StudentDashboardMenu url={''} hasAssignmentsTab={hasAssignmentsEnabled} hasAchievementsTab={false} />
      <Switch>
        <Route path={'/overview'}>
          <StudentDashboardOverview user={props.user} userMode={DashboardUserMode.TEACHER} qa="student-overview--leadership" />
        </Route>

        <Route path={'/courses'}>
          <StudentDashboardCourses studentId={props.user.userId} userMode={DashboardUserMode.TEACHER} />
        </Route>

        {hasAssignmentsEnabled && (
          <Route path={'/assignments'}>
            <StudentDashboardAssignmentsV1 studentId={props.user.userId} userMode={DashboardUserMode.TEACHER} />
          </Route>
        )}

        {history.location.pathname === match.url && (
          <Route path={props.isLeadership ? '' : '/'}>
            <Redirect to={`${match.url}overview?${searchParamsString}`} />
          </Route>
        )}
      </Switch>
    </>
  );
}

export default StudentDashboardForLeadership;
