import { changePasswordSentinel } from 'century-core/core-apis/sentinel/auth/auth';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils';
import { createLocalisedErrorMessage } from 'century-core/entities/ErrorMessage/createLocalisedErrorMessage';

export async function recoverPassword(accessToken: string, newPassword: string) {
  return createFetchRequestWithLocalisedError(
    () =>
      changePasswordSentinel(accessToken, newPassword).catch(err => {
        if (err.errorData.message.includes('The token is expired since')) {
          throw createLocalisedErrorMessage('localised-errors-recover-password-expired-token');
        } else {
          throw createLocalisedErrorMessage('localised-errors-user-profile-generic-error');
        }
      }),
    'localised-errors-user-profile-generic-error',
    true
  );
}