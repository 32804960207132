import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { Actions, ActionTypes } from '../../actions/userProfile/userProfile';

export interface UserProfile {
  avatars: string[] | [];
  error: ErrorMessage<Errors> | null;
  loaded: boolean;
  showAddEmail: boolean;
  showChangePassword: boolean;
  showChangeUsername: boolean;
}

export const defaultData: UserProfile = {
  avatars: [],
  error: null,
  loaded: false,
  showAddEmail: false,
  showChangePassword: false,
  showChangeUsername: false,
};

export function userProfile(state = defaultData, action: Actions): UserProfile {
  switch (action.type) {
    case ActionTypes.Error:
      return { ...state, loaded: true, error: action.error };
    case ActionTypes.Loading:
      return { ...state, loaded: false };
    case ActionTypes.Loaded:
      return { ...state, loaded: true };
    default:
      return state;
  }
}

export default UserProfile;
