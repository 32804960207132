import { useAccessTokenRef } from 'century-core/core-auth/hooks/useAuth';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// TODO: this info is used in other places in redux so must remain for now
import { loadUserProfile } from 'state/actions/userProfile/userProfile'; // eslint-disable-line import/no-restricted-paths
import { getLoggedinUserProfile, getUserProfileError, getUserProfileLoaded } from 'state/selectors';  // eslint-disable-line import/no-restricted-paths

export interface UserProfileContextType {
  loaded: boolean;
  error: ErrorMessage<Errors> | null;
  userProfile: ReturnType<typeof getLoggedinUserProfile>;
  loadUserProfile: (userId: string, tryCache?: boolean) => void;
}

const UserProfileContext = createContext<UserProfileContextType>({ loaded: false, userProfile: null, error: null } as UserProfileContextType);

function useReduxUserProfile() {
  const dispatch = useDispatch();
  const userProfile = useSelector(getLoggedinUserProfile);
  const loaded = useSelector(getUserProfileLoaded);
  const error = useSelector(getUserProfileError);
  const accessTokenRef = useAccessTokenRef();

  const dispatchLoadUserProfile = useCallback((userId: string, tryCache: boolean) => {
    dispatch(loadUserProfile(userId, accessTokenRef.current, tryCache));
  }, [dispatch, accessTokenRef]);

  return {
    loadUserProfile: dispatchLoadUserProfile,
    userProfile,
    loaded,
    error,
  }
}

export const useUserProfile = () => {
  return useContext(UserProfileContext);
}

export const UserProfileProvider = (props: { children: ReactNode }) => {
  const reduxUserProfile = useReduxUserProfile();

  return (
    <UserProfileContext.Provider value={reduxUserProfile}>
      { props.children }
    </UserProfileContext.Provider>
  )
}

export default UserProfileContext;
