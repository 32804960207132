import { ApolloClient } from '@apollo/client';
import { createHttpLink } from '@apollo/client';
import { ApolloLink, from } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { legacyGetUrl, PublicApiUrls } from 'century-core/core-apis/ApiProvider';
import { Store } from 'redux';
import * as SentryLib from 'century-core/core-utils/lib/sentry';

const createApolloClient = (store: Store) => {
  const accessToken = localStorage.getItem('accessToken');
  const roentgenUrl = legacyGetUrl(PublicApiUrls.Roentgen, undefined, '/graphql');

  const getFetchConfig = (state: any) => ({
    fetchOptions: {
      headers: { Authorization: state.auth.accessToken !== null ? `Bearer ${state.auth.accessToken}` : `Bearer ${accessToken}` },
    },
    timeout: 5000,
  });

  // Create HTTP Link
  const roentgenLink = createHttpLink({
    ...getFetchConfig(store.getState()),
    uri: `${roentgenUrl.href}`,
  });

  // Create onError Link
  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      let graphQLErrorsText = '';
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        graphQLErrorsText += `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions.code}\n`;
      });
      SentryLib.captureMessage(graphQLErrorsText);
    }

    if (networkError) {
      SentryLib.captureException(networkError);
    }
  });

  // Middlewares
  const sendTokenWithRequestLink = new ApolloLink((operation: any, forward: any) => {
    const auth = () => store.getState().auth;
    if (!auth().accessToken) {
      return;
    }

    operation.setContext(() => ({
      headers: {
        ...(operation.getContext()?.headers || {}), // for request specific headers (e.g. google auth token)
        Authorization: `Bearer ${auth().accessToken}`,
      },
    }));
    return forward(operation); // eslint-disable-line consistent-return
  });

  const cache = new InMemoryCache();

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    link: from([sendTokenWithRequestLink, onErrorLink, roentgenLink]),
  });
};

const getAccessTokenFromLocalStorage = () => JSON.parse(window.localStorage.getItem('auth') || '')?.accessToken || '';

// The same function, but with the redux bits replaced
export const createApolloClientForPolymer = () => {
  const roentgenUrl = legacyGetUrl(PublicApiUrls.Roentgen, undefined, '/graphql');

  const getFetchConfig = () => ({
    fetchOptions: {
      headers: { Authorization: `Bearer ${getAccessTokenFromLocalStorage()}` },
    },
    timeout: 5000,
  });
  const fetchConfig = getFetchConfig();

  // Create HTTP Link
  const roentgenLink = createHttpLink({
    ...fetchConfig,
    uri: `${roentgenUrl.href}`,
  });
  const cache = new InMemoryCache();

  // Middlewares
  const sendTokenWithRequestLink = new ApolloLink((operation: any, forward: any) => {
    if (!getAccessTokenFromLocalStorage()) {
      return;
    }

    operation.setContext(() => ({
      headers: {
        ...(operation.getContext()?.headers || {}), // for request specific headers (e.g. google auth token)
        Authorization: `Bearer ${getAccessTokenFromLocalStorage()}`,
      },
    }));
    return forward(operation); // eslint-disable-line consistent-return
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    link: from([sendTokenWithRequestLink, roentgenLink]),
  });
};

export default createApolloClient;
