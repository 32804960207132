import { Button, Form } from '@ctek/design-system'
import { Component } from 'react'
import './SigninForm.scss'

export interface SigninFormProps {
  ariaLabelledBy?: string
  onSubmit: () => void
  children: SigninFormChildren
  qa?: string
}

export interface SigninFormChildren {
  formError?: React.ReactElement[] | React.ReactElement | null
  password: React.ReactElement
  recoverPasswordLink?: React.ReactElement
  ssoButtons?: React.ReactElement[] | React.ReactElement<typeof Button> | null
  submitButton: React.ReactElement<typeof Button>
  username: React.ReactElement
  messages?: React.ReactElement | null
}

export class SigninForm extends Component<SigninFormProps & { enableUsernameAndPassword: boolean }, {}> {
  public render() {
    const formError = this.props.children.formError;
    const messages = this.props.children.messages ? <div className="signin-form__message">{this.props.children.messages}</div> : null;
    const { enableUsernameAndPassword } = this.props;

    return (
      <Form onSubmit={this.props.onSubmit} qa={this.props.qa}>
        {!!enableUsernameAndPassword ? (
          <>
            {this.props.children.username}
            {this.props.children.password}
            {formError && (
              <div data-testid="login-form-error">
                {formError}
              </div>
            )}
            {this.props.children.submitButton}
          </>
        ) : null} 
        {messages}
        {this.props.children.ssoButtons}
      </Form>
    );
  }
}

export default SigninForm;
