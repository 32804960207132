import { MIN_DOB, MIN_PASSWORD_LENGTH } from 'century-core/core-utils/utils/utils';
import { DateTime, Interval } from 'luxon';

export type ClassCodeValidSignupValues = {
  confirmEmail: string;
  confirmPassword: string;
  dob: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  password: string;
  consentGiven: boolean;
};

export type ClassCodeInitialValues = {
  classCode: string;
};

export const classCodeInitialValues: ClassCodeInitialValues = {
  classCode: '',
};

export const formErrors: { [key: string]: string } = {
  'class-code-signup-error-consent-required': 'Please consent to our privacy policy',
  'class-code-class-code-required': 'Please enter your class code',
  'class-code-signup-error-confirm-email': 'Please confirm your email',
  'class-code-signup-error-confirm-password': 'Please confirm your password',
  'class-code-signup-error-dob': 'Please fill in your date of birth',
  'class-code-signup-error-dob-invalid': 'Please fill in a valid date of birth',
  'class-code-signup-error-email': 'Please fill in your email',
  'class-code-signup-error-email-dont-match': "Emails don't match",
  'class-code-signup-error-email-invalid': 'Invalid email address',
  'class-code-signup-error-email-repeated': 'Repeated email address',
  'class-code-signup-error-first-name': 'Please fill in your first name',
  'class-code-signup-error-gender': 'Please select a gender',
  'class-code-signup-error-last-name': 'Please fill in your last name',
  'class-code-signup-error-password': 'Please choose a password',
  'class-code-signup-error-password-dont-match': "Passwords don't match",
  'error-password-too-short': `Passwords must be at least ${MIN_PASSWORD_LENGTH} characters long`,
};

export const validateClassCodeValidSignupForm = (values: ClassCodeValidSignupValues) => {
  const errors: Record<string, keyof typeof formErrors> = {};

  if (!values.consentGiven) {
    errors.consentGiven = formErrors['class-code-signup-error-consent-required'];
  }

  // First name and last name check
  if (!values.firstName) {
    errors.firstName = formErrors['class-code-signup-error-first-name'];
  }

  if (!values.lastName) {
    errors.lastName = formErrors['class-code-signup-error-last-name'];
  }

  // Gender check
  if (!values.gender) {
    errors.gender = formErrors['class-code-signup-error-gender'];
  }

  // Emails check
  if (!values.email) {
    errors.email = formErrors['class-code-signup-error-email'];
  }

  if (!values.confirmEmail) {
    errors.confirmEmail = formErrors['class-code-signup-error-confirm-email'];
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formErrors['class-code-signup-error-email-invalid'];
  }

  if (values.email !== values.confirmEmail) {
    errors.email = formErrors['class-code-signup-error-email-dont-match'];
  }

  // Passwords check
  if (!values.password) {
    errors.password = formErrors['class-code-signup-error-password'];
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = formErrors['class-code-signup-error-confirm-password'];
  }

  if (values.password !== values.confirmPassword) {
    errors.password = formErrors['class-code-signup-error-password-dont-match'];
  }

  if (values.password && values.password.length < MIN_PASSWORD_LENGTH) {
    errors.password = formErrors['error-password-too-short'];
  }

  // DOB check
  const inputDate = DateTime.fromISO(values.dob);
  if (values.dob === '') {
    errors.dob = formErrors['class-code-signup-error-dob'];
  } else if (Interval.fromDateTimes(new Date(MIN_DOB), DateTime.local()).contains(inputDate) === false || inputDate.isValid === false) {
    errors.dob = formErrors['class-code-signup-error-dob-invalid'];
  }

  return errors;
};

export const validateClassCodeForm = (values: ClassCodeInitialValues) => {
  const errors: Record<string, keyof typeof formErrors> = {};

  if (!values.classCode) {
    errors.classCode = formErrors['class-code-class-code-required'];
  }

  return errors;
};
