import * as R from 'ramda';
import { Auth } from 'century-core/entities/Auth/Auth';
import {
  getTokenLoginSessionId,
  getTokenMainOrgId,
  getTokenMainOrgName,
  getTokenSettingsLocale,
  getTokenSub,
} from 'century-core/core-auth/utils';
import { isProductionEnvironment } from '../config/config';
import { MixpanelEventTypes } from './MixpanelEventTypes';
import { MixpanelKeys } from './MixpanelKeys';

export type MixpanelParams = Partial<
  {
    [key in MixpanelKeys]: any;
  }
>;

export function getDefaultMixpanelProps(auth: Auth) {
  let userId;
  try {
    userId = getTokenSub(auth);
  } catch (error) {
    userId = 'N/A';
  }
  return {
    [MixpanelKeys.IsTest]: !isProductionEnvironment(),
    Library: 'React',
    Locale: getTokenSettingsLocale(auth),
    [MixpanelKeys.LoginSessionId]: auth.accessTokenData ? getTokenLoginSessionId(auth) : 'N/A',
    [MixpanelKeys.OrganisationId]: auth.accessTokenData ? getTokenMainOrgId(auth) : 'N/A',
    [MixpanelKeys.OrganisationName]: auth.accessTokenData ? getTokenMainOrgName(auth) : 'N/A',
    [MixpanelKeys.UserId]: userId,
    distinct_id: userId,
  };
}

// 500ms debouncers to prevent spamming of events; each event type should have a unique debouncer to prevent accidental cancellations
const debouncers: Partial<{ [k in MixpanelEventTypes]: number }> = {}
// B2C-859 attempts to deal with the issues from duplicate page views
// this requires a longer debouncer than occurs with other events and to not override existing page view events if multiple pages are viewed very quicklt
const pageViewDebouncers: { [k: string]: number } = {};

export function getMixpanelWithDefaultProps<T>(auth: Auth, additionalProps?: T): any {
  const mixpanel = R.clone(window.mixpanel);
  const defaultProps = getDefaultMixpanelProps(auth);

  return {
    ...mixpanel,
    track: (eventType: MixpanelEventTypes, props: MixpanelParams) => {
      let debounceToClear = debouncers[eventType];
      let debounceDuration = 100;
      if (eventType === MixpanelEventTypes.PageViewed) {
        debounceToClear = pageViewDebouncers[props['Page Name']];
        debounceDuration = 500;
      } 
      if (debounceToClear) {
        window.clearTimeout(debounceToClear);
      }
      const timeoutRef = window.setTimeout(() => {
        mixpanel.track(eventType, { ...defaultProps,  ...additionalProps, ...props });

        // remove historical debouncers once applied
        if (eventType === MixpanelEventTypes.PageViewed) {
          delete pageViewDebouncers[props['Page Name']];
        } else {
          delete debouncers[eventType];
        }
      }, debounceDuration);
      if (eventType === MixpanelEventTypes.PageViewed) {
        pageViewDebouncers[props['Page Name']] = timeoutRef;
      } else {
        debouncers[eventType] = timeoutRef;
      }
    },
  };
}
