import { Button, FormError, Input, Label, SignInBox } from '@ctek/design-system';
import { SigninFormChildren } from 'layout/elementsSignin/SigninForm/SigninForm';
import { Formik } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Select from 'state/selectors';
import { ViewState } from 'century-core/entities/Auth/Auth';
import { focusInputElement } from 'century-core/core-utils/utils/utils';
import { GoogleSeamlessLogin, isGoogleSeamlessLoginEnabled } from './GoogleSeamlessLogin';
import { isLtiLoginEnabled, LtiLogin } from './LtiLogin';
import { isMicrosoft365SeamlessLoginEnabled, Microsoft365SeamlessLogin } from './Microsoft365SeamlessLogin';
import { SSOButtons } from './SSOButtons';
import SigninForm from 'layout/elementsSignin/SigninForm/SigninForm';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';
import { clearError } from 'state/actions/auth/auth';
import { errors } from 'century-core/entities/ErrorMessage/Errors';
import LoginFormError from './LoginFormError';
import { AppLogo } from 'components/CustomTheme/AppLogo';

interface Props {
  layoutForm: 'SigninForm' | 'BasicSigninForm';
  login: (username: string, password: string) => void;
  setEmailAddress: (email: string) => void;
  qa?: string;
}

const formErrors = {
  'login-error': 'Required',
};

const loginFormInitialValues = {
  password: '',
  username: '',
};

const LOCKOUT_TIME_INTERVAL = 10000;

type LoginFormInitialValues = typeof loginFormInitialValues;

export const LoginForm = (props: Props) => {
  const email = useSelector(Select.getEmailAddress);
  const [lockoutTime, setLockoutTime] = React.useState<null | number>(null);
  const [shakeAnimation, setShakeAnimation] = React.useState(false);
  const auth = useAuth();
  const dispatch = useDispatch();
  const firstRun = React.useRef(true);
  const enableUsernameAndPassword = auth.orgSetting?.settings?.global?.authSettings?.isUsernameAndPasswordEnabled ?? true;
  const [canShowPassword, setcanShowPassword] = React.useState(false);

  const clearAuthErrorDispatch = React.useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  // Handle counting down the lockout timer. Needs to run before the componentDidMount hook
  React.useEffect(() => {
    if (lockoutTime === null) {
      if (firstRun.current) {
        return;
      }
      return clearAuthErrorDispatch();
    }

    const timer = setTimeout(() => {
      setLockoutTime(lockoutTime - LOCKOUT_TIME_INTERVAL > 0 ? lockoutTime - LOCKOUT_TIME_INTERVAL : null);
    }, LOCKOUT_TIME_INTERVAL);

    return () => clearTimeout(timer);
  }, [lockoutTime, clearAuthErrorDispatch]);

  React.useEffect(() => {
    if (auth.error?.code === errors.LockoutErr) {
      setLockoutTime(auth.error.values);
    }
  }, [auth.error]);

  if (isLtiLoginEnabled(auth)) {
    return <LtiLogin auth={auth} />;
  }

  if (isGoogleSeamlessLoginEnabled(auth)) {
    return <GoogleSeamlessLogin auth={auth} />;
  }

  if (isMicrosoft365SeamlessLoginEnabled(auth)) {
    return <Microsoft365SeamlessLogin auth={auth} />;
  }

  if (email) {
    loginFormInitialValues.username = email;
  }

  const layoutFormElemtCreator = (handleSubmit: () => void, children: SigninFormChildren) => {
    return props.layoutForm === 'SigninForm' ? (
      <SignInBox>
        <SignInBox.Header>
          <AppLogo />
        </SignInBox.Header>
        <SignInBox.Form qa={props.qa}>
          <SigninForm enableUsernameAndPassword={enableUsernameAndPassword} onSubmit={handleSubmit} qa={`login-form-${props.layoutForm}`}>
            {children}
          </SigninForm>
        </SignInBox.Form>
      </SignInBox>
    ) : (
      <SigninForm enableUsernameAndPassword={enableUsernameAndPassword} onSubmit={handleSubmit} qa={`login-form-${props.layoutForm}`}>
        {children}
      </SigninForm>
    );
  };

  const validateRecoverPasswordForm = (values: LoginFormInitialValues) => {
    const errors: Record<string, keyof typeof formErrors> = {};

    if (!values.username) {
      errors.username = 'login-error';
    }

    if (!values.password) {
      errors.password = 'login-error';
    }

    return errors;
  };

  const onInputChange = (e: React.ChangeEvent<any>, handleChange: (e: React.ChangeEvent<any>) => void) => {
    handleChange(e);
    setLockoutTime(null);
    clearAuthErrorDispatch();
  };

  const onLoginSubmit = async (values: { username: string; password: string }, setSubmitting: (val: boolean) => void) => {
    if (lockoutTime) {
      if (!shakeAnimation) {
        setShakeAnimation(true);
        setTimeout(() => {
          setShakeAnimation(false);
        }, 1000);
      }
      return;
    } else {
      await props.login(values.username.trim(), values.password);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={loginFormInitialValues}
      validate={validateRecoverPasswordForm}
      onSubmit={async (values, { setSubmitting }) => {
        onLoginSubmit(values, setSubmitting);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) =>
        layoutFormElemtCreator(handleSubmit, {
          // need to check for error here as returning null inside LoginFormError causes a weird tiny error box to always be rendered
          formError: auth.error ? <LoginFormError auth={auth} lockoutTime={lockoutTime} shakeAnimation={shakeAnimation} /> : undefined,
          password: (
            <>
              <Label>
                <Label.Text>
                  <FormattedMessage id="login-password" defaultMessage="Password" />
                  <Label.Link>
                  <NavLink to="/login/recover-password" data-testid="recover-password-forgot-pasword-link">
                    <FormattedMessage id="login-forgot-pasword-link" defaultMessage="Forgot your password?" />
                  </NavLink>
                  </Label.Link>
                </Label.Text>
                <Input 
                  type={canShowPassword ? 'text' : 'password'}
                  onChange={(e: React.ChangeEvent) => onInputChange(e, handleChange)}
                  value={values.password}
                  name="password"
                  qa="login-password-input"
                />
                {errors.password && touched.password ? (
                  <FormError qa="login-password-input-error">
                    <FormattedMessage id={errors.password} defaultMessage={formErrors[errors.password]} />
                  </FormError>) 
                : null}
              </Label>
              <Label>
              <Input
                type="checkbox"
                onChange={() => setcanShowPassword(!canShowPassword)}
              >
                <Input.Text>
                  <FormattedMessage id="show-password" defaultMessage="Show password" />
                </Input.Text>
              </Input>
            </Label>
          </>
          ),
          ssoButtons: <SSOButtons />,
          submitButton: (
            <Button
              type="submit"
              size="large"
              disabled={isSubmitting && auth.viewState === ViewState.LoggingIn}
              qa="login-button"
              variant="primary"
              width="full"
            >
              <FormattedMessage id="login-sign-in-button-label" defaultMessage="Sign In" />
            </Button>
          ),
          username: (
            <Label>
              <Label.Text>
                <FormattedMessage id="login-username" defaultMessage="Username or email" />
              </Label.Text>
              <Input 
                type="text" 
                onChange={(e: React.ChangeEvent) => onInputChange(e, handleChange)}
                onBlur={() => props.setEmailAddress(values.username)}
                value={values.username}
                name="username"
                qa="login-username-input"
                ref={focusInputElement}
              />
              {errors.username && touched.username ? (
                <FormError qa="login-username-input-error">
                  <FormattedMessage id={errors.username} defaultMessage={formErrors[errors.username]} />
                </FormError>) 
              : null}
            </Label>
          ),
        })
      }
    </Formik>
  );
};
