import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import Spinner from 'century-core/core-components/Spinner/Spinner';
import Auth from 'century-core/entities/Auth/Auth';
import { googleSignIn, GoogleAccessToken } from 'state/actions/auth/auth';
import { SessionError } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from 'react-google-login';

export const isGoogleSeamlessLoginEnabled = (auth: Auth) => {
  const ssoSettings = auth.orgSetting?.settings?.global?.authSettings?.sso;
  const isGoogleEnabled = ssoSettings?.google ? ssoSettings?.google.isEnabled : false;
  const isGoogleSeamless = ssoSettings?.google ? ssoSettings?.google.isSeamless : false;
  if (isGoogleEnabled && isGoogleSeamless) {
    return true;
  } else {
    return false;
  }
};

export const GoogleSeamlessLogin = (props: { auth: Auth }) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const googleSignInDispatch = (googleAccessToken: GoogleAccessToken) => {
    dispatch(googleSignIn(googleAccessToken.tokenObj.id_token, mixpanel.track));
  };

  const { signIn } = useGoogleLogin({
    onSuccess: googleSignInDispatch as any,
    onFailure: (err: any) => {
      return;
    },
    clientId: '970757263143-gvvdult95616r8uq4pihjghkrmoccl6c.apps.googleusercontent.com',
    cookiePolicy: 'single_host_origin',
    scope: 'profile email',
  });
  signIn();
  return (
    <Spinner type="fullpage" showContent={!!props.auth.error}>
      <SessionError>
        <SessionError.Heading>
          <FormattedMessage defaultMessage="Whoops! Connection has been lost." id="session-error-default-heading" />
        </SessionError.Heading>
        <SessionError.Description>
          <FormattedMessage
            defaultMessage="Something seems to have gone wrong. Try signing into your account again to return to where you left off."
            id="session-error-default-description"
          />
        </SessionError.Description>
      </SessionError>
    </Spinner>
  );
};
