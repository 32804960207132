/*
  An advanced wrapper which contains all of the relevant providers to use:
    - AuthContext
    - GraphQL requests
    - Mixpanel events
    - Internationalization
*/
import { useCallback, useEffect, useRef, useState } from 'react';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/auth.utils';
import Auth from 'century-core/entities/Auth/Auth';
import { AuthContext, AuthUpdateEvent } from 'century-core/core-auth/AuthContext';

const PolymerToReactAuthProvider = (props: any) => {
  const [auth, setAuth] = useState<Auth>(parseAuthFromLocalstorage()!);
  const authRef = useRef<Auth | undefined>(auth);

  const updateStorageIfRequired = useCallback((e: AuthUpdateEvent) => {
    const newAuth = e.detail.auth;
    if (newAuth?.accessToken && newAuth.accessToken !== authRef.current?.accessToken) {
      setAuth(newAuth);
      authRef.current = newAuth;
    }
  }, []);

  // listens to localStorage events and updates auth if required
  useEffect(() => {
    document.addEventListener('authUpdated', updateStorageIfRequired);
    return () => {
      document.removeEventListener('authUpdated', updateStorageIfRequired);
    };
  }, [updateStorageIfRequired]);

  return <AuthContext.Provider value={{ auth }}>{props.children}</AuthContext.Provider>;
};

export default PolymerToReactAuthProvider;
