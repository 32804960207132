import { History } from 'history';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import App from './App';
import { getTokenSub } from 'century-core/core-auth/utils';
import { Actions as AuthActions, checkAccessTokenOnRouteChange, getDomainSettings, loadLocalStorage } from 'state/actions/auth/auth';
import { Actions as UserProfileActions, loadUserProfile } from 'state/actions/userProfile/userProfile';
import { StoreState, ThunkExtraArg } from 'state/reducers/reducers';
import * as Select from 'state/selectors';
import * as SentryLib from 'century-core/core-utils/lib/sentry';

export type OwnProps = RouteComponentProps;

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => {
  return {
    auth: Select.getAuth(state),
    history: Select.getHistory(state, ownProps),
    location: Select.getLocation(state, ownProps),
    match: Select.getMatch(state, ownProps),
    user: Select.getCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, ThunkExtraArg, AuthActions | UserProfileActions>) => ({
  dispatchLoadLocalStorage: (historyPush: (path: History.Path) => void) => {
    dispatch(loadLocalStorage(historyPush));
  },
  getDomainSettings: (domain: string) => {
    dispatch(getDomainSettings(domain));
  },
  dispatchLoadUserProfile: (userId: string, accessToken: string, tryCache: boolean) =>
    dispatch(loadUserProfile(userId, accessToken, tryCache)),
  checkAccessTokenOnRouteChange: () => dispatch(checkAccessTokenOnRouteChange()),
});

const mergeProps = (propsFromState: StateProps, propsFromDispatch: DispatchProps, ownProps: OwnProps) => {
  return {
    ...propsFromState,
    loadLocalStorage: () => {
      propsFromDispatch.dispatchLoadLocalStorage(propsFromState.history.push);
    },
    getDomainSettings: propsFromDispatch.getDomainSettings,
    loadUserProfile: () => {
      if (propsFromState.auth) {
        const accessToken = propsFromState.auth.accessToken;
        const userId = getTokenSub(propsFromState.auth);
        if (accessToken && userId) {
          propsFromDispatch.dispatchLoadUserProfile(userId, accessToken, true);
        } else {
          SentryLib.captureMessage('Load user profile could not be dispatched because either user id or access token was undefined');
        }
      } else {
        SentryLib.captureMessage('Load user profile could not be dispatched because auth was undefined');
      }
    },
    checkAccessTokenOnRouteChange: propsFromDispatch.checkAccessTokenOnRouteChange,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MergeProps = ReturnType<typeof mergeProps>;

export type ConnectedProps = MergeProps;

export default compose(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps, mergeProps)
)(App as unknown as React.ComponentClass<OwnProps>);
