import { Button, Form, FormError, IllustrationClassCodeInvalid, Input, Label, SignInBox } from '@ctek/design-system';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { classCodeInitialValues, validateClassCodeForm } from '../validation';

interface Props {
  validateCode: (classCode: string) => void;
}

export const ClassCodeInvalid = (props: Props) => {
  return (
    <Formik
      initialValues={classCodeInitialValues}
      validate={validateClassCodeForm}
      onSubmit={async (values, { setSubmitting }) => {
        await props.validateCode(values.classCode);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <SignInBox.Header>
            <IllustrationClassCodeInvalid />
            <h1>
              <FormattedMessage id="class-code-invalid-title" defaultMessage="Whoops!" />
            </h1>
          </SignInBox.Header>
          <SignInBox.Body>
            <FormattedMessage
              id="class-code-invalid-instructions"
              defaultMessage="We don’t recognise that code. Make sure you’ve entered it correctly or talk to your teacher as the code may have expired."
            />
          </SignInBox.Body>
          <SignInBox.Form qa="class-code-invalid-form">
            <Form onSubmit={handleSubmit}>
              <Label>
                <Label.Text>
                  <FormattedMessage id="class-code-label" defaultMessage="Class code" />
                </Label.Text>
                <Input onChange={handleChange} value={values.classCode} name="classCode" qa="class-code-invalid-input" maxLength={30} />
                {errors.classCode && touched.classCode ? <FormError qa="class-code-input-error">{errors.classCode}</FormError> : null}
              </Label>
              <Button type="submit" disabled={isSubmitting} qa="class-code-invalid-submit" variant="primary" width="full">
                <FormattedMessage id="submit-button" defaultMessage="Submit" />
              </Button>
            </Form>
          </SignInBox.Form>
        </>
      )}
    </Formik>
  );
};
