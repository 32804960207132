import { FormattedMessage } from 'react-intl';
import { Tabs } from '@ctek/design-system';
import { useLocation, NavLink } from 'react-router-dom';
import { tabIsActive } from 'century-core/core-utils/utils/helpers';

interface StudentDashboardMenuProps {
  url: string;
  hasAssignmentsTab?: boolean;
  hasAchievementsTab?: boolean;
  hasMockTestTab?: boolean;
  hasLXJourneyEnabled?: boolean;
}

export default function StudentDashboardMenu({
  url,
  hasAssignmentsTab,
  hasAchievementsTab,
  hasMockTestTab,
  hasLXJourneyEnabled,
}: StudentDashboardMenuProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <Tabs>
      <Tabs.Item isActive={tabIsActive(`${url}/overview`)}>
        <NavLink to={`${url}/overview?${searchParams.toString()}`} data-testid="learner-dashboard-overview-tab-link">
          <FormattedMessage id="my-dashboard-overview-tab" defaultMessage="Overview" />
        </NavLink>
      </Tabs.Item>

      {hasLXJourneyEnabled && (
        <Tabs.Item isActive={tabIsActive(`${url}/subjects`)}>
          <NavLink to={`${url}/subjects?${searchParams.toString()}`} data-testid="learner-dashboard-subjects-tab-link">
            <FormattedMessage id="my-dashboard-subjects-tab" defaultMessage="Subjects" />
          </NavLink>
        </Tabs.Item>
      )}

      {/* // LX PHASE 1 - get rid of hasLXJourneyEnabled, '/courses' when everybody is on new LX Journey */}
      {!hasLXJourneyEnabled && (
        <Tabs.Item isActive={tabIsActive(`${url}/courses`)}>
          <NavLink to={`${url}/courses?${searchParams.toString()}`} data-testid="learner-dashboard-courses-tab-link">
            <FormattedMessage id="my-dashboard-courses-tab" defaultMessage="Courses" />
          </NavLink>
        </Tabs.Item>
      )}

      {hasMockTestTab && (
        <Tabs.Item isActive={tabIsActive(`${url}/mock-tests`)}>
          <NavLink to={`${url}/mock-tests?${searchParams.toString()}`} data-testid="learner-dashboard-mock-tests-tab-link">
            <FormattedMessage id="my-dashboard-mock-tests-tab" defaultMessage="Mock Tests" />
          </NavLink>
        </Tabs.Item>
      )}
      {hasAchievementsTab && (
        <Tabs.Item isActive={tabIsActive(`${url}/achievements`)}>
          <NavLink to={`${url}/achievements?${searchParams.toString()}`} data-testid="learner-dashboard-achievements-tab-link">
            <FormattedMessage id="my-dashboard-achievements-tab" defaultMessage="Achievements" />
          </NavLink>
        </Tabs.Item>
      )}
      {hasAssignmentsTab && (
        <Tabs.Item isActive={tabIsActive(`${url}/assignments`)}>
          <NavLink to={`${url}/assignments?${searchParams.toString()}`} data-testid="learner-dashboard-assignments-tab-link">
            <FormattedMessage id="my-dashboard-assignments-tab" defaultMessage="Assignments" />
          </NavLink>
        </Tabs.Item>
      )}
    </Tabs>
  );
}
