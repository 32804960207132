import Internationalize from 'components/Internationalization/Internationalize';
import StreaksWidget from './century-core/features-learner/Streaks/StreaksWidget';
import * as React from 'react';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/auth.utils';
import { PolymerMatchingQuestionTypeReadonly } from './century-core/core-components/DraggableQuestionTypes/Matching/MatchingQuestionTypeReadonly';
import { PolymerLabellingQuestionTypeReadonly } from './century-core/core-components/DraggableQuestionTypes/Labelling/LabellingQuestionTypeReadonly';
import LeadershipStudentView from 'century-core/features-leadership/LeadershipStudentView';
import DocumentDownlodButton from 'century-core/core-components/DocumentDownloadButton/DocumentDownloadButton';
import FilteredDocsDownloader from 'century-core/core-components/DocumentDownloadButton/CombinedDocumentDownloadButton';
import { createApolloClientForPolymer } from 'createApolloClient';
import PolymerToReactAuthProvider from 'century-core/core-auth/hooks/PolymerToReactAuthProvider';
import { getMixpanelWithDefaultProps } from 'century-core/core-utils/utils/mixpanel/mixpanel';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { MixpanelProvider } from 'react-mixpanel';

export interface LocaleAndOrgType {
  locale: string;
  orgType: string;
}

const simpleContextWrapper = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => {
    const auth = parseAuthFromLocalstorage();
    if (!auth) {
      return null;
    }

    return (
      <PolymerToReactAuthProvider>
        <Internationalize auth={auth}>
          <Component {...props} />
        </Internationalize>
      </PolymerToReactAuthProvider>
    );
  };
};

const advancedContextWrapper =
  <P extends object>(Component: React.ComponentType<P>) => 
    ({ routeBaseName, ...restProps }: any) => {
    const initialAuth = parseAuthFromLocalstorage()!;
    const client = createApolloClientForPolymer();
    return (
      <PolymerToReactAuthProvider>
        <Internationalize auth={initialAuth}>
          <MixpanelProvider mixpanel={getMixpanelWithDefaultProps(initialAuth)}>
            <BrowserRouter basename={routeBaseName}>
              <ApolloProvider client={client}>
                <Component {...restProps} />
              </ApolloProvider>
            </BrowserRouter>
          </MixpanelProvider>
        </Internationalize>
      </PolymerToReactAuthProvider>
    );
}

const PolymerAvailableReactComponents = {
  StreaksWidget: simpleContextWrapper(StreaksWidget),
  MatchingQuestionTypeReadonly: simpleContextWrapper(PolymerMatchingQuestionTypeReadonly),
  LabellingQuestionTypeReadonly: simpleContextWrapper(PolymerLabellingQuestionTypeReadonly),
  StudentDashboard: advancedContextWrapper(LeadershipStudentView), // uses advance context wrapper for graphql, auth, etc
  DocumentDownloadButton: simpleContextWrapper(DocumentDownlodButton),
  NuggetDocxDownloadButton: simpleContextWrapper(FilteredDocsDownloader),
};

export default PolymerAvailableReactComponents;
