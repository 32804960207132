export interface DataPoints {
  duration: number;
  x: number;
  y: number;
  score: number;
  category: string;
  userId: string;
}
export function filterStudyGroupsByCourses(studygroups: Ctek.StudyGroup[], courseIds: string[]): Ctek.StudyGroup[] {
  return studygroups.filter(studygroup => courseIds.includes(studygroup.course as string));
}

export function getStudyGroupCoursePlanByCourseId(studygroups: Ctek.StudyGroup[], courseId: string): string | null {
  const studygroup = studygroups.find(studygroup => studygroup.course === courseId);
  return studygroup && studygroup.coursePlan ? studygroup.coursePlan : null;
}

export function getUniqueCourseIds(studygroups: Ctek.StudyGroup[]): string[] {
  return studygroups.map(studygroup => studygroup.course as string).filter((courseId, index, self) => self.indexOf(courseId) === index);
}

export function getCoursePlanIdsFromStudyGroups(studygroups: Ctek.StudyGroup[]): string[] {
  return studygroups.map(studygroup => studygroup.coursePlan as string);
}

export function getIdsFromDocs(docs: { _id?: string }[]): string[] {
  return docs.map((doc: Ctek.Course) => doc._id || '');
}

export function getCoursePlanIdByCourse(courseplans: Ctek.CoursePlan[], courseId: string): Ctek.CoursePlan | null {
  const matchingCourseplan = courseplans.find((courseplan: Ctek.CoursePlan) => courseplan.courseId === courseId);
  return matchingCourseplan ? matchingCourseplan : null;
}

export function getNuggetIds(courseId: string, courses: Ctek.Course[]): string[] {
  const course: Ctek.Course | undefined = courses.find((crs: Ctek.Course) => crs._id === courseId);
  if (typeof course === 'undefined') {
    return [];
  } else {
    return course
      .strands!.reduce((acc: Ctek.Nugget[], cur) => acc.concat(cur.nuggets as Ctek.Nugget[]), [])
      .map(nugget => nugget._id || '');
  }
}

export function getCourseImageData(courses: Ctek.Course[], courseId: string) {
  let currentCourseImage: string = '';
  const courseImages = courses
    .reduce((acc: Ctek.Media[], cur: Ctek.Course): Ctek.Media[] => {
      if (cur.icon) {
        if (cur._id === courseId) {
          currentCourseImage = (cur.icon as Ctek.Media).files[0].id || '';
        }
        acc.push(cur.icon as Ctek.Media);
        return acc;
      } else {
        return acc;
      }
    }, [])
    .map((image: Ctek.Media) => ({
      fileId: image.files[0].id,
      itemId: image._id,
    }));
  return {
    courseImages,
    currentCourseImage,
  };
}

export function getSubjects(assignments: Ctek.Assignment[]): (string | Ctek.Label | undefined)[] {
  return assignments.reduce(
    (accumulator: (string | Ctek.Label | undefined)[], current: Ctek.Assignment) => accumulator.concat(current.subject),
    []
  );
}
