import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { userSchema } from 'century-core/entities/User/User';
import { StoreState, ThunkExtraArg } from '../../reducers/reducers';
import { Actions as EntitiesActions, addEntities } from '../entities/entities';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';

export enum ActionTypes {
  AvatarsLoaded = 'UserProfile.AvatarsLoaded',
  Error = 'UserProfile.Error',
  Loaded = 'UserProfile.Loaded',
  Loading = 'UserProfile.Loading',
}

export type Actions = ReturnType<typeof loaded> | ReturnType<typeof loading> | ReturnType<typeof error>;

export const loaded = () => ({
  type: ActionTypes.Loaded as typeof ActionTypes.Loaded,
});

export const loading = () => ({
  type: ActionTypes.Loading as typeof ActionTypes.Loading,
});

export const error = (err: ErrorMessage<Errors>) => ({
  error: err,
  type: ActionTypes.Error as typeof ActionTypes.Error,
});

export function loadUserProfile(
  userId: string,
  accessToken: string,
  tryCache: boolean
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    dispatch(loading());
    if (tryCache && isUserCached(userId, getState().entities)) {
      dispatch(loaded());
      return Promise.resolve();
    } else {
      return api.auth
        .getUser(userId, accessToken)
        .then(user => {
          const normalisedData = normalize(user, userSchema);
          dispatch(addEntities(normalisedData.entities));
          dispatch(loaded());
        })
        .catch((err: ErrorMessage<Errors>) => {
          dispatch(error(err));
        });
    }
  };
}

export function isUserCached(userId: string, entities: Record<string, any>): boolean {
  return userId && entities.users && entities.users[userId];
}
