import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { CustomThemeProvider } from 'components/CustomTheme/CustomTheme';
import Internationalize from 'components/Internationalization/Internationalize';
import Login from 'components/Login/Login';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/auth.utils';
import { getSubDomain } from 'century-core/core-utils/utils/config/config';
import * as SentryLib from 'century-core/core-utils/lib/sentry';
import * as katex from 'katex';
import 'katex/dist/katex.min.css';
import { Component, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import * as microsoftTeams from '@microsoft/teams-js';
import AppVersionDetails from './century-core/user-preferences/AppVersionDetails/AppVersionDetails';
import { Spinner } from './century-core/core-components/Spinner/Spinner';
import { ConnectedProps } from './ContainerApp';
import { isAccessTokenValid } from 'century-core/core-auth/utils';
import { ApiProvider } from 'century-core/core-apis/ApiProvider';
import { AuthProvider } from 'century-core/core-auth/AuthContext';
import { UserProvider } from 'century-core/core-utils/hooks/useUser';
import { getRedirectRouteByRole } from 'century-core/core-auth/utils';
import Auth from 'century-core/entities/Auth/Auth';
import { addExternalCustomMenu } from 'century-core/core-utils/utils/externalMenu/addExternalCustomMenu';
import { GtmProvider } from 'century-core/core-utils/GtmContext';
import { LearnerProductContextProvider } from 'century-core/core-subscription/hooks/LearnerProductContext';
import { SubscriptionContextProvider } from 'century-core/core-subscription/hooks/SubscriptionContext';
import { LogrocketContextProvider } from 'century-core/core-subscription/hooks/LogrocketContext';
import { CenturyMixpanelProvider } from 'CenturyMixpanelProvider';
import { UserProfileProvider } from 'century-core/core-auth/components/UserProfile/UserProfileContext';

window.katex = katex;

declare global {
  interface Window {
    auth0: Auth0Client;
    katex: any;
  }
  const rendereSuiteLinksV2: any;
}

const B2CRegistration = lazy(() => import('./components/B2CRegistration/Registration'));
const ContainerAppTemplate = lazy(() => import('./components/AppTemplate/ContainerAppTemplate'));
const MSTeamsContent = lazy(() => import('./century-core/features-msteams/MSTeamsContent/MSTeamsContent'));
const MSTeamsUnknown = lazy(() => import('./century-core/features-msteams/MSTeamsUnknown/MSTeamsUnknown'));

let isMSTeamsRoute = false;
let isMicrosoftTeams = false;
function setMicrosoftTeamsContext() {
  isMSTeamsRoute = window.location.pathname.includes('/msteams-');
  microsoftTeams.initialize();
  microsoftTeams.appInitialization.notifySuccess();
  microsoftTeams.getContext(() => {
    isMicrosoftTeams = true;
  });
}

let checkedRoute = false;

export default class App extends Component<ConnectedProps> {
  constructor(props: ConnectedProps) {
    super(props);
    setMicrosoftTeamsContext();
    if (!isMSTeamsRoute) {
      this.props.loadLocalStorage();
    }
  }

  public render() {
    return (
      <AuthProvider auth={this.props.auth}>
        <UserProvider user={this.props.user}>
          <ApiProvider>
            <UserProfileProvider>
              <LearnerProductContextProvider>
                <SubscriptionContextProvider>
                  <LogrocketContextProvider>
                    <CustomThemeProvider auth={this.props.auth}>
                      <Internationalize auth={this.props.auth}>
                        <AppVersionDetails>
                          <CenturyMixpanelProvider>
                            <GtmProvider>
                              <Suspense fallback={Spinner}>
                                <Switch>
                                  {this.redirectLoggedUserOnCertainRoutes()}
                                  <Route path="/msteams-content">
                                    <MSTeamsContent />
                                  </Route>
                                  <Route path="/msteams-unknown">
                                    <MSTeamsUnknown />
                                  </Route>
                                  <Route path="/registration">
                                    <B2CRegistration />
                                  </Route>
                                  <Route path="/bond">
                                    <B2CRegistration branding="bond" />
                                  </Route>
                                  {this.isLoggedIn() ? (
                                    <Route>
                                      <ContainerAppTemplate />
                                    </Route>
                                  ) : (
                                    <Login />
                                  )}
                                </Switch>
                              </Suspense>
                            </GtmProvider>
                          </CenturyMixpanelProvider>
                        </AppVersionDetails>
                      </Internationalize>
                    </CustomThemeProvider>
                  </LogrocketContextProvider>
                </SubscriptionContextProvider>
              </LearnerProductContextProvider>
            </UserProfileProvider>
          </ApiProvider>
        </UserProvider>
      </AuthProvider>
    );
  }

  public componentDidMount() {
    this.props.getDomainSettings(getSubDomain());

    window.addEventListener('popstate', () => this.props.checkAccessTokenOnRouteChange());

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.props.checkAccessTokenOnRouteChange();
        this.props.getDomainSettings(getSubDomain());
      }
    });
  }

  public async componentDidUpdate(prevProps: ConnectedProps) {
    // We setLocale/phraseApp tool in componentDidUpdate as it depends on state.auth and this may not be loaded yet.
    const { auth, location } = this.props;
    if (auth.accessToken && !prevProps.auth.accessToken && auth.accessToken !== prevProps.auth.accessToken) {
      this.props.loadUserProfile();
      // Only runs if an org has xternal menu feature flag enabled
      addExternalCustomMenu(auth);
    }

    // Load subdomain if any
    const domainOrgSettings: Ctek.Accounts.DomainResponse | void = auth.orgSetting;
    if (domainOrgSettings) {
      try {
        if (
          !window.auth0 &&
          domainOrgSettings.settings.global?.authSettings?.sso &&
          domainOrgSettings.settings.global.authSettings.sso.auth0 &&
          domainOrgSettings.settings.global.authSettings.sso.auth0.isEnabled
        ) {
          // this prevents auth0 from delaying the client initialisation
          // we dont care if about this flag anyway since Auth0 is only
          // used to login a user through Sentinel
          document.cookie = 'auth0.is.authenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

          const { default: createAuth0Client } = await import('@auth0/auth0-spa-js');
          window.auth0 = await createAuth0Client({
            redirect_uri: `${window.location.origin}/login/`,
            client_id: domainOrgSettings.settings.global.authSettings.sso.auth0.clientId,
            domain: domainOrgSettings.settings.global.authSettings.sso.auth0.domain,
            cacheLocation: 'localstorage',
          });
          window.dispatchEvent(new CustomEvent('auth0-client-loaded'));
        }
      } catch (error) {
        SentryLib.captureException(error);
      }
    }
    // Microsoft MS Teams app context
    // This context is set to set the current url and allow users to reload and stay in the same location.
    isMSTeamsRoute = window.location.pathname.includes('/msteams-');
    if (isMicrosoftTeams && !isMSTeamsRoute) {
      const prevUrl = prevProps.location.pathname;
      if (prevUrl !== location.pathname && prevUrl !== '/') {
        const url = `${window.location.protocol}//${window.location.host}`;
        microsoftTeams.setFrameContext({
          contentUrl: `${url}${location.pathname}${location.search}`,
          websiteUrl: `${url}${location.pathname}${location.search}`,
        });
      }
    }
  }

  /**
   * Checks if you are logged in in any of the 'unauthorizedRoutes'
   *
   * We only need to check this once (hence the 'checkedRoute') when user lands there on the first time when loading
   * any of these urls (user can only type these routes, there are no internal links to it).
   *
   * After first load and check, if user's allow, we whouldn't check this in any re-render, as this could impact in
   * the logic, like in registering a user in '/registration' or '/bond'
   */
  private redirectLoggedUserOnCertainRoutes(): any {
    const authFromLocalStorage = parseAuthFromLocalstorage();
    if (!checkedRoute && authFromLocalStorage && this.isLoggedIn(authFromLocalStorage)) {
      const unauthorizedRoutes: string[] = ['/registration', '/bond', '/msteams-content', 'msteams-unknown'];

      return unauthorizedRoutes.map(
        route => window.location.pathname.includes(route) && window.location.assign(getRedirectRouteByRole(authFromLocalStorage))
      );
    }
    checkedRoute = true;
  }

  private isLoggedIn(auth?: Auth): boolean {
    if (!auth) {
      auth = parseAuthFromLocalstorage();
    }
    const isTokenValid = auth ? isAccessTokenValid(auth) : false;
    return !!auth && (isTokenValid || (!isTokenValid && !!auth.refreshToken));
  }
}
