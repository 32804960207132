import * as palpatineStudygroups from 'century-core/core-apis/palpatine/studygroups/studygroups';
import { getSubjects, getUniqueCourseIds } from 'century-core/core-apis/utils/utils';
import { fetchAssignments } from './helpers/assignments';
import { getCourseplans } from './helpers/courseplans';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils/create-fetch-request';

export async function fetchAssignmentData(selectedUserId: string, accessToken: string) {
  return createFetchRequestWithLocalisedError(async () => {
    const studygroups = await palpatineStudygroups.getStudyGroups(selectedUserId, accessToken);
    // get courses by id
    const courseIds = getUniqueCourseIds(studygroups);
    if (!courseIds.length) {
      return {
        assignments: [],
        courseplans: [],
        selectedSubject: 'All',
        subjects: [],
      };
    }
    const assignments = await fetchAssignments(courseIds, [], accessToken);
    const subjects = getSubjects(assignments) as Ctek.Label[];

    const courseplans = await getCourseplans(
      {
        courses: assignments,
        selectedUserId,
        restrictByCoursePlanStartTime: true,
        returnEmptyActivity: true,
        studygroups,
      },
      accessToken
    );

    return {
      assignments,
      courseplans,
      subjects,
    };
  }, 'localised-errors-assignments-error');
}
