import {
  Button,
  ClickArea,
  FlexGrid,
  FormProgress,
  IllustrationProcessComplete,
  SignInBox,
  SignInScreen,
  StepNavigationButton,
  StepNavigationButtonDirection,
  Tabs,
  TabsVariant,
} from '@ctek/design-system';
import { MIN_PASSWORD_LENGTH } from 'century-core/core-utils/utils/utils';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoginForm } from '../Login/LoginForm';
import * as ClassCodeElements from './components';
import { checkEmailAlreadyExists, preAuthenticateUser, processNewUserWithClassCode, validateCode } from 'api/classCode/classCode';
import { setEmailAddress } from 'state/actions/recoverPassword/recoverPassword';
import { login } from 'state/actions/auth/auth';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
export interface ClassRelatedInfo {
  classId: string;
  onboardingCode: string;
  orgId: string;
}
export interface NewUserFormValues {
  birthDate: string;
  email: string;
  firstName: string;
  gender: string;
  lastName: string;
  password: string;
}

// TODO TL-1440 we need to use react-intl for this, not local messages
const formErrors = {
  'class-code-signup-error-consent-required': 'Please consent to our privacy policy',
  'class-code-class-code-required': 'Please enter your class code',
  'class-code-signup-error-confirm-email': 'Please confirm your email',
  'class-code-signup-error-confirm-password': 'Please confirm your password',
  'class-code-signup-error-dob': 'Please fill in your date of birth',
  'class-code-signup-error-dob-invalid': 'Please fill in a valid date of birth',
  'class-code-signup-error-email': 'Please fill in your email',
  'class-code-signup-error-email-dont-match': "Emails don't match",
  'class-code-signup-error-email-invalid': 'Invalid email address',
  'class-code-signup-error-email-repeated': 'Repeated email address',
  'class-code-signup-error-first-name': 'Please fill in your first name',
  'class-code-signup-error-gender': 'Please select a gender',
  'class-code-signup-error-last-name': 'Please fill in your last name',
  'class-code-signup-error-password': 'Please choose a password',
  'class-code-signup-error-password-dont-match': "Passwords don't match",
  'error-password-too-short': `Passwords must be at least ${MIN_PASSWORD_LENGTH} characters long`,
};

export const ClassCodePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { track } = useMixpanel();
  const [stage, setStage] = useState<{
    step: 1 | 2 | 3;
    status:
      | 'class-code-form'
      | 'class-code-valid'
      | 'class-code-invalid'
      | 'class-code-valid-signin'
      | 'class-code-valid-signup'
      | 'class-code-valid-signup-success';
  }>({ status: 'class-code-form', step: 1 });
  const [onboarding, setOnboarding] = useState<ClassRelatedInfo | null>(null);
  const [classCodeError, setError] = useState<ErrorMessage<Error> | null>(null);

  const sendValidateCode = (code: string) =>
    validateCode(code)
      .then(res => {
        if (res.isValid) {
          // dispatch(renderClassCodeValid({ onboardingCode: classCode, classId: res.classId, orgId: res.orgId }));
          setStage({
            step: 2,
            status: 'class-code-valid',
          });
          setOnboarding({
            classId: res.classId,
            onboardingCode: code,
            orgId: res.orgId,
          });
        } else {
          setStage({ step: 1, status: 'class-code-invalid' });
        }
      })
      .catch(e => {
        setError(e);
      });

  const sendCheckEmailAlreadyExists = (email: string) =>
    checkEmailAlreadyExists(email)
      .then(res => {
        return res.isKnownUser;
      })
      .catch(err => {
        setError(err);
        return false;
      });

  const processNewUserForm = useCallback(
    (newUserFormValues: NewUserFormValues) => {
      const onboardingCode = onboarding?.onboardingCode;
      const newUser: Partial<Ctek.User & { password: string }> = {
        contact: {
          emails: [
            {
              address: newUserFormValues.email.trim(),
              isVerified: false,
            },
          ],
        },
        password: newUserFormValues.password.trim(),
        personal: {
          birthDate: new Date(newUserFormValues.birthDate.trim()),
          ethnicity: {
            sdeCode: 'NS',
          },
          gender: newUserFormValues.gender,
          name: {
            first: newUserFormValues.firstName.trim(),
            last: newUserFormValues.lastName.trim(),
          },
        },
      };

      return processNewUserWithClassCode(newUser, onboardingCode!)
        .then(res => {
          setStage(stage => ({ ...stage, status: 'class-code-valid-signup-success' }));
        })
        .catch(err => {
          setError(err);
        });
    },
    [onboarding]
  );

  const preAuthenticaUserAndSignIn = useCallback(
    (username: string, password: string) => {
      const payload = {
        classId: onboarding?.classId!,
        code: onboarding?.onboardingCode!,
        email: username,
        orgId: onboarding?.orgId!,
        password,
      };

      return preAuthenticateUser(payload)
        .then(() => {
          dispatch(login(username, password, track));
          history.push('/');
        })
        .catch(err => {
          setError(err);
        });
    },
    [onboarding, dispatch, history, track]
  );

  const GoBackToFirstStepButton = () => (
    <ClickArea qa="class-code-go-to-login-button" onClick={() => setStage({ step: 1, status: 'class-code-form' })}>
      <StepNavigationButton direction={StepNavigationButtonDirection.BACK}>
        <FormattedMessage id="go-back" defaultMessage="go back" />
      </StepNavigationButton>
    </ClickArea>
  );

  const classCodeValidTabbedSignIn = useMemo(() => {
    let tabId: 'sign-up' | 'sign-in' | undefined;
    if (stage.status === 'class-code-valid-signin') {
      tabId = 'sign-in';
    }
    if (stage.status === 'class-code-valid-signup') {
      tabId = 'sign-up';
    }
    if (!tabId) {
      return null;
    }
    return (
      <>
        <Tabs variant={TabsVariant.BOX}>
          <Tabs.Item isActive={tabId === 'sign-up'}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={() => setStage({ step: 2, status: 'class-code-valid-signup' })}>
              <FormattedMessage id="tab-label-sign-up" defaultMessage="Sign up" />
            </a>
          </Tabs.Item>
          <Tabs.Item isActive={tabId === 'sign-in'}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={() => setStage({ step: 3, status: 'class-code-valid-signin' })}>
              <FormattedMessage id="tab-label-sign-in" defaultMessage="Sign in" />
            </a>
          </Tabs.Item>
        </Tabs>
        <SignInBox.Form>
          {tabId === 'sign-in' && (
            <LoginForm
              layoutForm={'BasicSigninForm'}
              login={preAuthenticaUserAndSignIn}
              setEmailAddress={email => dispatch(setEmailAddress(email))}
            />
          )}
          {tabId === 'sign-up' && (
            <ClassCodeElements.ClassCodeValidSignupForm
              formErrors={formErrors}
              checkEmailAlreadyExists={sendCheckEmailAlreadyExists}
              processNewUserForm={processNewUserForm}
              renderClassCodeValidSignin={() => setStage({ step: 3, status: 'class-code-valid-signin' })}
              setEmailAddress={email => dispatch(setEmailAddress(email))}
            />
          )}
        </SignInBox.Form>
      </>
    );
  }, [stage, dispatch, preAuthenticaUserAndSignIn, processNewUserForm]);

  if (!!classCodeError) {
    return (
      <>
        <SignInBox>
          <SignInBox.Header>
            <h1>
              <FormattedMessage id="recover-password-fail-title" defaultMessage="We have a problem!" />
            </h1>
          </SignInBox.Header>
          <SignInBox.Body qa="recover-password-error">
            <FormattedMessage
              id="recover-password-fail-message"
              defaultMessage={'We were unable to process your request. Please try again and if the problem persists contact our support.'}
            />
          </SignInBox.Body>
          <SignInBox.Footer>
            <Button
              size="medium"
              onClick={() => {
                setError(null);
                history.push('/');
              }}
              qa="class-code-signup-success-message-submit"
              variant="primary"
            >
              <FormattedMessage id="login-sign-in-button-label" defaultMessage="Sign In" />
            </Button>
          </SignInBox.Footer>
        </SignInBox>
        <SignInScreen.Navigation>
          <FlexGrid isVerticallyCentred={true}>
            <FlexGrid.Col>
              <GoBackToFirstStepButton />
            </FlexGrid.Col>
          </FlexGrid>
        </SignInScreen.Navigation>
      </>
    );
  }

  let content;
  let goBack;
  let progress;

  progress = <FormProgress currentStep={stage.step} totalSteps={3} />;
  if (['class-code-valid-signin', 'class-code-valid-signup', 'class-code-valid'].includes(stage.status)) {
    goBack = <GoBackToFirstStepButton />;
  }
  if (['class-code-invalid', 'class-code-form'].includes(stage.status)) {
    goBack = (
      <ClickArea
        qa="class-code-go-to-login-button"
        onClick={() => {
          setError(null);
          history.push('/');
        }}
      >
        <StepNavigationButton direction={StepNavigationButtonDirection.BACK}>
          <FormattedMessage id="go-back" defaultMessage="go back" />
        </StepNavigationButton>
      </ClickArea>
    );
  }
  switch (stage.status) {
    case 'class-code-valid-signup-success':
      content = (
        <>
          <SignInBox.Header>
            <IllustrationProcessComplete />
            <h1>
              <FormattedMessage id="class-code-signup-success-title" defaultMessage="Well done!" />
            </h1>
          </SignInBox.Header>
          <SignInBox.Body qa="class-code-signup-success-message">
            <FormattedMessage id="class-code-signup-success-message" defaultMessage="Go ahead and sign in to explore CENTURY." />
          </SignInBox.Body>
          <SignInBox.Footer>
            <Button
              size="medium"
              onClick={() => {
                setError(null);
                history.push('/');
              }}
              qa="class-code-signup-success-message-submit"
              variant="primary"
            >
              <FormattedMessage id="login-sign-in-button-label" defaultMessage="Sign In" />
            </Button>
          </SignInBox.Footer>
        </>
      );
      progress = null;
      break;
    case 'class-code-valid-signin':
    case 'class-code-valid-signup':
      content = classCodeValidTabbedSignIn || <></>;
      break;
    case 'class-code-valid':
      content = (
        <ClassCodeElements.ClassCodeValid
          renderClassCodeValidSignin={() => setStage({ step: 3, status: 'class-code-valid-signin' })}
          renderClassCodeValidSignup={() => setStage({ step: 3, status: 'class-code-valid-signup' })}
        />
      );
      break;
    case 'class-code-invalid':
      content = <ClassCodeElements.ClassCodeInvalid validateCode={sendValidateCode} />;
      break;
    case 'class-code-form':
    default:
      content = <ClassCodeElements.ClassCodeForm validateCode={sendValidateCode} />;
      break;
  }

  return (
    <>
      <SignInBox>{content}</SignInBox>
      <SignInScreen.Navigation>
        <FlexGrid isVerticallyCentred={true}>
          <FlexGrid.Col>{goBack}</FlexGrid.Col>
          <FlexGrid.Col xs="auto">{progress}</FlexGrid.Col>
        </FlexGrid>
      </SignInScreen.Navigation>
    </>
  );
};
