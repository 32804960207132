import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/auth.utils';
import { isEmptyQuillFeedback } from 'century-core/core-utils/utils/quill/utils/isEmptyQuillFeedback';
import { pathOr } from 'ramda';
import { PossibleAnswer, Results } from '../../typings/DraggableQuestionTypes';
import { ResultTypes } from './components/LabelPair/LabelPair';

export const getPossibleAnswerById = (id: string, possibleAnswers: PossibleAnswer[]): PossibleAnswer | undefined => {
  return possibleAnswers.find(possibleAnswer => possibleAnswer.id === id);
};

export const getAnswerGroupById = (id: string, answerGroups: Ctek.AnswerGroup[]): Ctek.AnswerGroup | undefined => {
  return answerGroups.find(answerGroup => answerGroup.id === id);
};

// TODO - remove when Polymer QLA is removed. Use Auth from redux instead.
export const getPolymerReactToken = () => {
  const auth = parseAuthFromLocalstorage();
  if (auth) {
    return auth.accessToken || '';
  }
  return '';
};

export function getDraggableQuestionFeedback(
  question: Ctek.EnrichedQuestion,
  questionGroup: Ctek.EnrichedQuestionGroup,
  isCorrect: boolean
): null | { ops: [] } {
  const questionFeedback = pathOr(null, [isCorrect ? 'defaultCorrectResponse' : 'defaultIncorrectResponse', 0, 'data'], question);

  if (questionFeedback && !isEmptyQuillFeedback(questionFeedback)) {
    return questionFeedback;
  }

  const questionGroupFeedback = pathOr(null, [isCorrect ? 'defaultCorrectResponse' : 'defaultIncorrectResponse', 0, 'data'], questionGroup);

  if (isEmptyQuillFeedback(questionGroupFeedback)) {
    return null;
  }

  return questionGroupFeedback;
}

export const getAnswerStatusForQuestion = (hasAnswer: boolean, result?: Results, withholdResults?: boolean) =>
  !!withholdResults ? null : result?.isCorrect ? ResultTypes.CORRECT : hasAnswer ? ResultTypes.INCORRECT : ResultTypes.SKIPPED;
