import { DateTime, Interval } from 'luxon';

/** This includes the short weekday, day of the month, and abbreviated month */
export const DATE_SHORT_WITH_WEEKDAY = 'EEE, dd LLL'; // Sun, 20 Oct

export const getDayCountFromToday = (isoDate: string) => {
  return parseInt(DateTime.fromISO(isoDate).diff(DateTime.local()).toFormat('d'), 10);
};

export const getPastDayCountFromToday = (isoDate: string) => {
  return parseInt(DateTime.local().diff(DateTime.fromISO(isoDate)).toFormat('d'), 10);
};

export const formatddMMM = (dt: DateTime | string, iso = false) => {
  return iso && typeof dt === 'string'
    ? DateTime.fromISO(dt, { zone: 'utc' }).toFormat('dd MMM')
    : (dt as DateTime).isValid
    ? (dt as DateTime).toFormat('dd MMM')
    : 'Invalid date';
};

export interface GetDateTimeFromObjectProp {
  year?: number;
  month?: number;
  day?: number;
  weekYear?: number;
  weekNumber?: number;
  startOf?: 'week' | 'month' | 'year';
  endOf?: 'week' | 'month' | 'year';
  toUTC?: boolean;
  toISO?: boolean;
  toISODate?: boolean;
}

export const getDateTimeFromObject = (props: GetDateTimeFromObjectProp): DateTime => {
  const { year, month, day, weekYear, weekNumber, startOf, endOf, toUTC, toISO, toISODate } = props;

  if ((!!year || !!month || !!day) && (!!weekYear || !!weekNumber)) {
    throw new Error("You can't pass both year/month and weekYear/weekNumber to the date creation from object");
  }

  if (!!startOf && !!endOf) {
    throw new Error("You can't pass both startOf and endOf to the date creation from object");
  }

  if (!!toISO && !!toISODate) {
    throw new Error("You can't pass both toISO and toISODate to the date creation from object");
  }

  let objectToCreate = {};
  if (!!weekYear) {
    objectToCreate = { ...objectToCreate, weekYear };
  }
  if (!!weekNumber) {
    objectToCreate = { ...objectToCreate, weekNumber };
  }
  if (!!year) {
    objectToCreate = { ...objectToCreate, year };
  }
  if (!!month) {
    objectToCreate = { ...objectToCreate, month };
  }
  if (!!day) {
    objectToCreate = { ...objectToCreate, day };
  }

  let date = DateTime.fromObject(objectToCreate);

  if (!!toUTC) {
    date = date.toUTC();
  }

  if (!!startOf) {
    date = date.startOf(startOf);
  }
  if (!!endOf) {
    date = date.endOf(endOf).endOf('day');
  }

  return date;
};

export const getUtcIsoDateTimeFromObject = (props: GetDateTimeFromObjectProp): string => {
  let datetime = getDateTimeFromObject(props);

  // kinda HACKY solution using 'toISODate()' with 'startOf' and 'endOf' to fix timezone issues
  datetime = DateTime.fromISO(datetime.toISODate(), { zone: 'utc' });
  if (!!props.endOf) {
    datetime = datetime.endOf('day');
  }
  return datetime.toISO();
};

export const getUtcDateTimeFromIso = (isoDate: string): DateTime => {
  return DateTime.fromISO(isoDate, { zone: 'utc' });
};

/**
 * Formats a date string to a string in the format "ccc, dd LLL, h:mm".
 *
 * @example
 * formatDate('2024-03-01T09:00:00Z'); // Returns "Fri, 01 Mar, 9:00"
 */
export const formatDateAndTime = (date?: string): string => (date ? DateTime.fromISO(date).toFormat('ccc, dd LLL, HH:mm') : '');

export const extractDateAndTime = (timestamp?: string) => {
  if (!timestamp || !isValidDate(timestamp)) return { date: '', time: '' };

  const dateTime = DateTime.fromISO(timestamp);
  const date = dateTime.toFormat('yyyy-MM-dd');
  const time = dateTime.toFormat('HH:mm');

  return { date, time };
};

const isValidDate = (date: string) => {
  return !isNaN(Date.parse(date));
};

export const isFutureDate = (date?: string) => {
  if (!date || !isValidDate(date)) {
    return false;
  }

  return DateTime.fromISO(date).diffNow().toMillis() > 0;
};

export const mergeAndFormatDateTime = (date?: string, time?: string) => {
  if (!date || !time) return '';
  const dateTime = DateTime.fromISO(`${date}T${time}`);
  return dateTime.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
};

/**
 * Checks if 'now' is included in the interval between start and end.
 * @param start - string ISO date
 * @param end - string ISO date
 */
export const checkTodayIsBetweenDates = (start: string, end: string) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);

  const now = DateTime.local();
  const interval = Interval.fromDateTimes(startDate.startOf('day'), endDate.endOf('day'));

  return interval.contains(now);
};

export const formatDate = (date: Date, format: string) => DateTime.fromISO(date.toISOString()).toFormat(format);

export const isDateInLast14Days = (date: string | undefined): boolean => {
  if (!date) return false;
  const dayCount = getDayCountFromToday(date);
  return dayCount >= -14 && dayCount <= 0;
};
