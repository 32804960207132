import { useState, useEffect, useMemo, ChangeEvent } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { ActiveStateFilter } from 'century-core/entities/Assignment/Assignment';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';

import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { useMixpanel, usePageViewEvent } from 'century-core/core-components/MixpanelUtils';

import { FilterToolbar, BoxSimple, AccessIllustration, Heading, Text } from '@ctek/design-system';
import { withErrorBoundaryPage } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { Spinner } from '../../../core-components/Spinner/Spinner';
import { AssignmentTable } from './AssignmentTable';
import { fetchAssignmentData } from '../api/fetchAssignments';
import * as SortSelectors from 'century-core/core-utils/selectors/sortSelectors/sortSelectors';
import {
  getAssignmentTableDataAndFilterBarStatus,
  getFirstCreatorFromAssignment,
  AssignmentTableData,
} from 'century-core/core-utils/selectors/assignmentsv1';
import { getMultipleUsersById } from 'century-core/core-apis/accountsV2/users/users';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';
import StudentDashboardAssignmentsOptions from './StudentDashboardAssignmentsOptions';
import StudentDashboardAssignmentsFilters from './StudentDashboardAssignmentsFilters';

export enum Option {
  BARS = 'Show Bars',
  NUMBERS = 'Show Numbers',
  SYMBOLS = 'Show Symbols',
  CELLS = 'Coloured Cells',
}

enum FilterType {
  SUBJECT = 'subject',
  TEACHER = 'teacher',
  SHOW = 'show',
}

export enum ShowFilter {
  ALL = 'ALL',
  ACTIVE = 'active',
  PAST = 'past',
}

// phrase app key prefix
export const prefix = 'dependant-assignments-';

function getSubjectOptions(subjects: Ctek.Label[]): SelectOption[] {
  if (!subjects || !subjects.length) {
    return [];
  }
  let data: SelectOption[] = prepareOptions(subjects) || [];
  data = SortSelectors.sortStringData(data, 'title');
  data.unshift({
    title: 'All',
    value: 'ALL',
  });
  return data;
}

function prepareOptions(subjects: Ctek.Label[]) {
  return subjects
    .map((subject: Ctek.Label) => ({
      title: subject.name,
      value: subject._id as string,
    }))
    .filter(
      (obj: SelectOption, index: number, self: SelectOption[]) =>
        // ensures there are no duplicates
        self.findIndex((el: SelectOption) => el.value === obj.value) === index
    );
}

function prepareTeacherList(teachers: Ctek.User[], intl: IntlShape) {
  const validTeachers = teachers.filter(t => t.personal?.name?.first && t.personal.name.last);
  const teacherOptions = validTeachers
    .map((teacher: Ctek.User) => ({
      title: `${teacher.personal?.name?.first.charAt(0).toUpperCase()} ${teacher.personal?.name?.last}`,
      value: teacher._id as string,
    }))
    .filter(
      (obj: SelectOption, index: number, self: SelectOption[]) =>
        // ensures there are no duplicates
        self.findIndex((el: SelectOption) => el.value === obj.value) === index
    );

  teacherOptions.unshift({ title: intl.formatMessage({ id: 'all', defaultMessage: 'All' }), value: 'ALL' });
  return teacherOptions;
}

interface AssignmentData {
  assignments: Ctek.Assignment[];
  courseplans: Ctek.CoursePlan[];
  selectedSubject?: string;
  subjects: Ctek.Label[];
}

interface Props {
  studentId: string;
  userMode: DashboardUserMode;
}

const StudentDashboardAssignmentsV1 = (props: Props) => {
  const { studentId, userMode } = props;
  const { track } = useMixpanel();

  const accessToken = useAccessToken();
  const intl = useIntl();

  // Page states
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AssignmentData | null>(null);

  // Filters
  const [subjectId, setSubjectId] = useState<string>('ALL');
  const [teacherId, setTeacherId] = useState<string>('ALL');
  const [subjects, setSubjects] = useState<SelectOption[]>([]);
  const [teacherList, setTeacherList] = useState<SelectOption[]>([]);
  const [activeStateFilter, setActiveStateFilter] = useState('ALL');
  // Options Menu
  const [showBars, setShowBars] = useState<boolean>(true);
  const [showNumbers, setShowNumbers] = useState<boolean>(true);
  const [showSymbols, setShowSymbols] = useState<boolean>(true);
  const [colouredCells, setColouredCells] = useState<boolean>(false);

  const isTeach = userMode === DashboardUserMode.TEACHER;

  usePageViewEvent(
    userMode === DashboardUserMode.GUARDIAN
      ?  'guardian-dependant-assignments'
      : userMode === DashboardUserMode.STUDENT
        ? 'student-dashboard-assignments'
        : userMode === DashboardUserMode.TEACHER
          ? 'teacher-student-dashboard-assignments'
          : ''
  )

  useEffect(() => {
    setLoading(true);

    fetchAssignmentData(studentId, accessToken).then(async res => {
      if (isTeach) {
        const uniqueTeacherIds = [...new Set(res.assignments.map(a => getFirstCreatorFromAssignment(a)))];
        const teacherList = await getMultipleUsersById(uniqueTeacherIds, accessToken);
        setTeacherList(prepareTeacherList(teacherList, intl));
      }
      setData(res);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSubject = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    if (value) {
      setSubjectId(value);
      const selectedSubject = subjects.find((s: SelectOption) => s.value === value);
      const selectedSubjectValue = selectedSubject ? selectedSubject.title : 'All';
      sendFilterChangeMixpanel(FilterType.SUBJECT, selectedSubjectValue);
    }
  };

  const changeTeacher = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setTeacherId(value);
    const selectedTeacher = teacherList.find((t: SelectOption) => t.value === value);
    if (selectedTeacher) return sendFilterChangeMixpanel(FilterType.TEACHER, selectedTeacher.title || value);
  };

  const changeShowFilter = (type: ShowFilter) => {
    setActiveStateFilter(type as ShowFilter);
    sendFilterChangeMixpanel(FilterType.SHOW, type);
  };

  const sendFilterChangeMixpanel = (type: FilterType, value: string) => {
    track(MixpanelEventTypes.StudentDashboardAssignmentsFilterChanged, {
      [MixpanelKeys.StudentId]: studentId,
      [MixpanelKeys.FilterType]: type,
      [MixpanelKeys.SelectedValue]: value,
    });
  };
  // turn on numbers if no other option selected
  useEffect(() => {
    if (!colouredCells && !showNumbers && !showSymbols && !showBars) {
      setShowNumbers(true);
    }
  }, [colouredCells, showSymbols, showBars, showNumbers]);
  const sendOptionChangedMixpanel = (option: Option, isChecked: boolean) => {
    track(MixpanelEventTypes.StudentDashboardAssignmentsOptionChanged, {
      [MixpanelKeys.StudentId]: studentId,
      [MixpanelKeys.OptionChanged]: option,
      [MixpanelKeys.Value]: isChecked,
    });
  };

  const onOptionChange = (option: Option, isChecked: boolean) => {
    sendOptionChangedMixpanel(option, isChecked);
    switch (option) {
      case Option.BARS:
        setShowBars(isChecked);
        break;
      case Option.CELLS:
        setColouredCells(isChecked);
        if (isChecked && showSymbols) {
          setShowSymbols(false);
        }
        break;
      case Option.NUMBERS:
        setShowNumbers(isChecked);
        break;
      case Option.SYMBOLS:
        setShowSymbols(isChecked);
        if (isChecked && colouredCells) {
          setColouredCells(false);
        }
        break;
      default:
        break;
    }
  };

  const assignments = useMemo(() => {
    if (!data?.assignments || !data?.courseplans) return [] as AssignmentTableData[];
    if (data?.subjects) {
      setSubjects(getSubjectOptions(data.subjects as Ctek.Label[]));
    }

    return (
      getAssignmentTableDataAndFilterBarStatus(
        data?.assignments,
        data?.courseplans,
        activeStateFilter as ActiveStateFilter,
        subjectId,
        teacherList,
        teacherId
      )?.assignments || ([] as AssignmentTableData[])
    );
  }, [data, activeStateFilter, subjectId, teacherList, teacherId]);

  return (
    <>
      <FilterToolbar>
        <StudentDashboardAssignmentsFilters
          subjectId={subjectId}
          subjects={subjects}
          onSubjectChange={changeSubject}
          isTeach={isTeach}
          teacherId={teacherId}
          teacherList={teacherList}
          onChangeTeacher={changeTeacher}
          selectedType={activeStateFilter}
          onTypeChange={changeShowFilter}
        />
        <StudentDashboardAssignmentsOptions
          showBars={showBars}
          showNumbers={showNumbers}
          showSymbols={showSymbols}
          colouredCells={colouredCells}
          onOptionChange={onOptionChange}
        />
      </FilterToolbar>
      {loading ? (
        <Spinner type="widget" />
      ) : data?.assignments && data.assignments.length ? (
        <AssignmentTable
          assignments={assignments}
          showBars={showBars}
          showNumbers={showNumbers}
          showSymbols={showSymbols}
          colouredCells={colouredCells}
          teacherList={teacherList}
          isTeach={isTeach}
          studentId={studentId}
        />
      ) : (
        <BoxSimple>
          <AccessIllustration illustration="no-courses" />
          <Heading.SectionTitle>
            {intl.formatMessage({ id: 'assignments-tab-no-assignments-title', defaultMessage: 'No Assignments' })}
          </Heading.SectionTitle>
          <Text.Message>
            {userMode === DashboardUserMode.STUDENT
              ? intl.formatMessage({
                  id: 'assignments-results-no-assignments-body',
                  defaultMessage: "Once you've been assigned courses, you'll be able to see your progress here.",
                })
              : intl.formatMessage({
                  id: 'guardian-assignments-results-no-assignments-body',
                  defaultMessage: "Once you have assigned courses to the learner, you'll be able to see their progress here.",
                })}
          </Text.Message>
        </BoxSimple>
      )}
    </>
  );
};

export default withErrorBoundaryPage(StudentDashboardAssignmentsV1);
