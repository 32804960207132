import { useMemo } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router';
import { getAllMainMenuRoutes, MainMenuRoute } from 'century-core/core-utils/lib/mainMenu/mainMenu';
import Auth from 'century-core/entities/Auth/Auth';
import { useIntl } from 'react-intl';

const getSubsectionTitle = (auth: Auth, str: string) => {
  const subsection = getAllMainMenuRoutes(auth).find((route: MainMenuRoute) => str && str.length > 1 && str.startsWith(route.path));
  if (!subsection?.key || !subsection?.name) {
    return null;
  }
  return {
    id: `main-menu-${subsection.key}`,
    defaultMessage: subsection?.name,
  };
};

interface Props {
  auth: Auth;
  title: string;
}

// This is a generalised title handler to give each subsection of the app a title to ease the navigation process
// As it's located near the top of the application individual sections may override it
// If in the future we want to give titles to individual pages which also include their section name from here then we may need to change this component to have a context state
export const LocationBasedTitle = (props: Props) => {
  const location = useLocation();
  const intl = useIntl();
  const locationTitle = useMemo(() => {
    const subsectionData = getSubsectionTitle(props.auth, location.pathname);
    if (subsectionData) {
      return intl.formatMessage(subsectionData);
    }
    return props.title;
  }, [intl, props.auth, props.title, location]);

  return <Helmet title={locationTitle || props.title} />;
};

LocationBasedTitle.defaultProps = {
  title: 'CENTURY',
};

export default LocationBasedTitle;
