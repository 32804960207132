import Spinner from 'century-core/core-components/Spinner/Spinner';
import { defaultLocale, defaultOrgType } from 'century-core/entities/Intl/defaultIntlValues';
import Auth from 'century-core/entities/Auth/Auth';
import { TranslationDocument } from 'entities/TranslationDocument/TranslationDocument';
import { getTokenSettingsLocale } from 'century-core/core-auth/utils';
import { pathOr } from 'ramda';
import { ReactNode, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { CustomFormats, IntlProvider } from 'react-intl';
import PolymerLoadedContext from '../../century-core/polymer/PolymerLoadedContext';
import { showContent, updateLocaleSettings } from './intlUtils';

const defaultMessage = {};

const Internationalize = (props: { auth: Auth; children: ReactNode | ReactNode[] }) => {
  const [currentLocale, setCurrentLocale] = useState(defaultLocale);
  const [currentOrgType, setOrgCurrentOrgType] = useState(defaultOrgType);
  const [messages, setMessages] = useState(null as TranslationDocument | null);
  const { setTranslationsSet, translationsSet } = useContext(PolymerLoadedContext);

  const updateMessagesIfRequired = useCallback(
    (newMessages: TranslationDocument) => {
      const newEntries = Object.entries(newMessages);
      if (newEntries.some(([messageId, messageValue]) => !messages || messages[messageId] !== messageValue)) {
        setMessages(newMessages);
      }
    },
    [messages]
  );

  useEffect(() => {
    const domainOrgSettings = props.auth.orgSetting;
    let locale;
    const orgType = pathOr(defaultOrgType, ['settings', 'organisationType'], domainOrgSettings);
    if (orgType && currentOrgType !== orgType) {
      setOrgCurrentOrgType(orgType);
    }

    locale = pathOr(defaultLocale, ['settings', 'defaultLocale'], domainOrgSettings);

    if (props.auth.accessToken) {
      locale = getTokenSettingsLocale(props.auth);
    }

    if (locale !== currentLocale) {
      setCurrentLocale(locale);
    }
  }, [props.auth.orgSetting, props.auth.accessToken, props.auth, currentLocale, currentOrgType]);

  useEffect(() => {
    updateLocaleSettings(props.auth, updateMessagesIfRequired).then(() => {
      if (setTranslationsSet && !translationsSet) {
        setTranslationsSet(true);
      }
    });
  }, [currentLocale, currentOrgType, props.auth, setTranslationsSet, translationsSet, updateMessagesIfRequired]);

  const formats = useMemo(
    (): CustomFormats => ({
      number: {
        GBP: {
          currency: 'GBP',
          style: 'currency',
        },
      },
    }),
    []
  );

  return (
    <IntlProvider formats={formats} locale={currentLocale} messages={messages || defaultMessage} defaultLocale="en-GB">
      <Spinner type={'fullpage'} showContent={showContent(props.auth, messages || defaultMessage)}>
        {props.children}
      </Spinner>
    </IntlProvider>
  );
};

export default Internationalize;
