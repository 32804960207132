import { useCallback, useEffect, useState } from 'react';

import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { useAccessTokenRef } from 'century-core/core-auth/hooks/useAuth';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { getUser } from 'century-core/core-apis/accountsV2/users/users';
import { getStudyGroups } from 'century-core/core-apis/palpatine/studygroups/studygroups';
import { getUniqueCourseIds } from 'century-core/core-apis/utils';
import { getCourseplans } from 'century-core/core-apis/learn/courseplans/courseplans';
import { DashboardUserMode, getLocationUrlParam } from 'century-core/core-utils/utils/utils';
import { CourseBehaviour, courseSortingLabels, courseHidingLabels, TEST_PRACTICE_LABEL_ID } from 'century-core/core-utils/utils/behaviours';
import { getCourses } from '../../api/helpers/courses';
import { getMixpanelEventFromViewMode } from '../utils';
import { getBatchedRequest } from 'century-core/core-apis/utils/getBatchedRequest';
import { updateQueryString } from 'century-core/core-utils/utils/update-query-string';

type CoreCoursesData = {
  courses: Ctek.Course[] | null;
  studyGroups: Ctek.StudyGroup[] | null;
  coursePlans: Ctek.CoursePlan[] | null;
  user: Ctek.User | null;
};

const hideCoursesByLabel = (courses: Ctek.Course[], courseBehaviour: CourseBehaviour): Ctek.Course[] => {
  const sortLabel = courseHidingLabels[courseBehaviour];
  if (!sortLabel || !courses.length) {
    return courses;
  }

  return courses.filter(course => !course.labels?.find((label: Ctek.Label) => label._id === sortLabel));
};

const sortCoursesByLabel = (courses: Ctek.Course[], courseBehaviour: CourseBehaviour): Ctek.Course[] => {
  const sortLabel = courseSortingLabels[courseBehaviour];
  if (!sortLabel || !courses.length) {
    return courses;
  }

  const labelCourses: Ctek.Course[] = [];
  const nonLabelCourses: Ctek.Course[] = [];
  courses.forEach(course => {
    if (course.labels?.find(courseLabel => ((courseLabel as Ctek.Label)._id || courseLabel) === sortLabel)) {
      labelCourses.push(course);
    } else {
      nonLabelCourses.push(course);
    }
  });

  return [...labelCourses, ...nonLabelCourses];
};

// main data which remains fixed for page life
const useCoreCourseData = (studentId: string, courseBehaviour: CourseBehaviour) => {
  const [coreData, setCoreData] = useState<CoreCoursesData>({ courses: null, studyGroups: null, coursePlans: null, user: null });
  const [isLoadingCoreData, setIsLoadingCoreData] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const accessTokenRef = useAccessTokenRef();

  const loadCoreData = useCallback(async () => {
    try {
      const user = await getUser(studentId, accessTokenRef.current);
      const studygroups = await getStudyGroups(studentId, accessTokenRef.current);
      const uniqueCourseIds = getUniqueCourseIds(studygroups);

      const formattedCoursesRequest = ({ entries, accessToken }: { entries: string[]; accessToken: string }) =>
        getCourses(entries, accessToken);
      let courses = await getBatchedRequest(formattedCoursesRequest, uniqueCourseIds, 20, { accessToken: accessTokenRef.current });

      courses = courses.filter(c => {
        // For 'subject' return all courses, aka 'TestPracticeFilter.ALL'
        if (courseBehaviour === CourseBehaviour.SUBJECT) {
          return true;
        }

        const labelIds = typeof c.labels?.[0] === 'string' ? c.labels : (c.labels || [])?.map(l => (l as Ctek.Label)._id);
        const isTestPractice = labelIds?.includes(TEST_PRACTICE_LABEL_ID);
        return courseBehaviour === CourseBehaviour.TEST_PRACTICE ? isTestPractice : !isTestPractice;
      });

      courses = hideCoursesByLabel(courses, courseBehaviour);
      courses = sortCoursesByLabel(courses, courseBehaviour);

      const courseIds = courses.map(c => c._id || '');
      const filteredStudygroups = studygroups.filter(studygroup => courseIds.includes(studygroup.course as string));
      const formattedCoursePlansRequest = ({ entries, userId, accessToken }: { entries: string[]; userId: string; accessToken: string }) =>
        getCourseplans({ userId, courseplanIds: entries }, accessToken);
      const filteredCoursePlans = filteredStudygroups.map(studygroup => studygroup.coursePlan as string).filter(v => !!v);
      const coursePlans = await getBatchedRequest(formattedCoursePlansRequest, filteredCoursePlans, 50, {
        userId: studentId,
        accessToken: accessTokenRef.current,
      });

      setCoreData({
        user,
        courses,
        coursePlans,
        studyGroups: filteredStudygroups,
      });
    } catch (e) {
      setError(e);
    } finally {
      setIsLoadingCoreData(false);
    }
  }, [studentId, accessTokenRef, courseBehaviour]);

  return {
    loadCoreData,
    isLoadingCoreData,
    hasCoreDataError: error,
    coreData,
  };
};

export const useCourseData = (studentId: string, viewMode: DashboardUserMode, courseBehaviour: CourseBehaviour) => {
  const { coreData, isLoadingCoreData, loadCoreData, hasCoreDataError: error } = useCoreCourseData(studentId, courseBehaviour);
  const [selectedCurrentCourse, setSelectedCurrentCourse] = useState<Ctek.Course | null>(null);
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const { track } = useMixpanel();

  useEffect(() => {
    if (selectedCurrentCourse?._id) {
      updateQueryString({ courseId: selectedCurrentCourse?._id || null });
      setSelectedCourseId(selectedCurrentCourse?._id);
    }
  }, [selectedCurrentCourse]);

  useEffect(() => {
    const selectedCourseId = selectedCurrentCourse?._id || getLocationUrlParam('courseId');
    const course = coreData.courses?.find(c => c._id === selectedCourseId);
    if (course && course._id !== selectedCurrentCourse?._id) {
      setSelectedCurrentCourse(course);
    } else if (!course && coreData.courses?.length) {
      setSelectedCurrentCourse(coreData.courses[0]);
    } else if (!course && !!selectedCurrentCourse) {
      setSelectedCurrentCourse(null);
    }
  }, [coreData.courses, selectedCurrentCourse]);

  useEffect(() => {
    if (!!coreData.courses) {
      updateQueryString({ courseId: selectedCurrentCourse?._id || null });
    }
  }, [selectedCurrentCourse, coreData]);

  const changeCourseSelection = useCallback(
    (newCourseId: string) => {
      const newCourse = coreData.courses?.find(c => c._id === newCourseId);
      if (newCourse && selectedCurrentCourse?._id !== newCourse._id) {
        const trackingEvent = getMixpanelEventFromViewMode(courseBehaviour, viewMode, {
          [CourseBehaviour.TEST_PRACTICE]: {
            [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardTestPracticeCourseSelection,
            [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardTestPracticeCourseSelection,
          },
          [CourseBehaviour.SUBJECT]: {
            [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardSubjectsCourseSelection,
            [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardSubjectsCourseSelection,
          },
        });
        if (trackingEvent) {
          track(trackingEvent, {
            [MixpanelKeys.CourseName]: newCourse.name,
            [MixpanelKeys.CourseId]: newCourse._id,
            [MixpanelKeys.CourseType]: newCourse.type,
            [MixpanelKeys.CourseSubject]: (newCourse.subject as Ctek.Subject)?.name,
            [MixpanelKeys.CourseLevel]: (newCourse.level as Ctek.Level)?.name,
          });
        }
        setSelectedCurrentCourse(newCourse);
      }
    },
    [coreData.courses, selectedCurrentCourse?._id, courseBehaviour, viewMode, track]
  );

  useEffect(() => {
    if (!coreData.user && !isLoadingCoreData) {
      loadCoreData();
    }
  }, [coreData, isLoadingCoreData, loadCoreData]);

  return {
    coreData,
    isLoadingCoreData,
    loadCoreData,
    error,
    selectedCurrentCourse,
    selectedCourseId,
    changeCourseSelection,
  };
};
