import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ColorEmphasis,
  ColorEmphasisVariant,
  Widget,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableResponsive,
  TableSimple,
  TableHeaderSortable,
  TableHeaderSortableSortDirection,
  ScorePercentage,
  PageContentResponsive,
  CircularStatusIndicator,
  Tooltipped,
} from '@ctek/design-system';
import {
  getSortingOrder,
  updateSortSettings,
  SortingType,
  SortSettings,
  sortTableData,
} from 'century-core/core-utils/utils/tableSorting/tableSortingV2';
import { AssignmentTableData, getFirstCreatorFromAssignment } from 'century-core/core-utils/selectors/assignmentsv1';
import { getTableDate } from 'century-core/core-utils/utils/helpers';
import { DurationMessage } from '../../../core-components/DurationMessage/DurationMessage';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';
import { useParams } from 'react-router-dom';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { SubjectName } from 'century-core/core-components/Subject/SubjectLabel';

type TableLabels =
  | 'name'
  | 'subject.name'
  | 'type'
  | 'planStartTime'
  | 'planEndTime'
  | 'isCompleted'
  | 'lastActivityTime'
  | 'percentScore'
  | 'durationActual';

interface AssignmentTableProps {
  assignments: AssignmentTableData[];
  teacherList: SelectOption[];
  isTeach: boolean;
  showBars: boolean;
  showNumbers: boolean;
  showSymbols: boolean;
  colouredCells: boolean;
  studentId: string;
}

export const AssignmentTable = (tableProps: AssignmentTableProps) => {
  const [assignments, setAssignments] = useState([] as AssignmentTableData[]);
  const [sortSettings, setSortSettings] = useState({ name: { order: 'ASCENDING', type: 'string' } } as SortSettings);
  const intl = useIntl();
  const mixpanel = useMixpanel();
  const routeParams = useParams();

  const headers = [
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-name" defaultMessage="Assignment Name" />,
      propName: 'name',
      propType: 'string',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-subject" defaultMessage="Subject" />,
      propName: 'subject.name',
      propType: 'string',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-content" defaultMessage="Content" />,
      propName: 'type',
      propType: 'string',
    },
    // only show if teach view mode
    ...(tableProps.isTeach
      ? [
          {
            formattedMessage: <FormattedMessage id="assignments-tab-column-title-teacher" defaultMessage="Assigned By" />,
            propName: 'teacher.title',
            propType: 'string',
            customGetter: (a: AssignmentTableData) => `${a.teacher.title.slice(2)}, ${a.teacher.title.slice(0, 1)}`,
          },
        ]
      : []),
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-start" defaultMessage="Start" />,
      propName: 'planStartTime',
      propType: 'date',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-due" defaultMessage="Due" />,
      propName: 'planEndTime',
      propType: 'date',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-completion" defaultMessage="Completion" />,
      propName: 'isCompleted',
      propType: 'boolean',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-completed" defaultMessage="Completed On" />,
      propName: 'lastActivityTime',
      propType: 'date',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-score" defaultMessage="Score" />,
      propName: 'percentScore',
      propType: 'number',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-duration" defaultMessage="Time Spent" />,
      propName: 'durationActual',
      propType: 'number',
    },
  ];

  useEffect(() => {
    setAssignments(sortTableData(tableProps.assignments, sortSettings));
  }, [sortSettings, tableProps.assignments]);

  const sortTable = (propName: string, propType: SortingType, customGetter?: any) => {
    if (!propName) {
      throw Error('Missing required propName');
    }
    if (!propType) {
      throw Error('Missing required propType');
    }

    const settings = updateSortSettings(propName, propType, sortSettings, customGetter);
    setSortSettings(settings);

    const isAscending = sortSettings[propName]?.order === TableHeaderSortableSortDirection.ASC ? false : true;
    mixpanel.track(MixpanelEventTypes.StudentDashboardAssignmentsTableSortChanged, {
      [MixpanelKeys.StudentId]: (routeParams as any).classId,
      [MixpanelKeys.Column]: propName,
      [MixpanelKeys.IsAscending]: isAscending,
    });
  };

  return (
    <PageContentResponsive>
      <Widget>
        <Widget.Body>
          <TableResponsive>
            <TableSimple bordered={false} sticky={true} striped={true}>
              <Thead>
                <Tr>
                  <Th>
                    <></>
                  </Th>
                  {headers.map(header => (
                    <Th key={header.propName}>
                      <TableHeaderSortable
                        sortDirection={getSortingOrder<TableLabels>(header.propName as TableLabels, sortSettings)}
                        onClick={() => sortTable(header.propName, header.propType as SortingType, header.customGetter)}
                      >
                        {header.formattedMessage}
                      </TableHeaderSortable>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {assignments.map((assignment: AssignmentTableData) => (
                  <Tr key={assignment._id + ':' + assignment.coursePlanId}>
                    <Td>
                      <Tooltipped label={intl.formatMessage({ id: `assignments-tab-tooltip-${assignment.status}` })}>
                        <CircularStatusIndicator status={assignment.status === 'past' ? true : false} />
                      </Tooltipped>
                    </Td>
                    <Td>{assignment.name}</Td>
                    <Td>
                      <SubjectName subjectName={assignment.subject.name} />
                    </Td>
                    <Td>
                      {assignment.type === 'essay' ? (
                        <FormattedMessage id="dependant-assignments-table-type-task" defaultMessage="Task" />
                      ) : (
                        <FormattedMessage
                          id="dependant-assignments-table-type-nugget"
                          values={{ count: assignment.numberOfNuggets }}
                          defaultMessage="{count} Nuggets"
                        />
                      )}
                    </Td>
                    {tableProps.isTeach && (
                      <Td>{tableProps.teacherList.find(t => t.value === getFirstCreatorFromAssignment(assignment))?.title} </Td>
                    )}
                    <Td>{getTableDate(assignment.planStartTime)}</Td>
                    <Td>{getTableDate(assignment.planEndTime)}</Td>
                    <Td>
                      {!assignment.isCompleted ? (
                        <ColorEmphasis variant={ColorEmphasisVariant.WARNING}>
                          <FormattedMessage id="dependant-assignments-table-not-completed" defaultMessage="Not Completed" />
                        </ColorEmphasis>
                      ) : assignment.finishedWithinPlanTime ? (
                        <FormattedMessage id="dependant-assignments-table-completed" defaultMessage="Completed" />
                      ) : (
                        <ColorEmphasis variant={ColorEmphasisVariant.WARNING}>
                          <FormattedMessage id="dependant-assignments-table-completed-late" defaultMessage="Completed Late" />
                        </ColorEmphasis>
                      )}
                    </Td>
                    <Td>{getTableDate(assignment.lastActivityTime)}</Td>
                    <Td>
                      {typeof assignment.percentScore === 'number' && assignment.percentScore >= 0 && (
                        <ScorePercentage
                          percentage={assignment.percentScore}
                          hasBar={tableProps.showBars}
                          hasNumber={tableProps.showNumbers}
                          hasSymbol={tableProps.showSymbols}
                          hasColouredCell={tableProps.colouredCells}
                        />
                      )}
                    </Td>
                    <Td>{<DurationMessage duration={assignment.durationActual} />}</Td>
                  </Tr>
                ))}
              </Tbody>
            </TableSimple>
          </TableResponsive>
        </Widget.Body>
      </Widget>
    </PageContentResponsive>
  );
};
