import { DateTime, Duration } from 'luxon';
import { FormattedDuration, FormattedDurationKeys } from 'century-core/entities/Assignment/Assignment';

export function getPercentValue(score: number | undefined): number {
  return score === undefined ? 0 : Math.round(score / 100);
}

export function getTableDate(value: Date | null): string {
  return (value && DateTime.fromJSDate(value).toFormat('DD')) || '';
}

export function getBirthdate(value: Date | undefined): string {
  // TODO: fix the typings of Ctek.User.personal.birthDate to be string as it seems to be ISO
  return value ? DateTime.fromJSDate(new Date(value)).toFormat('dd/MM/yyyy') : '';
}

export function getDDMMMDate(value: string): string {
  return (value && DateTime.fromISO(value).toFormat('d MMM')) || '';
}

export function getDDMYYYYHHmmDate(value: string): string {
  return (value && DateTime.fromISO(value).toFormat('d MMM yyyy, HH:mm')) || '';
}

export function getDDMMMYYYYHHmmDate(value: string): string {
  return (value && DateTime.fromISO(value).toFormat('dd MMMM yyyy HH:mm')) || '';
}

export function getDDMMMMYYYYDate(value: string | Date): string {
  return typeof value === 'string' ? DateTime.fromISO(value).toFormat('dd MMMM yyyy') : DateTime.fromJSDate(value).toFormat('dd MMMM yyyy');
}

export function formatTime(interval: number): FormattedDuration[] {
  const durationObj = getDurationObject(interval);
  let startIndex = 0;
  let endIndex = 0;

  // finding the first item with a value !== 0
  for (let i = 0; i < durationObj.length; i += 1) {
    if (durationObj[i].value !== 0) {
      startIndex = i;
      endIndex = durationObj[i].key === FormattedDurationKeys.YEARS ? i + 2 : i + 1;

      if (endIndex >= durationObj.length - 1) {
        endIndex = durationObj.length - 1;
      }
      break;
    }
  }

  // ignoring empty values in the end of the array
  for (let i = endIndex; i > startIndex; i -= 1) {
    if (durationObj[i].value === 0) {
      endIndex = i - 1;
    } else {
      break;
    }
  }
  return durationObj.slice(startIndex, endIndex + 1);
}

function getDurationObject(interval: number) {
  const duration: Duration = Duration.fromObject({ seconds: interval }).shiftTo(...Object.values(FormattedDurationKeys));

  return [
    {
      key: FormattedDurationKeys.YEARS,
      value: duration.years,
    },
    {
      key: FormattedDurationKeys.MONTHS,
      value: duration.months,
    },
    {
      key: FormattedDurationKeys.DAYS,
      value: duration.days,
    },
    {
      key: FormattedDurationKeys.HOURS,
      value: duration.hours,
    },
    {
      key: FormattedDurationKeys.MINUTES,
      value: duration.minutes,
    },
    {
      key: FormattedDurationKeys.SECONDS,
      value: duration.seconds === 0 && duration.milliseconds > 0 ? 1 : duration.seconds,
    },
  ];
}

export const tabIsActive = (url: string) => {
  return url === window.location.pathname;
};

export const getImageBase64FromUrl = async (url: string): Promise<string | null> => {
  return new Promise(async resolve => {
    const data = await fetch(url);
    const blob = await data.blob();

    if (data.status !== 200) {
      resolve(null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data as string);
    };
  });
};

export const getCurrentClassCategories = (categories: Ctek.ClassCategory[]): Ctek.ClassCategory[] => {
  const now: DateTime = DateTime.now();
  return categories.filter((category: Ctek.ClassCategory) => {
    return category.endDate && DateTime.fromISO(category.endDate) > now;
  });
};

export const formatSeconds = (seconds: number) => {
  const duration = Duration.fromObject({ seconds });
  return duration.toFormat('mm:ss');
}