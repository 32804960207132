import { defaultLocale, AvailableOrgTypes, defaultOrgType } from 'century-core/entities/Intl/defaultIntlValues';
import { Auth } from 'century-core/entities/Auth/Auth';

/**
 * Gets context settings according to token version.
 *
 * If settings are undefined, it returns null.
 */
const getTokenSettings = (auth: Auth): Ctek.OrganisationSettings | undefined => auth.accessTokenData?.context.settings;

/**
 * Gets locale from context settings.
 *
 * If the token or the defaultLocale are undefined, get locale from orgSetting
 * otherwise it returns the defaultLocale from Intl.
 */
export const getTokenSettingsLocale = (auth: Auth): string => {
  const tokenSettings: Ctek.OrganisationSettings | undefined = getTokenSettings(auth);
  return tokenSettings?.defaultLocale || auth.orgSetting?.settings.defaultLocale || defaultLocale;
};

/**
 * Gets organisationType from context settings.
 *
 * If the token or the organisationType are undefined, get organisationType from orgSetting
 * otherwise it returns the defaultOrgType from Intl.
 */
export const getTokenSettingsOrgType = (auth: Auth): AvailableOrgTypes => {
  const tokenSettings: Ctek.OrganisationSettings | undefined = getTokenSettings(auth);
  return (
    (tokenSettings?.organisationType as AvailableOrgTypes) ||
    (auth.orgSetting?.settings.organisationType as AvailableOrgTypes) ||
    defaultOrgType
  );
};

/**
 * Gets cssMainClass from context settings.
 *
 * If the token or the cssMainClass are undefined, get cssMainClass from orgSetting
 * otherwise it returns the defaultOrgType from Intl.
 */
export const getTokenCssMainClass = (auth: Auth): string | undefined => {
  const settings = getTokenSettings(auth);

  return settings?.cssMainClass || auth.orgSetting?.settings.cssMainClass;
};

/**
 * Gets theme config from context settings.
 *
 * If the token or the theme are undefined, get theme from orgSetting
 * otherwise it returns the defaultOrgType from Intl.
 */
export const getTokenThemeSettings = (auth: Auth): Ctek.Theme | undefined => {
  const settings = getTokenSettings(auth);

  return settings?.theme || auth.orgSetting?.settings.theme;
};
