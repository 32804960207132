import * as learnCourseplans from 'century-core/core-apis/learn/courseplans/courseplans';
import * as Utils from 'century-core/core-apis/utils/utils';

interface GetCourseplansOpts {
  selectedUserId: string;
  courses: (Ctek.Course | Ctek.Assignment)[];
  studygroups: Ctek.StudyGroup[];
  returnEmptyActivity?: boolean;
  restrictByCoursePlanStartTime?: boolean;
}
export async function getCourseplans(opts: GetCourseplansOpts, accessToken: string) {
  const { selectedUserId, courses, studygroups, returnEmptyActivity, restrictByCoursePlanStartTime } = opts;
  if (!studygroups || studygroups.length === 0 || !courses || courses.length === 0) {
    return [];
  }
  const filteredStudygroups = Utils.filterStudyGroupsByCourses(studygroups, Utils.getIdsFromDocs(courses));
  const courseplanIds = Utils.getCoursePlanIdsFromStudyGroups(filteredStudygroups);

  return await fetchCourseplans(
    {
      courseplanIds,
      courseplans: [],
      selectedUserId,
      restrictByCoursePlanStartTime,
      returnEmptyActivity,
    },
    accessToken
  );
}

interface FetchCoursePlansOpts {
  courseplanIds: string[];
  selectedUserId: string;
  courseplans: Ctek.CoursePlan[];
  returnEmptyActivity?: boolean;
  restrictByCoursePlanStartTime?: boolean;
}

function fetchCourseplans(opts: FetchCoursePlansOpts, accessToken: string): Promise<Ctek.CoursePlan[]> {
  const { courseplanIds, selectedUserId, courseplans, returnEmptyActivity, restrictByCoursePlanStartTime } = opts;
  const requestedCourseplanIds = courseplanIds.slice(0, 20);
  courseplanIds.splice(0, 20);
  return learnCourseplans
    .getCourseplans(
      {
        courseplanIds: requestedCourseplanIds,
        restrictByCoursePlanStartTime,
        returnEmptyActivity,
        userId: selectedUserId,
      },
      accessToken
    )
    .then(requestedCourseplans => {
      requestedCourseplans.map(crs => courseplans.push(crs));

      if (courseplanIds.length === 0) {
        return courseplans;
      } else {
        return fetchCourseplans(
          { courseplanIds, selectedUserId, courseplans, restrictByCoursePlanStartTime, returnEmptyActivity },
          accessToken
        );
      }
    })
    .catch((e: Error) => {
      throw new Error(`Unable to retrieve courseplans for courseplan ids ${JSON.stringify(requestedCourseplanIds)},
         ${e}`);
    });
}
