import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import Spinner from 'century-core/core-components/Spinner/Spinner';
import Auth from 'century-core/entities/Auth/Auth';
import { SessionError } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { office365SignIn } from 'state/actions/auth/auth';
import { AuthenticationContext, AdalConfig } from 'react-adal';
import queryString from 'query-string';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { ErrorMessage } from 'century-core/entities/ErrorMessage/ErrorMessage';

export const isMicrosoft365SeamlessLoginEnabled = (auth: Auth) => {
  const ssoSettings = auth.orgSetting?.settings?.global?.authSettings?.sso;
  const isOffice365Enabled = ssoSettings?.office365 ? ssoSettings?.office365.isEnabled : false;
  const isOffice365Seamless = ssoSettings?.office365 ? ssoSettings?.office365.isSeamless : false;
  if (isOffice365Enabled && isOffice365Seamless) {
    return true;
  } else {
    return false;
  }
};

export function microsoftLogin(dispatch: any, mixpanel: any, prompt: boolean, popUp: boolean, authError?: ErrorMessage<Errors>) {
  const { id_token } = queryString.parse(window.location.hash);
  // Redirect option (popUp = false) returns #id_token in the url, need to check this case before trying to log in
  if (id_token) {
    if (!authError) {
      dispatch(office365SignIn(id_token as string, mixpanel.track));
    }
  } else {
    const config: AdalConfig = {
      popUp,
      instance: 'https://login.microsoftonline.com/',
      tenant: 'common',
      clientId: 'a633e08d-0aee-482b-926e-0ed21a56e918',
      // make sure there is a trailing slash as ms require it
      postLogoutRedirectUri: window.location.href.replace(/\/*$/, '/'),
    };
    if (prompt) {
      config.extraQueryParameter = 'prompt=select_account';
    }
    const authContext = new AuthenticationContext(config);
    authContext.callback = (err: string, adalToken: string): void => {
      if (err) {
        return;
      }
      dispatch(office365SignIn(adalToken, mixpanel.track));
    };
    return authContext.login();
  }
}

export const Microsoft365SeamlessLogin = (props: { auth: Auth }) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  microsoftLogin(dispatch, mixpanel, false, false, props.auth.error);

  // Show spinner while we try to log the user in. If this fails, display the error message.
  return (
    <Spinner type="fullpage" showContent={!!props.auth.error}>
      <SessionError>
        <SessionError.Heading>
          <FormattedMessage defaultMessage="Whoops! Connection has been lost." id="session-error-default-heading" />
        </SessionError.Heading>
        <SessionError.Description>
          <FormattedMessage defaultMessage={props.auth.error?.message} id="session-error-default-description" />
        </SessionError.Description>
      </SessionError>
    </Spinner>
  );
};
