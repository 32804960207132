import Spinner from 'century-core/core-components/Spinner/Spinner';
import Auth from 'century-core/entities/Auth/Auth';
import { SessionError } from '@ctek/design-system';
import { renewAccessToken } from 'state/actions/auth/auth';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';

export const isLtiLoginEnabled = (auth: Auth) => {
  // If LTI is enabled, auto login and show fallback while this is happening.
  const ssoSettings = auth.orgSetting?.settings?.global?.authSettings?.sso;
  return ssoSettings?.lti?.isEnabled || false;
};

export const LtiLogin = (props: { auth: Auth }) => {
  const dispatch = useDispatch();

  const searchParams = new URL(window.location as any).searchParams;

  // B2C-791: page endlessly refreshes here if we don't include a ref to ensure it's new
  const refreshTokenRef = useRef('');
  // tslint:disable-next-line: variable-name
  const refresh_token = searchParams.get('refresh_token');
  if (refresh_token && refreshTokenRef.current !== refresh_token) {
    refreshTokenRef.current = refresh_token;
    dispatch(renewAccessToken({ refreshToken: refresh_token }));
    const externalReturnURL = searchParams.get('return');
    if (externalReturnURL) {
      localStorage.setItem('external_return_url', externalReturnURL);
    }
  }

  // Show spinner while we try to log the user in. If this fails, display the error message.
  return (
    <Spinner type="fullpage" showContent={!!props.auth.error || !refresh_token}>
      <SessionError>
        <SessionError.Heading>
          <FormattedMessage defaultMessage="Whoops! Connection has been lost." id="session-error-default-heading" />
        </SessionError.Heading>
        <SessionError.Description>
          <FormattedMessage
            defaultMessage="Something seems to have gone wrong. Try signing into your account again to return to where you left off."
            id="session-error-default-description"
          />
        </SessionError.Description>
      </SessionError>
    </Spinner>
  );
};
