import { FormError } from '@ctek/design-system';
import Auth from 'century-core/entities/Auth/Auth';
import { FormattedMessage } from 'react-intl';
import LockOutTimer from './LockOutTimer';

export default function LoginFormError(props: { auth: Auth, lockoutTime: number | null, shakeAnimation: boolean }) {
  return props.auth.error
    ?
    props.lockoutTime
      ? (
        <FormError announce={props.shakeAnimation}>
          <LockOutTimer lockoutTime={props.lockoutTime} />
        </FormError>
      )
      : (
        <FormError>
          <FormattedMessage id={props.auth.error.code} defaultMessage={props.auth.error.message} />
        </FormError>
      )
    : null
}
