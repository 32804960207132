import { GET } from 'century-core/core-apis/utils';
import { getContentURL } from '../contentV2';

export interface GetCoursesOpts {
  select?: string;
  type?: string;
}

export function getCourse(courseId: string, accessToken: string, opts?: GetCoursesOpts) {
  const url = getContentURL(`/courses/${courseId}`);
  const select = (opts && opts.select) || 'type,name,subject,strands';
  url.search += '&select=' + select;
  url.search += '&populate=true';

  return GET<Ctek.Course>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get course by id ${courseId}, ${err}`);
  });
}

export function getCourses(courseIds: string[], accessToken: string, opts?: GetCoursesOpts) {
  const url = getContentURL('/courses');
  const select = (opts && opts.select) || 'type,name,icon,subject,strands,strands.nuggets,labels';
  const type = opts && opts.type ? opts.type : 'standard';
  url.search += '?ids=' + courseIds.join(',');
  url.search += '&select=' + select;
  url.search += '&type=' + type;
  url.search += '&populate=true';

  return GET<Ctek.Course[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get courses for ids ${courseIds}, ${err}`);
  });
}

export function getAllAssignableOrgCourses(token: string, opts?: GetCoursesOpts) {
  const url = getContentURL('/courses/search');

  url.searchParams.set('select', opts?.select ?? '_id,name');
  url.searchParams.set('type', opts?.type ?? 'standard');
  url.searchParams.set('populate', 'true');
  url.searchParams.set('isAssignable', 'true');
  url.searchParams.set('limit', '500');
  url.search = decodeURIComponent(url.searchParams.toString());

  return GET<Ctek.Course[]>({ url, token }).catch((err: Error) => {
    throw Error(`Unable to get all assignable organisation courses. Error: ${err.message}`);
  });
}
