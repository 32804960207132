import { Duration } from 'luxon';
import { FormattedMessage } from 'react-intl';

export default function LockOutTimer(props: { lockoutTime: number }) {
  const timeLeft = Math.min(Math.floor(Duration.fromMillis(props.lockoutTime).shiftTo('minutes').toObject().minutes as number + 1), 5);

  return (
    <FormattedMessage
      id="login-error-locked-out"
      defaultMessage={`Too many failed attempts. Try again in ${timeLeft} minute(s)`}
      values={{ timeLeft }}
    />
  )
}
