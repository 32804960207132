import { useEffect, useRef, useState } from 'react';
import { DashboardSubjectsPage, FlexGrid } from '@ctek/design-system';

import { getRecommendations } from 'century-core/core-apis/learn/recommendation/recommendation';
import { useAccessToken, useAuth } from 'century-core/core-auth/hooks/useAuth';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import ActionsFocuses, { ActionsFocusesMode } from './ActionsFocuses/ActionsFocuses';
import StrengthsWeaknesses, { StrengthWeaknessMode } from './StrengthsWeaknesses/StrengthsWeaknesses';
import { StudentFocusAndStretch } from './FocusAndStretch/StudentFocusAndStretch';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { hasFeature } from 'century-core/core-auth/utils';

interface RecommendationsParams {
  userId: string;
  coursePlanId: string;
  courseId: string;
  widgetMode: DashboardUserMode;
  studyGroupId: string;
  isCourseTab?: boolean; // LX Phase 1 - won't be needed anymore when we roll Subjects tab out to every user.
}

function RecommendationsSubjects(props: RecommendationsParams) {
  const { userId, coursePlanId, widgetMode, courseId, studyGroupId, isCourseTab } = props;
  const accessToken = useAccessToken();
  const auth = useAuth();
  const [recommendations, setRecommendations] = useState<Ctek.Recommendation | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const fetchedParamsRef = useRef<Partial<RecommendationsParams>>({});
  const [error, setError] = useState(null);
  const isFocusAndStretchEnabled = hasFeature(auth, 'focus-and-stretch', 'studentDashboard');

  useEffect(() => {
    if (!coursePlanId) {
      // set recommendations to null in case user previously viewed a course with recommendations
      setRecommendations(null);
      fetchedParamsRef.current = {
        coursePlanId,
        userId,
      };
      setIsLoading(false);
      return;
    }
    if (coursePlanId !== fetchedParamsRef.current?.coursePlanId || userId !== fetchedParamsRef.current?.userId) {
      setIsLoading(true);
      getRecommendations(coursePlanId, userId, accessToken, 5)
        .then(v => {
          ['weaknesses', 'strengths', 'focuses', 'actions'].forEach(k => {
            v[k] = v[k]?.map((v: Ctek.AbacusCourseRecommendationPopulated) => ({
              ...v,
              percentScore: getPercentValue(v.percentScore),
              studyGroupId,
            }));
          });
          setRecommendations(v);
        })
        .catch(e => setError(e))
        .finally(() => {
          fetchedParamsRef.current = {
            coursePlanId,
            userId,
          };
          setIsLoading(false);
        });
    }
  }, [userId, coursePlanId, accessToken, studyGroupId]);

  const columnLgWidth = isCourseTab ? 12 : 6;

  return (
    <DashboardSubjectsPage.Recommendations>
      <FlexGrid>
        {!error ? (
          <>
            <FlexGrid.Col sm={12} lg={columnLgWidth}>
              <StrengthsWeaknesses
                isLoading={isLoading}
                data={recommendations?.strengths || []}
                mode={StrengthWeaknessMode.STRENGTHS}
                widgetMode={widgetMode}
                hasFocusAndStretchEnabled={isFocusAndStretchEnabled}
              />
            </FlexGrid.Col>
            <FlexGrid.Col sm={12} lg={columnLgWidth}>
              <StrengthsWeaknesses
                isLoading={isLoading}
                data={recommendations?.weaknesses || []}
                mode={StrengthWeaknessMode.WEAKNESSES}
                widgetMode={widgetMode}
                hasFocusAndStretchEnabled={isFocusAndStretchEnabled}
              />
            </FlexGrid.Col>
            {!isFocusAndStretchEnabled && (
              <>
                <FlexGrid.Col sm={12} lg={columnLgWidth}>
                  <ActionsFocuses isLoading={isLoading} data={recommendations?.actions || []} mode={ActionsFocusesMode.ACTIONS} />
                </FlexGrid.Col>
                <FlexGrid.Col sm={12} lg={columnLgWidth}>
                  <ActionsFocuses isLoading={isLoading} data={recommendations?.focuses || []} mode={ActionsFocusesMode.FOCUSES} />
                </FlexGrid.Col>
              </>
            )}
          </>
        ) : null}
        {isFocusAndStretchEnabled && <StudentFocusAndStretch userId={userId} courseId={courseId} widgetMode={widgetMode} />}
      </FlexGrid>
    </DashboardSubjectsPage.Recommendations>
  );
}

export default RecommendationsSubjects;
