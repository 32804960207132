import { GET } from 'century-core/core-apis/utils';
import { getContentURL } from '../contentV2';

export interface GetAssignmentsOpts {
  select?: string;
  type?: string;
}
export function getAssignments(courseIds: string[], accessToken: string, opts?: GetAssignmentsOpts) {
  const url = getContentURL('/assignments');
  const select = (opts && opts.select) || 'type,name,icon,subject,strands';
  url.search += '?ids=' + courseIds.join(',');
  url.search += '&select=' + select;
  url.search += '&populate=true';

  return GET<Ctek.Assignment[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get assignments for course ids ${courseIds}, ${err}`);
  });
}
