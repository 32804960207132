import { GET } from 'century-core/core-apis/utils';
import { getAccountsUrl } from '../accountsV2';

export function getClassCategories(accessToken: string, params?: { [key: string]: string }) {
  const url = getAccountsUrl('/class-categories');
  url.search += decodeURIComponent(new URLSearchParams(params).toString());

  return GET<Ctek.ClassCategory[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get class categories, ${err.message}`);
  });
}
