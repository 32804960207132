import { Button, Form, FormError, Input, Label, SignInBox, Text } from '@ctek/design-system';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { classCodeInitialValues, validateClassCodeForm } from '../validation';
interface Props {
  validateCode: (classCode: string) => void;
}

export const ClassCodeForm = (props: Props) => {
  return (
    <Formik
      initialValues={classCodeInitialValues}
      validate={validateClassCodeForm}
      onSubmit={async (values, { setSubmitting }) => {
        await props.validateCode(values.classCode);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <SignInBox.Header>
            <h1 data-testid="class-code-form-title">
              <FormattedMessage id="class-code-form-title" defaultMessage="I have a class code" />
            </h1>
          </SignInBox.Header>
          <SignInBox.Body>
            <Text.Lead qa="class-code-form-instructions">
              <FormattedMessage
                id="class-code-form-instructions"
                defaultMessage="Create an account with CENTURY with a code given to you by your teacher."
              />
            </Text.Lead>
          </SignInBox.Body>
          <SignInBox.Form>
            <Form onSubmit={handleSubmit} qa="class-code-form">
              <Label>
                <Label.Text qa="class-code-form-label">
                  <FormattedMessage id="class-code-label" defaultMessage="Class code" />
                </Label.Text>
                <Input onChange={handleChange} value={values.classCode} name="classCode" qa="class-code-form-input" maxLength={30} />
                {errors.classCode && touched.classCode ? <FormError qa="class-code-input-error">{errors.classCode}</FormError> : null}
              </Label>

              <Button type="submit" disabled={isSubmitting} qa="class-code-form-submit" variant="primary" width="full">
                <FormattedMessage id="submit-button" defaultMessage="Submit" />
              </Button>
            </Form>
          </SignInBox.Form>
        </>
      )}
    </Formik>
  );
};
