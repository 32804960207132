import * as React from 'react';
import {
  Button,
  ClickArea,
  Form,
  FormError,
  Input,
  Label,
  LoadingSpinner,
  SignInBox,
  SignInScreen,
  StepNavigationButton,
  StepNavigationButtonDirection,
} from '@ctek/design-system';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { focusInputElement, getLocationUrlParam, MIN_PASSWORD_LENGTH } from 'century-core/core-utils/utils/utils';
import { sendRecoverPasswordEmail } from 'century-core/core-apis/sentinel/auth/auth';
import { useMemo, useState } from 'react';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { useHistory } from 'react-router-dom';
import { recoverPassword } from 'century-core/core-auth/api/recoverPassword';
import { useDataLoader } from 'century-core/core-utils/hooks/use-data-loader';

const formErrors = {
  'error-password-too-short': `Passwords must be at least ${MIN_PASSWORD_LENGTH} characters long`,
  'recover-password-required-confirm-new-password': 'Please confirm your password',
  'recover-password-required-email': 'Please enter your username or email',
  'recover-password-required-new-password': 'Please enter your password',
  'recover-password-required-passwords-dont-match': "Passwords don't match",
};

const msg =
  'An email has been sent to you with a link to choose a new password. Go to your inbox and remember to' +
  " check your spam or junk folder if you can't find the email.";

const genericErrorMsg = 'We were unable to process your request. Please try again and if the problem persists contact our support.';

const passwordChangeInitialValues = {
  confirmPassword: '',
  newPassword: '',
};

type PasswordChangeInitialValues = typeof passwordChangeInitialValues;

function validateRecoverPasswordForm(values: { email: string }) {
  const errors: Record<string, keyof typeof formErrors> = {};

  if (!values.email) {
    errors.email = 'recover-password-required-email';
  }

  return errors;
}

function validatePasswordChangeForm(values: PasswordChangeInitialValues) {
  const errors: Record<string, keyof typeof formErrors> = {};

  if (!values.newPassword) {
    errors.newPassword = 'recover-password-required-new-password';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'recover-password-required-confirm-new-password';
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.newPassword = 'recover-password-required-passwords-dont-match';
  }

  if (values.newPassword && values.newPassword.length < MIN_PASSWORD_LENGTH) {
    errors.newPassword = 'error-password-too-short';
  }

  return errors;
}

export const RecoverPasswordPage = (props: { emailAddress: string }) => {
  let content;
  const history = useHistory();
  const recoverToken = useMemo(() => getLocationUrlParam('token'), []);
  const [status, setStatus] = useState<'email-send-form' | 'email-sent-message' | 'password-change-form' | 'password-changed-message'>(
    !recoverToken ? 'email-send-form' : 'password-change-form'
  );
  const [error, setError] = useState<ErrorMessage<Errors> | null>(null);
  const [canShowPassword, setcanShowPassword] = React.useState(false);
  const tokenChecker = useDataLoader(() => recoverPassword(recoverToken, ''), !!recoverToken);
  const expiredTokenRedirect = () => {
    setError(null);
    setStatus('email-send-form');
    tokenChecker.reset();
    history.push('/login/recover-password');
  };

  if (tokenChecker.loading) {
    return (
      <SignInBox>
        <LoadingSpinner type="fullpage" />
      </SignInBox>
    );
  }

  if (tokenChecker.error || error?.code === 'localised-errors-recover-password-expired-token') {
    return <TokenExpiredBox onClick={expiredTokenRedirect} />;
  }

  if (!!error) {
    content = (
      <>
        <SignInBox.Header>
          <h1>
            <FormattedMessage id="recover-password-fail-title" defaultMessage="We have a problem!" />
          </h1>
        </SignInBox.Header>
        <SignInBox.Body qa="recover-password-error">
          {error.code === 'localised-errors-learner-missing-email' ? (
            <FormattedMessage id={error.code} defaultMessage={error.message} />
          ) : (
            <FormattedMessage id="recover-password-fail-message" defaultMessage={genericErrorMsg} />
          )}
        </SignInBox.Body>
      </>
    );
  } else {
    switch (status) {
      case 'password-changed-message':
        content = (
          <>
            <SignInBox.Header>
              <h1>
                <FormattedMessage id="recover-password-password-changed-title" defaultMessage="Hurrah!" />
              </h1>
            </SignInBox.Header>
            <SignInBox.Body qa="recover-password-password-changed-message">
              <FormattedMessage
                id="recover-password-password-changed-message"
                defaultMessage="Your new password is ready to use. Try it out now."
              />
            </SignInBox.Body>
            <SignInBox.Footer>
              <Button size="medium" onClick={() => history.push('/')} variant="primary">
                <FormattedMessage id="login-sign-in-button-label" defaultMessage="Sign In" />
              </Button>
            </SignInBox.Footer>
          </>
        );
        break;
      case 'password-change-form':
        content = (
          <Formik
            initialValues={passwordChangeInitialValues}
            validate={validatePasswordChangeForm}
            onSubmit={(values, { setSubmitting }) => {
              recoverPassword(recoverToken, values.newPassword)
                .then(() => setStatus('password-changed-message'))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
              <>
                <SignInBox.Header>
                  <h1>
                    <FormattedMessage id="recover-password-change-password-title" defaultMessage="Choose a new password!" />
                  </h1>
                </SignInBox.Header>
                <SignInBox.Form>
                  <Form onSubmit={handleSubmit}>
                    <Label>
                      <Label.Text>
                        <FormattedMessage id="new-password" defaultMessage="New password" />
                      </Label.Text>
                      <Input
                        type={canShowPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        value={values.newPassword}
                        name="newPassword"
                        qa="recover-password-password-change-new-input"
                        ref={focusInputElement}
                      />
                      {errors.newPassword && touched.newPassword ? (
                        <FormError data-testid="recover-password-password-change-new-input-error">
                          <FormattedMessage
                            id={errors.newPassword}
                            defaultMessage={formErrors[errors.newPassword]}
                            values={{ minLength: MIN_PASSWORD_LENGTH }}
                            data-testid="recover-password-password-change-new-input-error"
                          />
                        </FormError>
                      ) : null}
                    </Label>
                    <Label>
                      <Label.Text>
                        <FormattedMessage id="new-password" defaultMessage="Confirm new password" />
                      </Label.Text>
                      <Input
                        type={canShowPassword ? 'text' : 'password'}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        qa="recover-password-password-change-confirm-input"
                        ref={focusInputElement}
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <FormError data-testid="recover-password-password-change-confirm-input-error">
                          <FormattedMessage
                            id={errors.confirmPassword}
                            defaultMessage={formErrors[errors.confirmPassword]}
                            data-testid="recover-password-password-change-confirm-input-error"
                          />
                        </FormError>
                      ) : null}
                    </Label>
                    <Label>
                      <Input type="checkbox" onChange={() => setcanShowPassword(!canShowPassword)}>
                        <Input.Text>
                          <FormattedMessage id="show-password" defaultMessage="Show passwords" />
                        </Input.Text>
                      </Input>
                    </Label>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      qa="recover-password-password-change-submit"
                      variant="primary"
                      width="full"
                    >
                      <FormattedMessage id="submit-button" defaultMessage="Submit" />
                    </Button>
                  </Form>
                </SignInBox.Form>
              </>
            )}
          </Formik>
        );
        break;
      case 'email-sent-message':
        content = (
          <>
            <SignInBox.Header>
              <h1>
                <FormattedMessage id="recover-password-email-sent-title" defaultMessage="Thank you!" />
              </h1>
            </SignInBox.Header>
            <SignInBox.Body qa="recover-password-email-sent-message">
              <FormattedMessage id="recover-password-email-sent-message" defaultMessage={msg} />
            </SignInBox.Body>
          </>
        );
        break;
      case 'email-send-form':
      default:
        content = (
          <Formik
            initialValues={{ email: props.emailAddress }}
            validate={validateRecoverPasswordForm}
            onSubmit={(values, { setSubmitting }) => {
              sendRecoverPasswordEmail(values.email)
                .then(() => setStatus('email-sent-message'))
                .catch(e => setError(e))
                .finally(() => setSubmitting(false));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
              <>
                <SignInBox.Header>
                  <h1>
                    <FormattedMessage id="recover-password-email-form-title" defaultMessage="Not to worry!" />
                  </h1>
                </SignInBox.Header>
                <SignInBox.Body>
                  <FormattedMessage
                    id="recover-password-email-form-instructions"
                    defaultMessage="Enter your username to reset your password. Your username is probably the email address 
                        linked to your CENTURY account."
                  />
                  <SignInBox.Form>
                    <Form onSubmit={handleSubmit}>
                      <Label>
                        <Label.Text>
                          <FormattedMessage id="login-username" defaultMessage="Username or email" />
                        </Label.Text>
                        <Input
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          qa="recover-password-send-email-input"
                          ref={focusInputElement}
                        />
                        {errors.email && touched.email ? (
                          <FormError>
                            <FormattedMessage id="email-error-recover-password" defaultMessage={formErrors[errors.email as string]} />
                          </FormError>
                        ) : null}
                      </Label>
                      <Button type="submit" disabled={isSubmitting} qa="recover-password-send-email-submit" variant="primary" width="full">
                        <FormattedMessage id="recover-password-submit-button" defaultMessage="Recover password" />
                      </Button>
                    </Form>
                  </SignInBox.Form>
                </SignInBox.Body>
              </>
            )}
          </Formik>
        );
    }
  }

  return (
    <>
      <SignInBox>{content}</SignInBox>
      {status === 'email-send-form' || status === 'email-sent-message' ? (
        <SignInScreen.Navigation>
          <ClickArea qa="class-code-go-to-login-button" onClick={() => history.push('/')}>
            <StepNavigationButton direction={StepNavigationButtonDirection.BACK}>
              <FormattedMessage id="back-to-sign-in" defaultMessage="back to sign in" />
            </StepNavigationButton>
          </ClickArea>
        </SignInScreen.Navigation>
      ) : null}
    </>
  );
};

const TokenExpiredBox = ({ onClick }: { onClick: () => void }) => (
  <SignInBox qa="recover-password-token-expired-box">
    <SignInBox.Header>
      <h1>
        <FormattedMessage id="recover-password-token-expired-title" defaultMessage="This link has expired!" />
      </h1>
    </SignInBox.Header>
    <SignInBox.Body qa="recover-password-error">
      <FormattedMessage
        id="recover-password-token-expired-message"
        defaultMessage="Please request a new password reset email if you need to change your password."
      />
    </SignInBox.Body>
    <SignInBox.Footer>
      <Button size="medium" onClick={onClick} variant="primary" qa="recover-password-token-expired-get-link-button">
        <FormattedMessage id="recover-password-token-expired-button-label" defaultMessage="Get new link" />
      </Button>
    </SignInBox.Footer>
  </SignInBox>
);
