import React from 'react';

// GIST: https://gist.github.com/kentcdodds/b36572b6e9227207e6c71fd80e63f3b4
export const useAbortController = () => {
  const abortControllerRef = React.useRef<AbortController>();

  React.useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  const getSignal = React.useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current.signal;
  }, []);

  return getSignal;
};
