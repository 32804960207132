import React, { createContext, useContext, useMemo } from 'react';
import { getUserName } from '../selectors/users';
import { isRTLLocaleFromTokenLocale } from '../utils/intl/intl';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';

const UserContext = createContext({ user: null as Ctek.User | null });

export const useUserContext = () => useContext(UserContext);

export const useUser = () => useContext(UserContext).user;

export const useUserName = (isCommaSeparated?: boolean) => {
  const user = useUser();
  const auth = useAuth();
  const isRtl = isRTLLocaleFromTokenLocale(auth)
  if (user) {
    return getUserName(user, isRtl)
  };
  return '';
}

export interface UserProfile {
  avatar: string;
  birthDate: Date | null;
  emails: Ctek.UserContactEmail[];
  firstName: string;
  lastName: string;
  name: string;
  userId: string;
  username: string;
}

export const useUserId = () => {
  const { user } = useUserContext();

  return user?._id as string;
};

export const useUserRoles = () => {
  const { user } = useUserContext()

  return useMemo(() => {
    const roleSet = new Set(user?.profile?.groups?.roles)
    user?.profile?.groups?.organisations?.forEach(o => o.roles?.forEach(r => roleSet.add(r)))
    return Array.from(roleSet)
  }, [user])
}

export const useUserProfile = () => {
  const { user } = useUserContext();

  return {
    avatar: user?.profile?.avatar || '',
    birthDate: user?.personal?.birthDate,
    emails: user?.contact?.emails || [],
    firstName: user?.personal?.name?.first || '',
    lastName: user?.personal?.name?.last,
    name: `${user?.personal?.name?.first} ${user?.personal?.name?.last}`,
    userId: user?._id as string,
    username: user?.profile?.ids?.username || '',
  } as UserProfile;
};

export const UserProvider: React.FC<{ user: Ctek.User | null }> = ({ children, user }) => {
  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};
