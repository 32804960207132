import { Auth } from 'century-core/entities/Auth/Auth';
import {
  featureName,
  isFeatureOverrideEnabled,
  getFeatureFlagData,
  hasAssessmentsFeatureTeacherOrManagerRole,
} from 'century-core/core-auth/utils';

export interface MainMenuRoute {
  default: boolean;
  feature: featureName;
  key: string;
  name: string;
  roles: string[];
  isPolymerApp?: boolean;
  isExternalLink?: boolean;
  // path is to route the user to a path, it's specific to avoid depending on the Router redirect and cause re-rendering
  path: string;
  // appRoute is just to have the generic route when 'path' is tab specific to highlight the meny items
  appRoute?: string;
}

export function getMainMenuRoutesByRoles(
  tokenOrgs: Ctek.JWTDataOrganisation[],
  auth: Auth,
  isActiveB2CGuardian?: boolean,
  b2c?: { isB2C: boolean; hasLXJourneyEnabled?: boolean; isB2Cloading?: boolean },
  excludedMenuKeys?: MainMenuRoute['key'][],
  isResponsiveOrder: boolean = false
): MainMenuRoute[] {
  // LX PHASE 1 - we need to hold until the product is loaded to check whether is BOP or BOPP, to avoid ugly re-rendering
  if (b2c?.isB2Cloading) {
    return [];
  }

  const routes: MainMenuRoute[] = getAllMainMenuRoutes(auth, isResponsiveOrder, isActiveB2CGuardian, b2c?.isB2C, b2c?.hasLXJourneyEnabled);

  const roleFilteredRoutes = routes.filter((route: MainMenuRoute) => {
    const { roles } = route;
    return tokenOrgs.some(organisation => organisation.roles.some(role => roles.includes(role)));
  });

  // Filter the routes by feature
  const featureFilteredRoutes = roleFilteredRoutes.filter(route => userHasAccess(route, auth) && !excludedMenuKeys?.includes(route.key));
  // if exclude is true (has these restrictions) we should not display it
  // this implementation needs some love if we are to extend it
  return featureFilteredRoutes;
}

const userHasAccess = (item: MainMenuRoute, auth: Auth) => {
  return isFeatureOverrideEnabled(auth, item.feature, item.default);
};

export function getAllMainMenuRoutes(
  auth: Auth,
  isResponsiveOrder: boolean = false,
  isActiveB2CGuardian?: boolean,
  isB2C?: boolean,
  hasLXJourneyEnabled?: boolean
): MainMenuRoute[] {
  const hasAssessmentRoutes = hasAssessmentsFeatureTeacherOrManagerRole(auth);
  const assessmentPlayerFeatureFlagData = getFeatureFlagData<{ playerOnly: boolean }>(auth, 'assessments', 'assessment-player');

  const homeRedirect = getFeatureFlagData<{ name: string; url: string }>(auth, 'main-menu', 'homeRedirect');
  const localStorageHomeRedirect = localStorage.getItem('external_return_url') || '';
  const homeRedirectRoute: MainMenuRoute[] = homeRedirect
    ? [
        {
          default: true,
          feature: 'external-home-redirect',
          key: 'external-home-redirect',
          name: homeRedirect.name,
          path: homeRedirect.url || localStorageHomeRedirect,
          isPolymerApp: false,
          isExternalLink: true,
          roles: ['admin', 'manager', 'teacher', 'student', 'learner', 'leadership', 'editor'],
        },
      ]
    : [];

  let guardianRoutes: MainMenuRoute[] = [];
  if (!isB2C) {
    guardianRoutes = [
      {
        default: true,
        isPolymerApp: true,
        feature: 'guardian',
        key: 'guardian-my-guardian-dashboard',
        name: 'Guardian Dashboard',
        path: '/guardian/dependants',
        roles: ['guardian'],
      },
    ];
  } else {
    if (!!isActiveB2CGuardian) {
      guardianRoutes = [
        {
          default: true,
          isPolymerApp: true,
          feature: 'guardian',
          key: 'guardian-my-dashboard',
          name: 'Dashboard',
          path: '/guardian/dependants',
          roles: ['guardian'],
        },
        {
          default: false,
          feature: 'b2c',
          key: 'guardian-consumer-dashboard',
          name: 'My Subscription',
          path: '/guardian/subscription',
          roles: ['guardian'],
        },
      ];
    } else {
      guardianRoutes = [
        {
          default: false,
          feature: 'b2c',
          key: 'guardian-consumer-dashboard',
          name: 'My Subscription',
          path: '/guardian/subscription',
          roles: ['guardian'],
        },
      ];
    }
  }

  // LX PHASE 1 - routes are conditional on isBondProduct and feature flags
  const learnerRoutes: MainMenuRoute[] = [
    {
      default: !!hasLXJourneyEnabled,
      feature: 'my-journey',
      isPolymerApp: true,
      key: 'learn-my-journey',
      name: 'My Journey',
      path: '/learn/my-journey',
      roles: ['learner'],
    },
    {
      default: !hasLXJourneyEnabled,
      feature: 'my-path',
      isPolymerApp: true,
      key: 'learn-my-path',
      name: 'My Path',
      path: '/learn/my-path',
      roles: ['learner'],
    },
    {
      default: !hasLXJourneyEnabled,
      feature: 'courses',
      isPolymerApp: false,
      key: 'learn-my-courses',
      name: 'My Courses',
      path: '/learn/my-courses',
      roles: ['learner'],
    },
    {
      default: true,
      isPolymerApp: false,
      feature: 'assignments',
      key: 'learn-my-assignments',
      name: 'My Assignments',
      appRoute: '/learn/assignments',
      path: '/learn/assignments/due',
      roles: ['student'],
    },
    {
      default: true,
      feature: 'student-dashboard',
      isPolymerApp: true,
      key: 'learn-my-dashboard',
      name: 'My Dashboard',
      appRoute: '/learn/my-dashboard',
      path: '/learn/my-dashboard/overview',
      roles: ['student'],
    },
  ];

  const teacherRoutes: MainMenuRoute[] = [
    {
      default: true,
      feature: 'leadership-dashboard',
      isPolymerApp: true,
      key: 'leadership-dashboard',
      name: 'Leadership Dashboard',
      path: '/leadership',
      roles: ['leadership'],
    },
    {
      default: true,
      feature: 'teacher-dashboard',
      isPolymerApp: true,
      key: 'teach-dashboard',
      name: 'Teacher Dashboard',
      appRoute: '/teach',
      path: '/teach/classes',
      roles: ['teacher'],
    },
    {
      default: true,
      isPolymerApp: true,
      feature: 'cms',
      key: 'cms-courses',
      name: 'Courses',
      path: '/cms/courses',
      roles: ['editor'],
    },
    {
      default: true,
      feature: 'planner',
      isPolymerApp: true,
      key: 'cms-scheme-of-work',
      name: 'My Planner',
      path: '/cms/schemes-of-work',
      roles: ['teacher'],
    },
    {
      default: true,
      isPolymerApp: true,
      feature: 'assignments',
      key: 'assignments',
      name: 'Assignments',
      path: '/assignments',
      roles: ['teacher'],
    },
    {
      default: true,
      isPolymerApp: hasAssessmentRoutes,
      feature: 'assessments',
      key: 'assessments',
      name: 'Assessments',
      path: '/assessments',
      roles: hasAssessmentRoutes ? ['manager', 'teacher'] : [],
    },
    {
      default: true,
      isPolymerApp: true,
      feature: 'admin',
      key: 'admin-class-admin',
      name: 'Class Admin',
      appRoute: '/admin',
      path: '/admin/classes',
      roles: ['admin', 'manager', 'teacher'],
    },
  ];

  const assessmentRoutes: MainMenuRoute[] = [
    {
      default: true,
      feature: 'assessments',
      key: 'assessments',
      name: 'My Assessments',
      path: '/my-assessments',
      roles: ['student'],
    },
  ];

  return [
    ...homeRedirectRoute,
    ...guardianRoutes,
    ...(isResponsiveOrder ? teacherRoutes : learnerRoutes),
    ...(isResponsiveOrder ? learnerRoutes : teacherRoutes),
    ...(!!assessmentPlayerFeatureFlagData ? assessmentRoutes : []),
  ] as MainMenuRoute[];
}
