import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import Auth from 'century-core/entities/Auth/Auth';
import { auth0SignIn, googleSignIn, GoogleAccessToken, openIdSignIn } from 'state/actions/auth/auth';
import { useCallback, useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import * as Select from 'state/selectors';
import * as microsoftTeams from '@microsoft/teams-js';
import { DividerLabelled, SSOButton, SSOButtonProvider } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { microsoftLogin } from './Microsoft365SeamlessLogin';

declare const OidcClient: any;

export const SSOButtons = () => {
  const auth = useSelector(Select.getAuth);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const ssoSettings = auth.orgSetting?.settings?.global?.authSettings?.sso;
  const isGoogleEnabled = ssoSettings?.google ? ssoSettings?.google.isEnabled : true;
  const isOffice365Enabled = ssoSettings?.office365 ? ssoSettings?.office365.isEnabled : true;
  const isAuth0Enabled = ssoSettings?.auth0 ? ssoSettings?.auth0.isEnabled : false;
  const isOpenIdEnabled = ssoSettings?.openId ? ssoSettings?.openId.isEnabled : false;
  const [isMicrosoftTeams, setIsMicrosoftTeams] = useState(false);
  const enableUsernameAndPassword = auth.orgSetting?.settings?.global?.authSettings?.isUsernameAndPasswordEnabled ?? true;

  const googleSignInDispatch = (googleAccessToken: GoogleAccessToken) => {
    dispatch(googleSignIn(googleAccessToken.tokenObj.id_token, mixpanel.track));
  };

  const handleAuth0Loaded = useCallback(async () => {
    if (isAuth0Enabled && (window as any).auth0 && window.location.search.includes('code=') && window.location.search.includes('state=')) {
      const auth0Client = (window as any).auth0;
      try {
        await auth0Client.handleRedirectCallback();
        if (await auth0Client.isAuthenticated()) {
          const token = await auth0Client.getTokenSilently();
          dispatch(auth0SignIn(token, mixpanel.track));
        }
      } catch (error) {
        window.location.href = `${window.location.origin}/login/`;
      }
    }
  }, [dispatch, isAuth0Enabled, mixpanel]);

  const autoOpenIdSignIn = useCallback(() => {
    if (isOpenIdEnabled && window.location.hash) {
      const client = new OidcClient();
      client.processSigninResponse().then((params: any) => {
        if (params.error) {
          return;
        }
        dispatch(openIdSignIn(params.access_token, mixpanel.track));
      });
    }
  }, [dispatch, isOpenIdEnabled, mixpanel.track]);

  useEffect(() => {
    autoOpenIdSignIn();
    handleAuth0Loaded();
    window.addEventListener('auth0-client-loaded', handleAuth0Loaded);

    return () => window.removeEventListener('auth0-client-loaded', handleAuth0Loaded);
  }, [autoOpenIdSignIn, handleAuth0Loaded]);

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.appInitialization.notifySuccess();
    microsoftTeams.getContext(() => {
      setIsMicrosoftTeams(true);
    });
  }, []);

  const ssoButtons = [];

  if (isGoogleEnabled && !isMicrosoftTeams) {
    ssoButtons.push(
      <GoogleLogin
        key="ssobutton_google"
        clientId="970757263143-gvvdult95616r8uq4pihjghkrmoccl6c.apps.googleusercontent.com"
        scope="profile email"
        render={(renderProps: any) => (
          <SSOButton onClick={renderProps.onClick} qa="sso-login-button" provider={SSOButtonProvider.GOOGLE}>
            <FormattedMessage id="google-sign-in-label" defaultMessage="Sign in with Google" />
          </SSOButton>
        )}
        buttonText="Login"
        onSuccess={googleSignInDispatch as any}
        onFailure={(err: any) => {
          return;
        }}
        cookiePolicy={'single_host_origin'}
      />
    );
  }

  if (isOffice365Enabled && !isMicrosoftTeams) {
    ssoButtons.push(
      <SSOButton key="ssobutton_ms" onClick={() => microsoftLogin(dispatch, mixpanel, true, true)} provider={SSOButtonProvider.MICROSOFT}>
        <FormattedMessage id="office-365-sign-in-label" defaultMessage="Sign in with Office 365" />
      </SSOButton>
    );
  }

  if (isAuth0Enabled) {
    ssoButtons.push(
      <SSOButton key="ssobutton_auth0" onClick={auth0SignInWithRedirect} provider={SSOButtonProvider.AUTH0}>
        <FormattedMessage id="auth0-sign-in-label" defaultMessage="Sign in with Auth0" />
      </SSOButton>
    );
  }

  if (isOpenIdEnabled) {
    ssoButtons.push(
      <SSOButton
        key="ssobutton_openid"
        provider={SSOButtonProvider.OPENID}
        onClick={() =>
          openIdLogin(
            (token: any) => dispatch(openIdSignIn(token, mixpanel.track)),
            (err: any) => {
              //tslint:disable
              console.log(err);
            }
          )(auth)
        }
      >
        <FormattedMessage id="open-id-sign-in-label" defaultMessage="Sign in with Open Id" />
      </SSOButton>
    );
  }

  return ssoButtons.length === 0 ? null : (
    <>
      {!!enableUsernameAndPassword ? (
        <DividerLabelled>
          <FormattedMessage id="or-sign-in-label" defaultMessage="Or" />
        </DividerLabelled>
      ) : null}
      {ssoButtons}
    </>
  );
};

async function auth0SignInWithRedirect(): Promise<void> {
  if ((window as any).auth0) {
    const { redirect } = queryString.parse(window.location.search);
    const redirectUri = redirect
      ? `${window.location.origin}/login/?redirect=${escape(redirect as string)}`
      : `${window.location.origin}/login/`;

    await (window as any).auth0.loginWithRedirect({
      redirect_uri: redirectUri,
    });
  }
}

export function getTokenSsoDomains(auth: Auth): string[] {
  const tokenContext = auth?.accessTokenData?.context;

  switch (tokenContext?.version) {
    case 0:
      return [];
    case 1:
    default:
      return auth?.orgSetting?.settings?.ssoDomains || [];
  }
}

export function openIdLogin(setToken: any, setError: any) {
  return (auth: Auth) => {
    const ssoDomains = getTokenSsoDomains(auth);
    if (!ssoDomains.length) {
      setError('Open ID missing valid domains');
    }
    const redirectUri = `${window.location.protocol}//${window.location.hostname}/login/`;
    const settings = {
      authority: ssoDomains[0],
      client_id: 'century',
      redirect_uri: redirectUri,
      post_logout_redirect_uri: redirectUri,
      response_type: 'id_token token',
      scope: 'openid profile given_name email offline_access',
      loadUserInfo: true,
      grant_type: 'implicit',
    };
    const client = new OidcClient(settings); // open id client gen
    // @ts-ignore
    client.AllowAccessTokensViaBrowser = true;
    client
      .createSigninRequest()
      .then((data: any) => {
        setToken(data);
        window.location.href = data.url;
      })
      .catch((error: any) => {
        setError(error);
      });
  };
}
