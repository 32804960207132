import Auth from 'century-core/entities/Auth/Auth';
import { createContext, useContext, useEffect } from 'react';

export const AuthContext = createContext<{ auth: Auth }>({ auth: {} });

interface Props {
  auth: Auth;
}

export const useAuthContext = () => useContext(AuthContext);

export type AuthUpdateEvent = CustomEvent<{ auth: Auth }>;

export const AuthProvider: React.FC<Props> = ({ auth, children }) => {
  useEffect(() => {
    // hack for notify polymer of auth changes
    if (document.querySelectorAll('polymer-to-react').length > 0) {
      const event = new CustomEvent<{ auth: Auth }>('authUpdated', { detail: { auth } });
      document.dispatchEvent(event);
    }
  }, [auth]);
  return <AuthContext.Provider value={{ auth }}>
    {children}
  </AuthContext.Provider>;
};
